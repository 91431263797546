/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  simple: {
    normal: [
      '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} \u0686 \u06CB \u06D0 \u0631 \u062A \u064A \u06C7 \u06AD \u0648 \u067E ] [ /',
      '{lock} \u06BE \u0633 \u062F \u0627 \u06D5 \u0649 \u0642 \u0643 \u0644 \u061B : {enter}',
      '{shift} \u0632 \u0634 \u063A \u06C8 \u0628 \u0646 \u0645 \u060C . \u0626 {shift}',
      '@ {space}'
    ],
    shift: [
      '~ ! @ # $ % ^ & * ) ( - + {bksp}',
      '{tab} \u0686 \u06CB \u06D0 \u0631 \u062A \u064A \u06C7 \u06AD \u0648 » « \\',
      '{lock} \u06BE \u0633 \u0698 \u0641 \u06AF \u062E \u062C \u06C6 \u0644 \u061B | {enter}',
      '{shift} \u0632 \u0634 \u063A \u06C8 \u0628 \u0646 \u0645 \u2039 \u203A \u061F {shift}',
      '@ {space}'
    ]
  },
  Uyghur: {
    normal: [
      '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} \u0686 \u06cb \u06d0 \u0631 \u062a \u064a \u06c7 \u06ad \u0648 \u067e ] [ \u005c',
      '\u06be \u0633 \u062f \u0627 \u06d5 \u0649 \u0642 \u0643 \u0644 \u061b \' {enter}',
      '{shift} \u0632 \u0634 \u063a \u06c8 \u0628 \u0646 \u0645 \u060c . / {shift}',
      '{accept} {space} {cancel}'
    ],
    shift: [
      '~ ! @ # $ % ^ & * ) ( _ + {bksp}',
      '{tab} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} \u00bb \u00ab |',
      '{e} {e} \u0698 \u0641 \u06af \u062e \u062c \u06c6 \u0644\u0627 : \u0022 {enter}',
      '{shift} {e} {e} {e} {e} {e} {e} {e} > < / {shift}',
      '{accept} {space} {cancel}'
    ]
  }
}

const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
