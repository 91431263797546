/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  simple: {
    normal: [
      '\u02DB 1 2 3 4 5 6 7 8 9 0 + \' {bksp}',
      '{tab} q w e r t z u i o p \u017C \u015B',
      '{lock} a s d f g h j k l \u0142 \u0105 \u00F3 {enter}',
      '{shift} < y x c v b n m , . - {shift}',
      '@ {space}'
    ],
    shift: [
      '\u00B7 ! " # \u00A4 % & / ( ) = ? * {bksp}',
      '{tab} Q W E R T Z U I O P \u0144 \u0107',
      '{lock} A S D F G H J K L \u0141 \u0119 \u017A {enter}',
      '{shift} > Y X C V B N M ; : _ {shift}',
      '@ {space}'
    ]
  },
  'Polish (214)': {
    normal: [
      '\u02DB 1 2 3 4 5 6 7 8 9 0 + \' {bksp}',
      '{tab} q w e r t z u i o p \u017C \u015B',
      'a s d f g h j k l \u0142 \u0105 \u00F3 {enter}',
      '{shift} < y x c v b n m , . - {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\u00B7 ! " # \u00A4 % & / ( ) = ? * {bksp}',
      '{tab} Q W E R T Z U I O P \u0144 \u0107',
      'A S D F G H J K L \u0141 \u0119 \u017A {enter}',
      '{shift} > Y X C V B N M ; : _ {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{e} ~ \u02C7 ^ \u02D8 \u00B0 \u02DB ` \u00B7 \u00B4 \u02DD \u00A8 \u00B8 {bksp}',
      '{tab} \\ \u00A6 {e} {e} {e} {e} \u20AC {e} {e} {e} \u00F7 \u00D7',
      '{e} \u0111 \u0110 {e} {e} {e} {e} {e} {e} $ \u00DF {e} {enter}',
      '{shift} {e} {e} {e} {e} @ { } \u00A7 < > {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  }
}
const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
