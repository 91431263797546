/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  Yakut: {
    normal: [
      '\u0022 ! \u043d\u044c \u0434\u044c \u04a5 \u0495 \u04e9 \u04bb \u04af ; : - = {bksp}',
      '{tab} \u0439 \u0446 \u0443 \u043a \u0435 \u043d \u0433 \u0448 \u0449 \u0437 \u0445 \u044a \u005c',
      '\u0444 \u044b \u0432 \u0430 \u043f \u0440 \u043e \u043b \u0434 \u0436 \u044d {enter}',
      '{shift} \u0022 \u044f \u0447 \u0441 \u043c \u0438 \u0442 \u044c \u0431 \u044e / {shift}',
      '{accept} {space} {cancel}'
    ],
    shift: [
      '\u2116 ? \u041d\u044c \u0414\u044c \u04a4 \u0494 \u04e8 \u04ba \u04ae ( ) _ + {bksp}',
      '{tab} \u0419 \u0426 \u0423 \u041a \u0415 \u041d \u0413 \u0428 \u0429 \u0417 \u0425 \u042a /',
      '\u0424 \u042b \u0412 \u0410 \u041f \u0420 \u041e \u041b \u0414 \u0416 \u042d {enter}',
      '{shift} \u2116 \u042f \u0427 \u0421 \u041c \u0418 \u0422 \u042c \u0411 \u042e / {shift}',
      '{accept} {space} {cancel}'
    ]
  }
}
const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
