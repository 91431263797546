/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  Igbo: {
    normal: [
      '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} q w e r t y u i o p [ ] \\',
      'a s d f g h j k l ; \' {enter}',
      '{shift} \\ z x c v b n m , . / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '~ ! @ # $ % ^ & * ( ) _ + {bksp}',
      '{tab} Q W E R T Y U I O P { } |',
      'A S D F G H J K L : " {enter}',
      '{shift} | Z X C V B N M < > / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{e} {e} {e} {e} {e} \u20ac {e} {e} {e} \u2018 \u2019 {e} {e} {bksp}',
      '{tab} \u1ecb\u0301 \u1ecb\u0300 \u1ecb\u0304 {e} {e} {e} \u1ee5 \u1ecb \u1ecd {e} {e} {e} {e}',
      '\u1ecd\u0301 \u1ecd\u0300 \u1ecd\u0304 \u1ee5\u0301 \u1ee5\u0300 \u1ee5\u0304 {e} {e} {e} \u00b6 \u00b4 {enter}',
      '{shift} {e} {e} {e} {e} {e} {e} \u1e45 m\u0300 m\u0304 n\u0304 {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    'alt-shift': [
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {bksp}',
      '{tab} \u1eca\u0301 \u1eca\u0300 \u1eca\u0304 {e} {e} {e} \u1ee4 \u1eca \u1ecc {e} {e} {e} \u00a6',
      '\u1ecc\u0301 \u1ecc\u0300 \u1ecc\u0304 \u1ee4\u0301 \u1ee4\u0300 \u1ee4\u0304 {e} {e} {e} {e} {e} {enter}',
      '{shift} {e} {e} {e} {e} {e} {e} \u1e44 M\u0300 M\u0304 N\u0304 {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  }
}
const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
