/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  simple: {
    normal: [
      '\u00A7 1 2 3 4 5 6 7 8 9 0 + \u00B4 {bksp}',
      '{tab} q w e r t y u i o p \u00E5 ¨',
      '{lock} a s d f g h j k l \u00F8 \u00E6 \' {enter}',
      '{shift} < z x c v b n m , . - {shift}',
      '@ {space}'
    ],
    shift: [
      '\u00B0 ! " # $ % & / ( ) = ? ` {bksp}',
      '{tab} Q W E R T Y U I O P \u00C5 ^',
      '{lock} A S D F G H J K L \u00D8 \u00C6 * {enter}',
      '{shift} > Z X C V B N M ; : _ {shift}',
      '@ {space}'
    ]
  },
  Norwegian: {
    normal: [
      '| 1 2 3 4 5 6 7 8 9 0 + \\ {bksp}',
      '{tab} q w e r t y u i o p \u00E5 \u00A8',
      'a s d f g h j k l \u00F8 \u00E6 \' {enter}',
      '{shift} < z x c v b n m , . - {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\u00A7 ! " # \u00A4 % & / ( ) = ? ` {bksp}',
      '{tab} Q W E R T Y U I O P \u00C5 ^',
      'A S D F G H J K L \u00D8 \u00C6 * {enter}',
      '{shift} > Z X C V B N M ; : _ {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{e} {e} @ \u00A3 $ \u20AC {e} { [ ] } {e} \u00B4 {bksp}',
      '{tab} {e} {e} \u20AC {e} {e} {e} {e} {e} {e} {e} {e} ~',
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {enter}',
      '{shift} {e} {e} {e} {e} {e} {e} {e} \u00B5 {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  },
  'Norwegian with Sami': {
    normal: [
      '| 1 2 3 4 5 6 7 8 9 0 + \\ {bksp}',
      '{tab} q w e r t y u i o p \u00e5 \u00a8 \'',
      'a s d f g h j k l \u00f8 \u00e6 {enter}',
      '{shift} < z x c v b n m , . / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\u00a7 ! " # \u00a4 % & / ( ) = ? ` {bksp}',
      '{tab} Q W E R T Y U I O P \u00c5 ^ *',
      'A S D F G H J K L \u00d8 \u00c6 {enter}',
      '{shift} > Z X C V B N M ; : / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{e} {e} @ \u00a3 $ \u20ac {e} { [ ] } {e} \u00b4 {bksp}',
      '{tab} \u00e2 {e} \u20ac {e} \u0167 {e} {e} \u00ef \u00f5 {e} {e} ~ {e}',
      '\u00e1 \u0161 \u0111 \u01e5 \u01e7 \u021f {e} \u01e9 {e} \u00f6 \u00e4 {enter}',
      '{shift} {e} \u017e {e} \u010d \u01ef \u0292 \u014b \u00b5 {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    'alt-shift': [
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {bksp}',
      '{tab} \u00c2 {e} {e} {e} \u0166 {e} {e} \u00cf \u00d5 {e} {e} {e} {e}',
      '\u00c1 \u0160 \u0110 \u01e4 \u01e6 \u021e {e} \u01e8 {e} \u00d6 \u00c4 {enter}',
      '{shift} {e} \u017d {e} \u010c \u01ee \u01b7 \u014a {e} {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  }
}

const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
