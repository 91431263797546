/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  simple: {
    normal: [
      '" 1 2 3 4 5 6 7 8 9 0 * - # {bksp}',
      '{tab} q w e r t y u ı o p ğ ü [ ]',
      '{lock} a s d f g h j k l ş i , {enter}',
      '{shift} < z x c v b n m ö ç . | $ € {shift}',
      '@ {space}'
    ],
    shift: [
      'é ! \' ^ + % & / ( ) = ? _ ~ {bksp}',
      '{tab} Q W E R T Y U I O P Ğ Ü { }',
      '{lock} A S D F G H J K L Ş İ ; {enter}',
      '{shift} > Z X C V B N M Ö Ç : \\ ` ´ {shift}',
      '@ {space}'
    ]
  },
  'turkish-q': {
    normal: [
      '\u0022 1 2 3 4 5 6 7 8 9 0 \u002a \u002d {bksp}',
      '{tab} q w e r t y u \u0131 o p \u011f \u00fc',
      '{lock} a s d f g h j k l \u015f \u0069 , {enter}',
      '{shift} < z x c v b n m \u00f6 \u00e7 . {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\u00e9 ! \u0027 \u0302 + % & / ( ) = ? \u005f {bksp}',
      '{tab} Q W E R T Y U \u0049 O P \u011e \u00DC',
      '{lock} A S D F G H J K L \u015e \u0130 ; {enter}',
      '{shift} > Z X C V B N M \u00d6 \u00c7 : {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '\u0022 1 \u00a3 # $ \u00bd 6 { [ ] } \u005c \u002d {bksp}',
      '{tab} @ w \u20ac r t y u \u0131 o p \u0308 \u0303',
      '{lock} a s d f g h j k l \u0301 \u0069 \u0300 {enter}',
      '{shift} | z x c v b n m \u00f6 \u00e7 . {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  },
  'turkish-f': {
    normal: [
      '\u002B \u0031 \u0032 \u0033 \u0034 \u0035 \u0036 \u0037 \u0038 \u0039 \u0030 \u002F \u002D {bksp}',
      '{tab} \u0066 \u0067 \u011F \u0131 \u006F \u0064 \u0072 \u006E \u0068 \u0070 \u0071 \u0077',
      '{lock} \u0075 \u0069 \u0065 \u0061 \u00FC \u0074 \u006B \u006D \u006C \u0079 \u015F \u0078 {enter}',
      '{shift} \u003C \u006A \u00F6 \u0076 \u0063 \u00E7 \u007A \u0073 \u0062 \u002E \u002C {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\u002A \u0021 \u0022 \u0302 \u0024 \u0025 \u0026 \u0027 \u0028 \u0029 \u003D \u003F \u005F {bksp}',
      '{tab} \u0046 \u0047 \u011E \u0049 \u004F \u0044 \u0052 \u004E \u0048 \u0050 \u0051 \u0057',
      '{lock} \u0055 \u0130 \u0045 \u0041 \u00DC \u0054 \u004B \u004D \u004C \u0059 \u015E \u0058 {enter}',
      '{shift} \u003E \u004A \u00D6 \u0056 \u0043 \u00C7 \u005A \u0053 \u0042 \u003A \u003B {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '\u00AC \u00B9 \u00B2 \u0023 \u00BC \u00BD \u00BE \u007B \u005B \u005D \u007D \u005C \u002D {bksp}',
      '{tab} \u0040 \u0067 \u011F \u0131 \u006F \u00A5 \u0072 \u006E \u0068 \u00A3 \u0308 \u0303',
      '{lock} \u0075 \u00DF \u20AC \u0061 \u00FC \u0074 \u006B \u006D \u006C \u0301 \u015F \u0300 {enter}',
      '{shift} \u007C \u00AB \u00BB \u0076 \u0063 \u00E7 \u007A \u0073 \u00D7 \u00F7 \u002C {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  },
  'Turkish F': {
    normal: [
      '+ 1 2 3 4 5 6 7 8 9 0 / - {bksp}',
      '{tab} f g \u011F \u0131 o d r n h p q w',
      'u i e a \u00FC t k m l y \u015F x {enter}',
      '{shift} < j \u00F6 v c \u00E7 z s b . , {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '* ! \u0022 ^ $ % & \' ( ) = ? _ {bksp}',
      '{tab} F G \u011E I O D R N H P Q W',
      'U \u0130 E A \u00DC T K M L Y \u015E X {enter}',
      '{shift} > J \u00D6 V C \u00C7 Z S B : ; {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '\u00AC \u00B9 \u00B2 # \u00BC \u00BD \u00BE { [ ] } \u005C | {bksp}',
      '{tab} @ {e} {e} \u00B6 {e} \u00A5 {e} {e} \u00F8 \u00A3 \u00A8 ~',
      '\u00E6 \u00DF \u20AC {e} {e} {e} {e} {e} {e} \u00B4 {e} ` {enter}',
      '{shift} | \u00AB \u00BB \u00A2 {e} {e} {e} \u00B5 \u00D7 \u00F7 \u00AD {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    'alt-shift': [
      '{e} \u00A1 {e} \u00B3 \u00A4 {e} {e} {e} {e} {e} {e} \u00BF {e} {bksp}',
      '{tab} {e} {e} {e} \u00AE {e} {e} {e} {e} \u00D8 {e} {e} {e}',
      '\u00C6 \u00A7 {e} \u00AA {e} {e} {e} {e} {e} {e} {e} {e} {enter}',
      '{shift} \u00A6 < > \u00A9 {e} {e} {e} \u00BA {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  },
  'Turkish Q': {
    normal: [
      '\u0022 1 2 3 4 5 6 7 8 9 0 * - {bksp}',
      '{tab} q w e r t y u \u0131 o p \u011F \u00FC',
      'a s d f g h j k l \u015F i , {enter}',
      '{shift} < z x c v b n m \u00F6 \u00E7 . {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\u00E9 ! \' ^ + % & / ( ) = ? _ {bksp}',
      '{tab} Q W E R T Y U I O P \u011E \u00DC',
      'A S D F G H J K L \u015E \u0130 ; {enter}',
      '{shift} > Z X C V B N M \u00D6 \u00C7 : {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '< > \u00A3 # $ \u00BD {e} { [ ] } \u005C | {bksp}',
      '{tab} @ {e} \u20AC {e} {e} {e} {e} i {e} {e} \u00A8 ~',
      '\u00E6 \u00DF {e} {e} {e} {e} {e} {e} {e} \u00B4 {e} ` {enter}',
      '{shift} | {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    'alt-shift': [
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {bksp}',
      '{tab} {e} {e} {e} {e} {e} {e} {e} \u0130 {e} {e} {e} {e}',
      '\u00C6 {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {enter}',
      '{shift} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  }
}
const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
