/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  'Inuktitut - Latin': {
    normal: [
      '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} q w e r t y u i o p [ ] \\',
      'a s d f g h j k l ; \' {enter}',
      '{shift} \\ z x c v b n m , . / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '~ 1 2 3 4 5 6 7 8 9 0 _ + {bksp}',
      '{tab} Q W E R T Y U I O P { } |',
      'A S D F G H J K L : " {enter}',
      '{shift} | Z X C V B N M < > / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{e} ! @ # $ % * & \u2022 [ ] {e} = {bksp}',
      '{tab} \u1590 \u1404 \u1580 \u146e \u144f \u14f0 \u14a6 \u14c3 \u14d6 \u1529 \u201c \u2018 {e}',
      '\u1592 \u1406 \u1582 \u1470 \u1451 \u14f2 \u14a8 \u14c5 \u14d8 \u2026 \u0142 {enter}',
      '{shift} {e} \u1594 \u140b \u1584 \u1473 \u1456 \u14f5 \u14ab {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    'alt-shift': [
      '~ \u00a2 \u00ae \u00bc \u00bd \u00be ^ \u2154 \u00a9 \u00f7 + {e} {e} {bksp}',
      '{tab} \u148c \u1432 \u1547 {e} \u1672 \u1674 \u1676 \u15a5 \u15a1 {e} \u201d \u2019 |',
      '\u148e \u1434 \u1549 \u1556 {e} {e} \u152b \u1558 \u15a3 {e} \u0141 {enter}',
      '{shift} | \u1491 \u1439 \u154c { } \u14c8 \u14db {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  },
  'Inuktitut - Naqittaut': {
    normal: [
      '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} q w e r t y u i o p [ ] \\',
      'a s d f g h j k l ; \' {enter}',
      '{shift} \\ z x c v b n m , . / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '~ ! @ # $ % ^ & * ( ) _ + {bksp}',
      '{tab} Q W E R T Y U I O P { [ |',
      'A S D F G H J K L : " {enter}',
      '{shift} | Z X C V B N M < > / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{e} ! @ # $ % * & \u2022 [ ] {e} = {bksp}',
      '{tab} \u1590 \u1404 \u1580 \u146e \u144f \u14f0 \u14a6 \u14c3 \u14d6 \u1529 \u201c \u2018 {e}',
      '\u1592 \u1406 \u1582 \u1470 \u1451 \u14f2 \u14a8 \u14c5 \u14d8 \u014b \u0142 {enter}',
      '{shift} {e} \u1594 \u140b \u1584 \u1473 \u1456 \u14f5 \u14ab {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    'alt-shift': [
      '~ \u00a2 \u00ae \u00bc \u00bd \u00be ^ \u2154 \u00a9 \u00f7 + {e} {e} {bksp}',
      '{tab} \u148c \u1432 \u1547 {e} \u1672 \u1674 \u1676 \u15a5 \u15a1 {e} \u201d \u2019 |',
      '\u148e \u1434 \u1549 \u1556 {e} {e} \u152b \u1558 \u15a3 {e} \u0141 {enter}',
      '{shift} | \u1491 \u1439 \u154c { } \u14c8 \u14db {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  }
}
const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
