/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  Hausa: {
    normal: [
      '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} q w e r t y u i o p [ ] \\',
      'a s d f g h j k l ; \' {enter}',
      '{shift} \\ z x c v b n m , . / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '~ ! @ # $ % ^ & * ( ) _ + {bksp}',
      '{tab} Q W E R T Y U I O P { } |',
      'A S D F G H J K L : " {enter}',
      '{shift} | Z X C V B N M < > / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{e} {e} {e} {e} {e} \u20ac {e} {e} {e} \u2018 \u2019 {e} {e} {bksp}',
      '{tab} {e} {e} {e} {e} {e} \u01b4 {e} {e} {e} {e} {e} {e} {e}',
      '{e} {e} \u0257 {e} {e} {e} {e} \u0199 {e} \u00b6 \u2019 {enter}',
      '{shift} {e} {e} {e} {e} {e} \u0253 {e} {e} {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    'alt-shift': [
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {bksp}',
      '{tab} {e} {e} {e} {e} {e} \u01b3 {e} {e} {e} {e} {e} {e} \u00a6',
      '{e} {e} \u018a {e} {e} {e} {e} \u0198 {e} {e} {e} {enter}',
      '{shift} {e} {e} {e} {e} {e} \u0181 {e} {e} {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  }
}
const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
