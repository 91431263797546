/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  simple: {
    normal: [
      '` \u0661 \u0662 \u0663 \u0664 \u0665 \u0666 \u0667 \u0668 \u0669 \u0660 - = {bksp}',
      '{tab} \u0642 \u0648 \u0639 \u0631 \u062A \u06D2 \u0621 \u0649 \u06C1 \u067E [ ]',
      '{lock} \u0627 \u0633 \u062F \u0641 \u06AF \u06BE \u062C \u06A9 \u0644 \u061B \u060C {enter}',
      '{shift} \u0632 \u0634 \u0686 \u0637 \u0628 \u0646 \u0645 \u06E4 , . / {shift}',
      '@ {space}'
    ],
    shift: [
      '~ ! @ # $ \u066A ^ & * ( ) _ + {bksp}',
      '{tab} \uFE70 \uFE77 \uFE79 \u0691 \u0679 \uFE7A \uFEFB \uFE8B \u0629 | { }',
      '{lock} \u0622 \u0635 \u0688 \u060D \u063A \u062D \u0636 \u062E \u06D4 \u0703 \u05f4 {enter}',
      '{shift} \u0630 \u0698 \u062B \u0638 \u06BA \u066b \u0640 < > \u061F {shift}',
      '@ {space}'
    ]
  },
  Urdu: {
    normal: [
      '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} \u0637 \u0635 \u06be \u062f \u0679 \u067e \u062a \u0628 \u062c \u062d ] [ \\',
      '\u0645 \u0648 \u0631 \u0646 \u0644 \u06c1 \u0627 \u06a9 \u06cc \u061b \' {enter}',
      '{shift} \\ \u0642 \u0641 \u06d2 \u0633 \u0634 \u063a \u0639 \u060c \u06d4 / {shift}',
      '{accept} {space} {cancel}'
    ],
    shift: [
      '~ ! @ # $ \u066a ^ \u06d6 \u066d ) ( _ + {bksp}',
      '{tab} \u0638 \u0636 \u0630 \u0688 \u062b \u0651 \u06c3 \u0640 \u0686 \u062e } { |',
      '\u0698 \u0632 \u0691 \u06ba \u06c2 \u0621 \u0622 \u06af \u064a : " {enter}',
      '{shift} | {ZWJ} {ZWNJ} \u06d3 {LRM} \u0624 \u0626 {RLM} > < \u061f {shift}',
      '{accept} {space} {cancel}'
    ]
  },
  urduStandard: {
    normal: [
      '` \u0661 \u0662 \u0663 \u0664 \u0665 \u0666 \u0667 \u0668 \u0669 \u0660 - = {bksp}',
      '{tab} \u0637 \u0635 \u06BE \u062F \u0679 \u067E \u062A \u0628 \u062C \u062D ] [ \u005C',
      '{lock} \u0645 \u0648 \u0631 \u0646 \u0644 \u06C1 \u0627 \u06A9 \u0649 \u061B \u066C {enter}',
      '{shift} \u0642 \u0641 \u06D2 \u0633 \u0634 \u063A \u0639 \u060C \u06D4 / {shift}',
      '@ {space}'
    ],
    shift: [
      '~ ! @ # $ \u066A ^ \u06D6 * ) ( - + {bksp}',
      '{tab} \u0638 \u0636 \u0630 \u0688 \u062B \u0303 \u06C3 \u0640 \u0686 \u062E } { |',
      '{lock} \u0698 \u0632 \u0691 \u06BA \u06C2 \u0621 \u0622 \u06AF \u064A \u0703 \u05f4 {enter}',
      '{shift} \u06D3 \u0624 \u0626     > < \u061F {shift}',
      '@ {space}'
    ]
  }
}

const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
