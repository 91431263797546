/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  simple: {
    normal: [
      '\' 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} q w e r t y u i o p \u00B4 [',
      '{lock} a s d f g h j k l \u00E7 ~ ] {enter}',
      '{shift} \\ z x c v b n m , . ; / {shift}',
      '@ {space}'
    ],
    shift: [
      '" ! @ # $ % \u0308 & * ( ) _ + {bksp}',
      '{tab} Q W E R T Y U I O P ` {',
      '{lock} A S D F G H J K L \u00C7 ^ } {enter}',
      '{shift} | Z X C V B N M < > : ? {shift}',
      '@ {space}'
    ]
  }
}

const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
