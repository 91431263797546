/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  Marathi: {
    normal: [
      '{e} \u0967 \u0968 \u0969 \u096a \u096b \u096c \u096d \u096e \u096f \u0966 - \u0943 {bksp}',
      '{tab} \u094c \u0948 \u093e \u0940 \u0942 \u092c \u0939 \u0917 \u0926 \u091c \u0921 \u093c \u0949',
      '\u094b \u0947 \u094d \u093f \u0941 \u092a \u0930 \u0915 \u0924 \u091a \u091f {enter}',
      '{shift} \u0949 \u0902 \u092e \u0928 \u0935 \u0932 \u0938 , . \u092f {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '{e} \u090d \u0945 \u094d\u0930 \u0930\u094d \u091c\u094d\u091e \u0924\u094d\u0930 \u0915\u094d\u0937 \u0936\u094d\u0930 ( ) \u0903 \u090b {bksp}',
      '{tab} \u0914 \u0910 \u0906 \u0908 \u090a \u092d \u0919 \u0918 \u0927 \u091d \u0922 \u091e \u0911',
      '\u0913 \u090f \u0905 \u0907 \u0909 \u092b \u0931 \u0916 \u0925 \u091b \u0920 {enter}',
      '{shift} \u0911 \u0901 \u0923 {e} {e} \u0933 \u0936 \u0937 \u0964 \u095f {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} [ ] \\',
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} ; \' {enter}',
      '{shift} {e} {e} {e} {e} {e} {e} {e} , . {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    'alt-shift': [
      '~ ! @ # $ % ^ & * ( ) _ + {bksp}',
      '{tab} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} { } |',
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} : " {enter}',
      '{shift} {e} \u093d \u0950 {e} {e} {e} {e} < > \u003f {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  }
}
const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
