/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  'Sami Extended Finland-Sweden': {
    normal: [
      '\u00a7 1 2 3 4 5 6 7 8 9 0 + \u00b4 {bksp}',
      '{tab} \u00e1 \u0161 e r t \u0167 u i o p \u00e5 \u014b \u0111',
      'a s d f g h j k l \u00f6 \u00e4 {enter}',
      '{shift} \u017e z \u010d c v b n m , . / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\u00bd ! " # \u00a4 % & / ( ) = ? ` {bksp}',
      '{tab} \u00c1 \u0160 E R T \u0166 U I O P \u00c5 \u014a \u0110',
      'A S D F G H J K L \u00d6 \u00c4 {enter}',
      '{shift} \u017d Z \u010c C V B N M ; : / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '| {e} @ \u00a3 $ \u20ac {e} { [ ] } \\ {e} {bksp}',
      '{tab} q w \u20ac {e} {e} y {e} \u00ef \u00f5 {e} \u00a8 ~ \'',
      '\u00e2 {e} {e} {e} \u01e7 \u01e5 {e} \u01e9 {e} \u00f8 \u00e6 {enter}',
      '{shift} \u01ef \u0292 x {e} {e} {e} {e} \u00b5 < > {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    'alt-shift': [
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {bksp}',
      '{tab} Q W {e} {e} {e} Y {e} \u00cf \u00d5 {e} ^ \u02c7 *',
      '\u00c2 {e} {e} {e} \u01e6 \u01e4 {e} \u01e8 {e} \u00d8 \u00c6 {enter}',
      '{shift} \u01ee \u01b7 X {e} {e} {e} {e} {e} {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  },
  'Sami Extended Norway': {
    normal: [
      '| 1 2 3 4 5 6 7 8 9 0 + \\ {bksp}',
      '{tab} \u00e1 \u0161 e r t \u0167 u i o p \u00e5 \u014b \u0111',
      'a s d f g h j k l \u00f8 \u00e6 {enter}',
      '{shift} \u017e z \u010d c v b n m , . / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\u00a7 ! " # \u00a4 % & / ( ) = ? ` {bksp}',
      '{tab} \u00c1 \u0160 E R T \u0166 U I O P \u00c5 \u014a \u0110',
      'A S D F G H J K L \u00d8 \u00c6 {enter}',
      '{shift} \u017d Z \u010c C V B N M ; : / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{e} {e} @ \u00a3 $ \u20ac {e} { [ ] } {e} \u00b4 {bksp}',
      '{tab} q w \u20ac {e} {e} y {e} \u00ef \u00f5 {e} \u00a8 ~ \'',
      '\u00e2 {e} {e} {e} \u01e7 \u01e5 {e} \u01e9 {e} \u00f6 \u00e4 {enter}',
      '{shift} \u01ef \u0292 x {e} {e} {e} {e} \u00b5 < > {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    'alt-shift': [
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {bksp}',
      '{tab} Q W {e} {e} {e} Y {e} \u00cf \u00d5 {e} ^ \u02c7 *',
      '\u00c2 {e} {e} {e} \u01e6 \u01e4 {e} \u01e8 {e} \u00d6 \u00c4 {enter}',
      '{shift} \u01ee \u01b7 X {e} {e} {e} {e} {e} {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  }
}
const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
