/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  'Maltese 47-Key': {
    normal: [
      '\u010b 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} q w e r t y u i o p \u0121 \u0127 \u017c',
      'a s d f g h j k l ; \' {enter}',
      '{shift} z x c v b n m , . / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\u010a ! @ \u20ac $ % ^ & * ( ) _ + {bksp}',
      '{tab} Q W E R T Y U I O P \u0120 \u0126 \u017b',
      'A S D F G H J K L : " {enter}',
      '{shift} Z X C V B N M < > / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '` {e} {e} \u00a3 {e} {e} {e} {e} {e} {e} {e} {e} {e} {bksp}',
      '{tab} {e} {e} \u00e8 {e} {e} {e} \u00f9 \u00ec \u00f2 {e} [ ] \\',
      '\u00e0 {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {enter}',
      '{shift} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    'alt-shift': [
      '~ {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {bksp}',
      '{tab} {e} {e} \u00c8 {e} {e} {e} \u00d9 \u00cc \u00d2 {e} { } |',
      '\u00c0 {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {enter}',
      '{shift} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  },
  'Maltese 48-Key': {
    normal: [
      '\u010b 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} q w e r t y u i o p \u0121 \u0127 #',
      'a s d f g h j k l ; \' {enter}',
      '{shift} \u017c z x c v b n m , . / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\u010a ! " \u20ac $ % ^ & * ( ) _ + {bksp}',
      '{tab} Q W E R T Y U I O P \u0120 \u0126 ~',
      'A S D F G H J K L : @ {enter}',
      '{shift} \u017b Z X C V B N M < > / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '` {e} {e} \u00a3 {e} {e} {e} {e} {e} {e} {e} {e} {e} {bksp}',
      '{tab} {e} {e} \u00e8 {e} {e} {e} \u00f9 \u00ec \u00f2 {e} [ ] {e}',
      '\u00e0 {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {enter}',
      '{shift} \\ {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    'alt-shift': [
      '\u00ac {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {bksp}',
      '{tab} {e} {e} \u00c8 {e} {e} {e} \u00d9 \u00cc \u00d2 {e} { } {e}',
      '\u00c0 {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {enter}',
      '{shift} | {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  }
}
const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
