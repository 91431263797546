/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  simple: {
    normal: [
      '; + \u011B \u0161 \u010D \u0159 \u017E \u00FD \u00E1 \u00ED \u00E9 \u00B4 {bksp}',
      '{tab} q w e r t y u i o p \u00FA ) \u00A8',
      '{lock} a s d f g h j k l \u016F \u00A7 {enter}',
      '{shift} \\ z x c v b n m , . - {shift}',
      '@ {space}'
    ],
    shift: [
      '\u00b0 1 2 3 4 5 6 7 8 9 0 % \u02c7 {bksp}',
      '{tab} Q W E R T Y U I O P / ( \'',
      '{lock} A S D F G H J K L " ! {enter}',
      '{shift} | Z X C V B N M ? : _ {shift}',
      '@ {space}'
    ]
  },
  Czech: {
    normal: [
      '; + \u011b \u0161 \u010d \u0159 \u017e \u00fd \u00e1 \u00ed \u00e9 = \u00b4 {bksp}',
      '{tab} q w e r t z u i o p \u00fa ) {e}',
      'a s d f g h j k l \u016f \u00a7 {enter}',
      '{shift} \\ y x c v b n m , . / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\u00b0 1 2 3 4 5 6 7 8 9 0 % \u02c7 {bksp}',
      '{tab} Q W E R T Z U I O P / ( \'',
      'A S D F G H J K L " ! {enter}',
      '{shift} | Y X C V B N M ? : / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{e} ~ \u02c7 ^ \u02d8 \u00b0 \u02db ` \u02d9 \u00b4 \u02dd \u00a8 \u00b8 {bksp}',
      '{tab} \\ | \u20ac {e} {e} {e} {e} {e} {e} {e} \u00f7 \u00d7 \u00a4',
      '{e} \u0111 \u0110 [ ] {e} {e} \u0142 \u0141 $ \u00df {enter}',
      '{shift} {e} {e} # & @ { } {e} < > {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  },
  'Czech (QWERTY)': {
    normal: [
      '; + \u011b \u0161 \u010d \u0159 \u017e \u00fd \u00e1 \u00ed \u00e9 = \u00b4 {bksp}',
      '{tab} q w e r t y u i o p \u00fa ) {e}',
      'a s d f g h j k l \u016f \u00a7 {enter}',
      '{shift} \\ z x c v b n m , . / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\u00b0 1 2 3 4 5 6 7 8 9 0 % \u02c7 {bksp}',
      '{tab} Q W E R T Y U I O P / ( \'',
      'A S D F G H J K L " ! {enter}',
      '{shift} | Z X C V B N M ? : / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '` ! @ # $ % ^ & * ( ) - = {bksp}',
      '{tab} {e} {e} \u20ac {e} {e} {e} {e} {e} {e} {e} [ ] \\',
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} ; \u00a4 {enter}',
      '{shift} \u00df {e} {e} {e} {e} {e} {e} {e} < > {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    'alt-shift': [
      '~ {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} _ + {bksp}',
      '{tab} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} { } |',
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} : ^ {enter}',
      '{shift} \u02dd {e} {e} {e} {e} {e} {e} {e} \u00d7 \u00f7 {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  }
}

const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
