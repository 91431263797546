/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  simple: {
    normal: [
      '0 1 2 3 4 5 6 7 8 9 ö ü ó {bksp}',
      '{tab} q w e r t z u i o p ő ú',
      '{lock} a s d f g h j k l é á ű {enter}',
      '{shift} í y x c v b n m , . - {shift}',
      '@ {space}'
    ],
    shift: [
      '§ \' " + ! % / = ( ) Ö Ü Ó {bksp}',
      '{tab} Q W E R T Z U I O P Ő Ú',
      '{lock} A S D F G H J K L É Á Ű {enter}',
      '{shift} Í Y X C V B N M ? : _ {shift}',
      '@ {space}'
    ]
  },
  'hungarian-qwertz-1': {
    normal: [
      '0 1 2 3 4 5 6 7 8 9 \u00f6 \u00fc \u00f3 \u0171 {bksp}',
      '{tab} q w e r t z u i o p \u0151 \u00fa',
      'a s d f g h j k l \u00e9 \u00e1 {enter}',
      '{shift} \u00ed y x c v b n m , . - {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\u00a7 \' " + ! % / = ( ) \u00d6 \u00dc \u00d3 \u0170 {bksp}',
      '{tab} Q W E R T Z U I O P \u0150 \u00da',
      'A S D F G H J K L \u00c9 \u00c1 {enter}',
      '{shift} \u00cd Y X C V B N M ; : _ {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{sp:1} ~ \u02c7 ^ \u02d8 \u00b0 \u02db ` \u02d9 \u00b4 \u02dd \u00a8 \u00b8 \u00a4 {bksp}',
      '{tab} \\ | \u00c4 {sp:1} {sp:1} {sp:1} \u20ac \u00cd {sp:1} {sp:1} \u00f7 \u00d7',
      '\u00e4 \u0111 \u0110 [ ] {sp:1} \u00ed \u0142 \u0141 $ \u00df {enter}',
      '{shift} < > # & @ { } < ; > * {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  },
  'hungarian-qwertz-2': {
    normal: [
      '0 1 2 3 4 5 6 7 8 9 \u00f6 \u00fc \u00f3 {bksp}',
      '{tab} q w e r t z u i o p \u0151 \u00fa',
      'a s d f g h j k l \u00e9 \u00e1 \u0171 {enter}',
      '{shift} \u00ed y x c v b n m , . - {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\u00a7 \' " + ! % / = ( ) \u00d6 \u00dc \u00d3 {bksp}',
      '{tab} Q W E R T Z U I O P \u0150 \u00da',
      'A S D F G H J K L \u00c9 \u00c1 \u0170 {enter}',
      '{shift} \u00cd Y X C V B N M ; : _ {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{sp:1} ~ \u02c7 ^ \u02d8 \u00b0 \u02db ` \u02d9 \u00b4 \u02dd \u00a8 \u00b8 {bksp}',
      '{tab} \\ | \u00c4 {sp:1} {sp:1} {sp:1} \u20ac \u00cd {sp:1} {sp:1} \u00f7 \u00d7',
      '\u00e4 \u0111 \u0110 [ ] {sp:1} \u00ed \u0142 \u0141 $ \u00df \u00a4 {enter}',
      '{shift} < > # & @ { } < ; > * {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  },
  Hungarian: {
    normal: [
      '0 1 2 3 4 5 6 7 8 9 \u00F6 \u00FC \u00F3 {bksp}',
      '{tab} q w e r t z u i o p \u0151 \u00FA',
      'a s d f g h j k l \u00E9 \u00E1 \u0171 {enter}',
      '{shift} \u00ED y x c v b n m , . - {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\u00A7 \' " + ! % / = ( ) \u00D6 \u00DC \u00D3 {bksp}',
      '{tab} Q W E R T Z U I O P \u0150 \u00DA',
      'A S D F G H J K L \u00C9 \u00C1 \u0170 {enter}',
      '{shift} \u00CD Y X C V B N M ? : _ {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{e} ~ \u02C7 ^ \u02D8 \u00B0 \u02DB ` \u02D9 \u00B4 \u02DD \u00A8 \u00B8 {bksp}',
      '{tab} \\ | \u00C4 {e} {e} {e} \u20AC \u00CD {e} {e} \u00F7 \u00D7',
      '\u00E4 \u0111 \u0110 [ ] {e} \u00ED \u0142 \u0141 $ \u00DF \u00A4 {enter}',
      '{shift} < > # & @ { } < ; > * {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  },
  'Hungarian 101-key': {
    normal: [
      '\u00ED 1 2 3 4 5 6 7 8 9 \u00F6 \u00FC \u00F3 {bksp}',
      '{tab} q w e r t y u i o p \u0151 \u00FA',
      'a s d f g h j k l \u00E9 \u00E1 \u0171 {enter}',
      '{shift} \u00ED z x c v b n m , . - {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\u00CD \' " + ! % / = ( ) \u00D6 \u00DC \u00D3 {bksp}',
      '{tab} Q W E R T Y U I O P \u0150 \u00DA',
      'A S D F G H J K L \u00C9 \u00C1 \u0170 {enter}',
      '{shift} \u00CD Z X C V B N M ? : _ {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '0 ~ \u02C7 ^ \u02D8 \u00B0 \u02DB ` \u02D9 \u00B4 \u02DD {e} {e} {bksp}',
      '{tab} \\ | \u00C4 \u00A7 \u00A4 {e} \u20AC \u00CD {e} {e} \u00F7 \u00D7',
      '\u00E4 \u0111 \u0110 [ ] {e} \u00ED \u0142 \u0141 $ \u00DF \\ {enter}',
      '{shift} < > # & @ { } < ; > * {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  }
}
const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
