/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  'Bulgarian (Phonetic Traditional)': {
    normal: [
      '\u0447 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} \u044f \u0432 \u0435 \u0440 \u0442 \u044a \u0443 \u0438 \u043e \u043f \u0448 \u0449 \u044e',
      '\u0430 \u0441 \u0434 \u0444 \u0433 \u0445 \u0439 \u043a \u043b ; \' {enter}',
      '{shift} \u044e \u0437 \u044c \u0446 \u0436 \u0431 \u043d \u043c , . / {shift}',
      '{accept} {space} {cancel}'
    ],
    shift: [
      '\u0427 ! @ \u2116 $ % \u20ac \u00a7 * ( ) _ + {bksp}',
      '{tab} \u042f \u0412 \u0415 \u0420 \u0422 \u042a \u0423 \u0418 \u041e \u041f \u0428 \u0429 \u042e',
      '\u0410 \u0421 \u0414 \u0424 \u0413 \u0425 \u0419 \u041a \u041b : " {enter}',
      '{shift} \u042e \u0417 \u045d \u0426 \u0416 \u0411 \u041d \u041c < > / {shift}',
      '{accept} {space} {cancel}'
    ]
  },
  'Bulgarian (Phonetic)': {
    normal: [
      '\u044e 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} \u0447 \u0448 \u0435 \u0440 \u0442 \u044a \u0443 \u0438 \u043e \u043f \u044f \u0449 \u044c',
      '\u0430 \u0441 \u0434 \u0444 \u0433 \u0445 \u0439 \u043a \u043b ; \' {enter}',
      '{shift} \u045d \u0437 \u0436 \u0446 \u0432 \u0431 \u043d \u043c , . / {shift}',
      '{accept} {space} {cancel}'
    ],
    shift: [
      '\u042e ! @ \u2116 $ % \u20ac \u00a7 * ( ) \u2013 + {bksp}',
      '{tab} \u0427 \u0428 \u0415 \u0420 \u0422 \u042a \u0423 \u0418 \u041e \u041f \u042f \u0429 \u045d',
      '\u0410 \u0421 \u0414 \u0424 \u0413 \u0425 \u0419 \u041a \u041b : " {enter}',
      '{shift} \u040d \u0417 \u0416 \u0426 \u0412 \u0411 \u041d \u041c \u201e \u201c / {shift}',
      '{accept} {space} {cancel}'
    ]
  },
  'Bulgarian (Typewriter)': {
    normal: [
      '` 1 2 3 4 5 6 7 8 9 0 - . {bksp}',
      '{tab} , \u0443 \u0435 \u0438 \u0448 \u0449 \u043a \u0441 \u0434 \u0437 \u0446 ; (',
      '\u044c \u044f \u0430 \u043e \u0436 \u0433 \u0442 \u043d \u0432 \u043c \u0447 {enter}',
      '{shift} \\ \u044e \u0439 \u044a \u044d \u0444 \u0445 \u043f \u0440 \u043b / {shift}',
      '{accept} {space} {cancel}'
    ],
    shift: [
      '~ ! ? + " % = : / _ \u2116 \u0406 V {bksp}',
      '{tab} \u044b \u0423 \u0415 \u0418 \u0428 \u0429 \u041a \u0421 \u0414 \u0417 \u0426 \u00a7 )',
      '\u042c \u042f \u0410 \u041e \u0416 \u0413 \u0422 \u041d \u0412 \u041c \u0427 {enter}',
      '{shift} | \u042e \u0419 \u042a \u042d \u0424 \u0425 \u041f \u0420 \u041b / {shift}',
      '{accept} {space} {cancel}'
    ]
  }
}

const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
