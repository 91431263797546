/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  simple: {
    normal: [
      'ё ` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} й ц у к е н г ш ў з х [ ] \\',
      '{lock} ф ы в а п р о л д ж э ; \' {enter}',
      '{shift} я ч с м і т ь б ю , . / {shift}',
      '@ {space}'
    ],
    shift: [
      'Ё ~ ! @ # $ % ^ & * ( ) _ + {bksp}',
      '{tab} Й Ц У К Е Н Г Ш Ў З Х { } |',
      '{lock} Ф Ы В А П Р О Л Д Ж Э : " {enter}',
      '{shift} Я Ч С М І Т Ь Б Ю < > ? {shift}',
      '@ {space}'
    ]
  },
  'belarusian-qwerty': {
    alt: [
      'ё 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} й ц у к е н г ш ў з х \' \\',
      'ф ы в а п р о л д ж э {enter}',
      '{shift} я ч с м і т ь б ю . {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    'alt-shift': [
      'Ё ! " № ; € : ? * ( ) _ + {bksp}',
      '{tab} Й Ц У К Е Н Г Ш Ў З Х \' /',
      'Ф Ы В А П Р О Л Д Ж Э {enter}',
      '{shift} Я Ч С М І Т Ь Б Ю , {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    normal: [
      '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} q w e r t y u i o p [ ] \\',
      'a s d f g h j k l ; \' {enter}',
      '{shift} z x c v b n m , . / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '~ ! @ # $ % ^ & * ( ) _ + {bksp}',
      '{tab} Q W E R T Y U I O P { } |',
      'A S D F G H J K L : " {enter}',
      '{shift} Z X C V B N M < > ? {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  },
  Belarusian: {
    normal: [
      '\u0451 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} \u0439 \u0446 \u0443 \u043a \u0435 \u043d \u0433 \u0448 \u045e \u0437 \u0445 \' \\',
      '\u0444 \u044b \u0432 \u0430 \u043f \u0440 \u043e \u043b \u0434 \u0436 \u044d {enter}',
      '{shift} \\ \u044f \u0447 \u0441 \u043c \u0456 \u0442 \u044c \u0431 \u044e / {shift}',
      '{accept} {space} {cancel}'
    ],
    shift: [
      '\u0401 ! " \u2116 ; % : ? * ( ) _ + {bksp}',
      '{tab} \u0419 \u0426 \u0423 \u041a \u0415 \u041d \u0413 \u0428 \u040e \u0417 \u0425 \' /',
      '\u0424 \u042b \u0412 \u0410 \u041f \u0420 \u041e \u041b \u0414 \u0416 \u042d {enter}',
      '{shift} / \u042f \u0427 \u0421 \u041c \u0406 \u0422 \u042c \u0411 \u042e / {shift}',
      '{accept} {space} {cancel}'
    ]
  }
}
const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
