/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  Nepali: {
    normal: [
      '\u091e \u091c\u094d\u091e \u0918 \u0919 \u091d \u091b \u091f \u0920 \u0921 \u0922 \u0923 ( . {bksp}',
      '{tab} \u0924\u094d\u0930 \u0927 \u092d \u091a \u0924 \u0925 \u0917 \u0937 \u092f \u0909 \u0943 \u0947 \u094d\u0930',
      '\u092c \u0915 \u092e \u093e \u0928 \u091c \u0935 \u092a \u093f \u0938 \u0941 {enter}',
      '{shift} \u0936 \u0939 \u0905 \u0916 \u0926 \u0932 \u092b , \u0964 \u0930 {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\u091e\u094d \u0967 \u0968 \u0969 \u096a \u096b \u096c \u096d \u096e \u096f \u0966 ) \u0902 {bksp}',
      '{tab} \u094b \u0927\u094d \u092d\u094d \u091a\u094d \u0924\u094d \u0925\u094d \u0917\u094d \u0915\u094d\u0937 \u0907 \u090f \u0930\u094d \u0948 \u094d',
      '\u092c\u094d \u0915\u094d \u092e\u094d \u0901 \u0928\u094d \u091c\u094d \u0935\u094d \u092a\u094d \u0940 \u0938\u094d \u0942 {enter}',
      '{shift} \u0936\u094d \u0939\u094d \u090b \u0916\u094d \u0926\u094d \u0932\u094d \u0903 ? \u0936\u094d\u0930 / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{e} 1 2 3 4 5 6 7 8 9 0 {e} + {bksp}',
      '{tab} {e} {e} {e} {e} {e} {e} {e} {e} {e} \u090a {e} \u0914 {e}',
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {enter}',
      '{shift} {e} {e} \u0906 {e} {e} {e} {e} {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  }
}
const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
