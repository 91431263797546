/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  Gaelic: {
    normal: [
      '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} q w e r t y u i o p [ ]',
      'a s d f g h j k l ; \' # {enter}',
      '{shift} \\ z x c v b n m , . / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '` ! " \u00A3 $ % ^ & * ( ) _ + {bksp}',
      '{tab} Q W E R T Y U I O P { }',
      'A S D F G H J K L : @ ~ {enter}',
      '{shift} | Z X C V B N M < > ? {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '\u00A6 {e} {e} {e} \u20AC {e} {e} {e} {e} {e} {e} {e} {e} {bksp}',
      '{tab} {e} {e} \u00E9 {e} {e} \u00FD \u00FA \u00ED \u00F3 {e} {e} {e}',
      '\u00E1 {e} {e} {e} {e} {e} {e} {e} {e} {e} \' {e} {enter}',
      '{shift} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    'alt-shift': [
      '\u00AC {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {bksp}',
      '{tab} {e} {e} \u00C9 {e} {e} \u00DD \u00DA \u00CD \u00D3 {e} {e} {e}',
      '\u00C1 {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {enter}',
      '{shift} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  }
}
const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
