/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  mongolian: {
    normal: [
      '= \u2116 - " \u20AE : . _ , % ? \u0435 \u0449 {bksp}',
      '{tab} \u0444 \u0446 \u0443 \u0436 \u044d \u043D \u0433 \u0448 \u04af \u0437 \u043A \u044A \\',
      '\u0439 \u044B \u0431 \u04e9 \u0430 \u0445 \u0440 \u043e \u043B \u0434 \u043f {enter}',
      '{shift} \u044F \u0447 \u0451 \u0441 \u043c \u0438 \u0442 \u044c \u0432 \u044e {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],

    shift: [
      '+ 1 2 3 4 5 6 7 8 9 0 \u0415 \u0429 {bksp}',
      '{tab} \u0424 \u0426 \u0423 \u0416 \u042d \u041D \u0413 \u0428 \u04AE \u0417 \u041a \u042A |',
      '\u0419 \u042B \u0411 \u04e8 \u0410 \u0425 \u0420 \u041e \u041b \u0414 \u041f {enter}',
      '{shift} \u042F \u0427 \u0401 \u0421 \u041c \u0418 \u0422 \u042c \u0412 \u042e {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} q w e r t y u i o p [ ] \\',
      'a s d f g h j k l ; \' {enter}',
      '{shift} z x c v b n m , . / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    'alt-shift': [
      '~ ! @ # $ % ^ & * ( ) _ + {bksp}',
      '{tab} Q W E R T Y U I O P { } |',
      'A S D F G H J K L : " {enter}',
      '{shift} Z X C V B N M < > ? {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  },
  'Mongolian Cyrillic': {
    normal: [
      '= \u2116 - " \u20ae : . _ , % ? \u0435 \u0449 {bksp}',
      '{tab} \u0444 \u0446 \u0443 \u0436 \u044d \u043d \u0433 \u0448 \u04af \u0437 \u043a \u044a \\',
      '\u0439 \u044b \u0431 \u04e9 \u0430 \u0445 \u0440 \u043e \u043b \u0434 \u043f {enter}',
      '{shift} \\ \u044f \u0447 \u0451 \u0441 \u043c \u0438 \u0442 \u044c \u0432 / {shift}',
      '{accept} {space} {cancel}'
    ],
    shift: [
      '+ 1 2 3 4 5 6 7 8 9 0 \u0415 \u0429 {bksp}',
      '{tab} \u0424 \u0426 \u0423 \u0416 \u042d \u041d \u0413 \u0428 \u04ae \u0417 \u041a \u042a |',
      '\u0419 \u042b \u0411 \u04e8 \u0410 \u0425 \u0420 \u041e \u041b \u0414 \u041f {enter}',
      '{shift} | \u042f \u0427 \u0401 \u0421 \u041c \u0418 \u0422 \u042c \u0412 / {shift}',
      '{accept} {space} {cancel}'
    ]
  },
  'Mongolian (Mongolian Script)': {
    normal: [
      '\u180d 1 2 3 4 5 6 7 8 9 0 \u202f = {bksp}',
      '{tab} \u1834 \u1823 \u1821 \u1837 \u1832 \u1836 \u1826 \u1822 \u1825 \u182b \u3014 \u3015 \u1801',
      '\u1820 \u1830 \u1833 \u1839 \u182d \u182c \u1835 \u183a \u182f ; \u180b {enter}',
      '{shift} {e} \u183d \u1831 \u183c \u1824 \u182a \u1828 \u182e \u1802 \u1803 / {shift}',
      '{accept} {space} {cancel}'
    ],
    shift: [
      '~ ! \u2048 \u2049 \u2014 % {ZWNJ} \u180a {ZWJ} ( ) \u180e + {bksp}',
      '{tab} {e} \u1838 \u1827 \u183f {e} {e} {e} {e} {e} {e} \u3008 \u3009 |',
      '{e} {e} {e} {e} {e} \u183e {e} \u183b \u1840 \u1804 \u180c {enter}',
      '{shift} {e} \u1841 {e} \u1842 {e} {e} \u1829 {e} \u300a \u300b / {shift}',
      '{accept} {space} {cancel}'
    ]
  }
}
const layout = layouts.mongolian
export { layout, layouts }
