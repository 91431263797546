/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  Telugu: {
    normal: [
      '\u0c4a 1 2 3 4 5 6 7 8 9 0 - \u0c43 {bksp}',
      '{tab} \u0c4c \u0c48 \u0c3e \u0c40 \u0c42 \u0c2c \u0c39 \u0c17 \u0c26 \u0c1c \u0c21 {e}',
      '\u0c4b \u0c47 \u0c4d \u0c3f \u0c41 \u0c2a \u0c30 \u0c15 \u0c24 \u0c1a \u0c1f {enter}',
      '{shift} \u0c46 \u0c02 \u0c2e \u0c28 \u0c35 \u0c32 \u0c38 , . \u0c2f {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\u0c12 {e} {e} \u0c4d\u0c30 {e} \u0c1c\u0c4d\u0c1e \u0c24\u0c4d\u0c30 \u0c15\u0c4d\u0c37 \u0c36\u0c4d\u0c30 ( ) \u0c03 \u0c0b {bksp}',
      '{tab} \u0c14 \u0c10 \u0c06 \u0c08 \u0c0a \u0c2d \u0c19 \u0c18 \u0c27 \u0c1d \u0c22 \u0c1e',
      '\u0c13 \u0c0f \u0c05 \u0c07 \u0c09 \u0c2b \u0c31 \u0c16 \u0c25 \u0c1b \u0c20 {enter}',
      '{shift} \u0c0e \u0c01 \u0c23 \u0c28 {e} \u0c33 \u0c36 \u0c37 {e} / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{e} \u0c67 \u0c68 \u0c69 \u0c6a \u0c6b \u0c6c \u0c6d \u0c6e \u0c6f \u0c66 {e} \u0c44 {bksp}',
      '{tab} {e} \u0c56 {e} {e} {e} {e} {e} {e} {e} {e} {e} {e}',
      '{e} \u0c55 {e} {e} {e} {e} {e} {e} {e} {e} {e} {enter}',
      '{shift} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    'alt-shift': [
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} \u0c60 {bksp}',
      '{tab} {e} {e} {e} \u0c61 {e} {e} {e} {e} {e} {e} {e} {e}',
      '{e} {e} {e} \u0c0c {e} {e} {e} {e} {e} {e} {e} {enter}',
      '{shift} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  }
}

const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
