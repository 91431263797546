/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  simple: {
    normal: [
      '\u0D12 \u0D67 \u0D68 \u0D69 \u0D6A \u0D6B \u0D6C \u0D6D \u0D6E \u0D6F \u0D66 - \u0D0B {bksp}',
      '{tab} \u0D14 \u0D10 \u0D06 \u0D08 \u0D0A \u0D2D \u0D19 \u0D18 \u0D27 \u0D1D \u0D22 \u0D1E \\',
      '{lock} \u0D13 \u0D0F \u0D05 \u0D07 \u0D09 \u0D2B \u0D31 \u0D16 \u0D25 \u0D1B \u0D20 {enter}',
      '{shift} \u0D0E \u0D03 \u0D23 \u0D34 \u0D33 \u0D36 \u0D37 . \u0D2F {shift}',
      '@ {space}'
    ],
    shift: [
      '\u0D4A ! @ # $ % ^ & * ) ( _ \u0D43 {bksp}',
      '{tab} \u0D4C \u0D48 \u0D3E \u0D40 \u0D42 \u0D2C \u0D39 \u0D17 \u0D26 \u0D1C \u0D21 \u0D4E \\',
      '{lock} \u0D4B \u0D47 \u0D4D \u0D3F \u0D41 \u0D2A \u0D30 \u0D15 \u0D24 \u0D1A \u0D1F {enter}',
      '{shift} \u0D46 \u0D02 \u0D2E \u0D28 \u0D35 \u0D32 \u0D38 . \u0D2F {shift}',
      '@ {space}'
    ]
  },
  Malayalam: {
    normal: [
      '\u0d4a 1 2 3 4 5 6 7 8 9 0 - \u0d43 {bksp}',
      '{tab} \u0d4c \u0d48 \u0d3e \u0d40 \u0d42 \u0d2c \u0d39 \u0d17 \u0d26 \u0d1c \u0d21 {e}',
      '\u0d4b \u0d47 \u0d4d \u0d3f \u0d41 \u0d2a \u0d30 \u0d15 \u0d24 \u0d1a \u0d1f {enter}',
      '{shift} \u0d46 \u0d02 \u0d2e \u0d28 \u0d35 \u0d32 \u0d38 , . \u0d2f {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\u0d12 {e} {e} \u0d4d\u0d30 {e} {e} {e} \u0d15\u0d4d\u0d37 {e} ( ) \u0d03 \u0d0b {bksp}',
      '{tab} \u0d14 \u0d10 \u0d06 \u0d08 \u0d0a \u0d2d \u0d19 \u0d18 \u0d27 \u0d1d \u0d22 \u0d1e',
      '\u0d13 \u0d0f \u0d05 \u0d07 \u0d09 \u0d2b \u0d31 \u0d16 \u0d25 \u0d1b \u0d20 {enter}',
      '{shift} \u0d0e {e} \u0d23 {e} \u0d34 \u0d33 \u0d36 \u0d37 {e} / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{e} \u0d67 \u0d68 \u0d69 \u0d6a \u0d6b \u0d6c \u0d6d \u0d6e \u0d6f \u0d66 {e} {e} {bksp}',
      '{tab} \u0d57 {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e}',
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {enter}',
      '{shift} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    'alt-shift': [
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} \u0d60 {bksp}',
      '{tab} {e} {e} {e} \u0d61 {e} {e} {e} {e} {e} {e} {e} {e}',
      '{e} {e} \u0d0c {e} {e} {e} {e} {e} {e} {e} {e} {enter}',
      '{shift} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  }
}

const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
