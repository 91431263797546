/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  simple: {
    normal: [
      '\u0965 \u09e7 \u09e8 \u09e9 \u09ea \u09eb \u09ec \u09ed \u09ee \u09ef \u09e6 \u002d \u09c3 {bksp}',
      '{tab} \u09cc \u09c8 \u09be \u09c0 \u09c2 \u09ac \u09b9 \u0997 \u09a6 \u099c \u09a1 \u09bc',
      '\u09cb \u09c7 \u09cd \u09bf \u09c1 \u09aa \u09f0 \u0995 \u09a4 \u099a \u099f {enter}',
      '{shift} \u0982 \u09ae \u09a8 \u09f1 \u09b2 \u09b8 \u002c \u002e \u09df {shift}',
      '@ {space}'
    ],
    shift: [
      '\u0021 \u0040 ( ) \u0983 \u098b {bksp}',
      '{tab} \u0994 \u0990 \u0986 \u0998 \u098a \u09ad \u0999 \u0998 \u09a7 \u099d \u09a2 \u099e',
      '\u0993 \u098f \u0985 \u0987 \u0989 \u09ab  \u0996 \u09a5 \u099b \u099b \u09a0 {enter}',
      '{shift} \u0981 \u09a3 \u09b6 \u09b7 \u0964 \u09af {shift}',
      '@ {space}'
    ]
  },
  'Assamese-INSCRIPT': {
    normal: [
      '\u0965 \u09e7 \u09e8 \u09e9 \u09ea \u09eb \u09ec \u09ed \u09ee \u09ef \u09e6 - \u09c3 {bksp}',
      '{tab} \u09cc \u09c8 \u09be \u09c0 \u09c2 \u09ac \u09b9 \u0997 \u09a6 \u099c \u09a1 \u09bc {e}',
      '\u09cb \u09c7 \u09cd \u09bf \u09c1 \u09aa \u09f0 \u0995 \u09a4 \u099a \u099f {enter}',
      '{shift} {e} {e} \u0982 \u09ae \u09a8 \u09f1 \u09b2 \u09b8 , . / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '{e} ! @ \u09cd\u09f0 \u09f0\u09cd \u099c\u09cd\u099e \u09a4\u09cd\u09f0 \u0995\u09cd\u09b7 \u09b6\u09cd\u09f0 ( ) \u0983 \u098b {bksp}',
      '{tab} \u0994 \u0990 \u0986 \u0988 \u098a \u09ad \u0999 \u0998 \u09a7 \u099d \u09a2 \u099e {e}',
      '\u0993 \u098f \u0985 \u0987 \u0989 \u09ab {e} \u0996 \u09a5 \u099b \u09a0 {enter}',
      '{shift} {e} {e} \u0981 \u09a3 {e} {e} {e} \u09b6 \u09b7 \u0964 / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} q w e r t y u i o p [ ] \\',
      'a s d f g h j k l ; \' {enter}',
      '{shift} z x c v b n m , . / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    'alt-shift': [
      '~ ! @ # $ % ^ & * ( ) _ + {bksp}',
      '{tab} Q W E R T Y U I O P { } |',
      'A S D F G H J K L : " {enter}',
      '{shift} Z X C V B N M < > ? {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  },
  Assamese: {
    normal: [
      '+ \u09E7 \u09E8 \u09E9 \u09EA \u09EB \u09EC \u09ED \u09EE \u09EF \u09E6 - \u09C3 {b}',
      '{t} \u09CC \u09C8 \u09BE \u09C0 \u09C2 \u09F1 \u09B9 \u0997 \u09A6 \u099C \u09A1 {enter}',
      '\u09CB \u09C7 \u09CD \u09BF \u09C1 \u09AA \u09F0 \u0995 \u09A4 \u099A \u099F \u09BC',
      '{s} \u09CE \u0982 \u09AE \u09A8 \u09AC \u09B2 \u09B8 , . \u09AF {s}',
      '{space} {alt} {accept}'
    ],
    shift: [
      '? { } \u09CD\u09F0 \u09F0\u09CD \u099C\u09CD\u09F0 \u0995\u09CD\u09B7 \u0995\u09CD\u09F0 \u09B6\u09CD\u09F0 ( ) {e} \u098B {b}',
      '{t} \u0994 \u0990 \u0986 \u0988 \u098A \u09AD \u0999 \u0998 \u09A7 \u099D \u09A2 {enter}',
      '\u0993 \u098F \u0985 \u0987 \u0989 \u09AB {e} \u0996 \u09A5 \u099B \u09A0 \u099E',
      '{s} \u0983 \u0981 \u09A3 \u09F7 " \' \u09B6 \u09B7 ; \u09DF {s}',
      '{space} {alt} {accept}'
    ],
    alt: [
      '{e} \u09E7 \u09E8 \u09E9 \u09EA \u09EB \u09EC \u09ED \u09EE \u09EF \u09E6 {e} \u09E2 {b}',
      '{t} \u09D7 {e} {e} \u09E3 {e} {e} {e} {e} {e} {e} \u09DC {enter}',
      '\u09F4 \u09F6 \u09F8 \u09E2 {e} {e} \u09F0 {e} {e} {e} {e} {e}',
      '{s} {e} \u09FA {e} {e} {e} {e} {e} {e} {e} {e} {s}',
      '{space} {alt} {accept}'
    ],
    'alt-shift': [
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} \u09E0 {b}',
      '{t} {e} {e} {e} \u09E1 {e} {e} {e} {e} {e} {e} \u09DD {enter}',
      '\u09F5 \u09F7 \u09F9 \u098C {e} {e} \u09F1 {e} {e} {e} {e} {e}',
      '{s} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {s}',
      '{space} {alt} {accept}'
    ]
  }
}
const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
