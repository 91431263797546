/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  Slovenian: {
    normal: [
      '\u00b8 1 2 3 4 5 6 7 8 9 0 \' + {bksp}',
      '{tab} q w e r t z u i o p \u0161 \u0111 \u017e',
      'a s d f g h j k l \u010d \u0107 {enter}',
      '{shift} < y x c v b n m , . / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\u00a8 ! " # $ % & / ( ) = ? * {bksp}',
      '{tab} Q W E R T Z U I O P \u0160 \u0110 \u017d',
      'A S D F G H J K L \u010c \u0106 {enter}',
      '{shift} > Y X C V B N M ; : / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{e} ~ \u02c7 ^ \u02d8 \u00b0 \u02db ` \u02d9 \u00b4 \u02dd \u00a8 \u00b8 {bksp}',
      '{tab} \\ | \u20ac {e} {e} {e} {e} {e} {e} {e} \u00f7 \u00d7 \u00a4',
      '{e} {e} {e} [ ] {e} {e} \u0142 \u0141 {e} \u00df {enter}',
      '{shift} {e} {e} {e} {e} @ { } \u00a7 < > {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  }
}
const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
