/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  'latvian-qwerty': {
    normal: [
      '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} q w e r t y u i o p [ ] \u00B0',
      'a s d f g h j k l ; \' {enter}',
      '{shift} z x c v b n m , . / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '~ ! @ # $ % ^ & * ( ) _ + {bksp}',
      '{tab} Q W E R T Y U I O P { } |',
      'A S D F G H J K L : " {enter}',
      '{shift} Z X C V B N M < > ? {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '\u00ac 1 \u00AB \u00BB \u20AC 5 \u2019 7 8 9 0 \u2013 = {bksp}',
      '{tab} q w \u0113 \u0157 t y \u016B \u012B \u014D p [ ] \u00B0',
      '\u0101 \u0161 d f \u0123 h j \u0137 \u013C ; \u00B4 {enter}',
      '{shift} \u017E x \u010D v b \u0146 m , . / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    'alt-shift': [
      '~ 1 2 3 4 \u00A7 \u00AB 7 \u00B1 \u00D7 0 \u2014 = {bksp}',
      '{tab} Q W \u0112 \u0156 T Y \u016A \u012A \u014C P { } |',
      '\u0100 \u0160 D F \u0122 H J \u0136 \u013B : \u00A8 {enter}',
      '{shift} \u017D X \u010C V B \u0145 M < > ? {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  },
  Latvian: {
    normal: [
      '\u00ad 1 2 3 4 5 6 7 8 9 0 - f {bksp}',
      '{tab} \u016b g j r m v n z \u0113 \u010d \u017e h \u0137',
      '\u0161 u s i l d a t e c \u00b4 {enter}',
      '{shift} \u0123 \u0146 b \u012b k p o \u0101 , . / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '? ! \u00ab \u00bb $ % / & \u00d7 ( ) _ F {bksp}',
      '{tab} \u016a G J R M V N Z \u0112 \u010c \u017d H \u0136',
      '\u0160 U S I L D A T E C \u00b0 {enter}',
      '{shift} \u0122 \u0145 B \u012a K P O \u0100 ; : / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{e} \u00ab {e} {e} \u20ac " \u2019 {e} : {e} {e} \u2013 = {bksp}',
      '{tab} q \u0123 {e} \u0157 w y {e} {e} {e} {e} [ ] {e}',
      '{e} {e} {e} {e} {e} {e} {e} {e} \u20ac {e} \u00b4 {enter}',
      '{shift} \\ {e} x {e} \u0137 {e} \u00f5 {e} < > {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    'alt-shift': [
      '{e} {e} @ # $ ~ ^ \u00b1 {e} {e} {e} \u2014 ; {bksp}',
      '{tab} Q \u0122 {e} \u0156 W Y {e} {e} {e} {e} { } {e}',
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} \u00a8 {enter}',
      '{shift} | {e} X {e} \u0136 {e} \u00d5 {e} {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  },
  'Latvian (QWERTY)': {
    normal: [
      '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} q w e r t y u i o p [ ] {e}',
      'a s d f g h j k l ; \' {enter}',
      '{shift} \\ z x c v b n m , . / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '~ ! @ # $ % ^ & * ( ) _ + {bksp}',
      '{tab} Q W E R T Y U I O P { } |',
      'A S D F G H J K L : " {enter}',
      '{shift} | Z X C V B N M < > / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '\u00ad {e} \u00ab \u00bb \u20ac {e} \u2019 {e} {e} {e} {e} \u2013 {e} {bksp}',
      '{tab} {e} {e} \u0113 \u0157 {e} {e} \u016b \u012b \u00f5 {e} {e} {e} {e}',
      '\u0101 \u0161 {e} {e} \u0123 {e} {e} \u0137 \u013c {e} \u00b4 {enter}',
      '{shift} {e} \u017e {e} \u010d {e} {e} \u0146 {e} {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    'alt-shift': [
      '{e} {e} {e} {e} \u00a7 \u00b0 {e} \u00b1 \u00d7 {e} {e} \u2014 {e} {bksp}',
      '{tab} {e} {e} \u0112 \u0156 {e} {e} \u016a \u012a \u00d5 {e} {e} {e} {e}',
      '\u0100 \u0160 {e} {e} \u0122 {e} {e} \u0136 \u013b {e} \u00a8 {enter}',
      '{shift} {e} \u017d {e} \u010c {e} {e} \u0145 {e} {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  }
}
const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
