/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  'vietnamese-qwerty': {
    normal: [
      '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} q w e r t y u i o p [ ] \\',
      'a s d f g h j k l ; \' {enter}',
      '{shift} z x c v b n m < > , . / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '~ ! @ # $ % ^ & * ( ) _ + {bksp}',
      '{tab} Q W E R T Y U I O P { } |',
      'A S D F G H J K L : \' {enter}',
      '{shift} Z X C V B N M < > ; : ? {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{e} \u0103 \u00e2 \u00ea \u00f4 {e} \u031b {e} {e} {e} \u0111 \u002d \u20ab {bksp}',
      '{tab} {e} {e} {e} {e} {e} \u00ff {e} {e} \u0153 {e} \u01b0 \u01a1 {e}',
      '{e} {e} {e} {e} {e} {e} {e} {e} { } \u0302 {enter}',
      '{shift} {e} {e} \u00e7 {e} {e} {e} {e} {e} {e} {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    'alt-shift': [
      '{e} \u0102 \u00c2 \u00ca \u00d4 {e} {e} {e} {e} {e} \u0110 \u005f + {bksp}',
      '{tab} {e} {e} {e} {e} {e} \u0178 {e} {e} \u0152 {e} \u01af \u01a0 {e}',
      '{e} {e} {e} {e} {e} {e} {e} {e} { } \u0308 {enter}',
      '{shift} {e} {e} \u00c7 {e} {e} {e} {e} {e} {e} {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  },
  Vietnamese: {
    normal: [
      '` \u0103 \u00e2 \u00ea \u00f4 \u0300 \u0309 \u0303 \u0301 \u0323 \u0111 - \u20ab {bksp}',
      '{tab} q w e r t y u i o p \u01b0 \u01a1 \u005c',
      'a s d f g h j k l ; \' {enter}',
      '{shift} \u005c z x c v b n m , . / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '~ \u0102 \u00c2 \u00ca \u00d4 \u0300 \u0309 \u0303 \u0301 \u0323 \u0110 _ + {bksp}',
      '{tab} Q W E R T Y U I O P \u01af \u01a0 |',
      'A S D F G H J K L : \u0022 {enter}',
      '{shift} | Z X C V B N M < > / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} q w e r t y u i o p [ ] \u005c',
      'a s d f g h j k l ; \' {enter}',
      '{shift} \u005c z x c v b n m , . {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    'alt-shift': [
      '~ ! @ # $ % ^ & * ( ) _ + {bksp}',
      '{tab} Q W E R T Y U I O P { } |',
      'A S D F G H J K L : \u0022 {enter}',
      '{shift} | Z X C V B N M < > {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  }
}
const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
