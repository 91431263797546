/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  simple: {
    normal: [
      '` \u06f1 \u06f2 \u06f3 \u06f4 \u06f5 \u06f6 \u06f7 \u06f8 \u06f9 \u06f0 - = {bksp}',
      '{tab} \u0636 \u0635 \u062b \u0642 \u0641 \u063a \u0639 \u0647 \u062e \u062d \u062c \u0686 \\',
      '{lock} \u0634 \u0633 \u06cc \u0628 \u0644 \u0627 \u062a \u0646 \u0645 \u06a9 \u06af {enter}',
      '{shift} \u0638 \u0637 \u0632 \u0631 \u0630 \u062f \u067e \u0648 \u002e \u002f {shift}',
      '@ {space}'
    ],
    shift: [
      '\u00f7 \u0021 \u066c \u066b \ufdfc \u066a \u00d7 \u06f7 \u002a \u0029 \u0028 \u0640 \u002b {bksp}',
      '{tab} \u0652 \u064c \u064d \u064b \u064f \u0650 \u064e \u0651 \u005d \u005b \u007d \u007b',
      '{lock} \u0624 \u0626 \u064a \u0625 \u0623 \u0622 \u0629 \u00bb \u00ab \u003a \u061b {enter}',
      '{shift} \u0643 \u0653 \u0698 \u0670 \u200c \u0654 \u0621 \u003c \u003e \u061f {shift}',
      '@ {space}'
    ]
  },
  'fa-AF Dari': {
    rtl: true,
    normal: [
      '{ZWJ} \u06F1 \u06F2 \u06F3 \u06F4 \u06F5 \u06F6 \u06F7 \u06F8 \u06F9 \u06F0 - = {b}',
      '{t} \u0636 \u0635 \u062B \u0642 \u0641 \u063A \u0639 \u0647 \u062E \u062D \u062C \u0686 \\',
      '\u0634 \u0633 \u06CC \u0628 \u0644 \u0627 \u062A \u0646 \u0645 \u06A9 \u06AF {enter}',
      '{s} \u0638 \u0637 \u0632 \u0631 \u0630 \u062F \u067E \u0648 . / {s}',
      '{space} {alt} {accept}'
    ],
    shift: [
      '\u00F7 ! \u066C \u066B \u060B \u066A \u00D7 \u060C * ) ( \u0640 + {b}',
      '{t} \u0652 \u064C \u064D \u064B \u064F \u0650 \u064E \u0651 ] [ } { |',
      '\u0624 \u0626 \u064A \u0625 \u0623 \u0622 \u0629 \u00BB \u00AB : \u061B {enter}',
      '{s} \u0643 \u0653 \u0698 \u0670 {ZWNJ} \u0654 \u0621 > < \u061F {s}',
      '{space} {alt} {accept}'
    ],
    alt: [
      '~ ` @ # $ % ^ & \u2022 {LRM} {RLM} _ {e} {b}',
      '{t} \u00B0 {e} \u20AC \uFD3E \uFD3F \u0656 \u0659 \u0655 \' " \u0681 \u0685 ?',
      '\u069A \u06CD \u0649 \u06D0 \u06B7 \u0671 \u067C \u06BC \u06BA ; \u06AB {enter}',
      '{s} \u06D2 \u0691 \u0696 \u0693 \u0688 \u0689 \u0679 , \u06C7 \u06C9 {s}',
      '{space} {alt} {accept}'
    ]
  }
}

const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
