/* eslint-disable sort-keys */
/* Japanese (hiragana and katakana) keyboard layouts
 * Japanese layout by Paco Alcantara (https://github.com/pacoalcantara), based on:
 * http://www.fileformat.info/info/unicode/char/search.htm
 * http://www.tamasoft.co.jp/en/general-info/unicode.html
 * http://ascii-table.com/keyboard.php/194
 * Preferably use 'japanese-kana' unless roma-ji characters are needed on input.
 */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  simple: {
    normal: [
      '1 2 3 4 5 6 7 8 9 0 - ^ ¥ {bksp}',
      '{tab} た て い す か ん な に ら せ ゛ ゜ む',
      '{lock} ち と し は き く ま の り れ け {enter}',
      '{shift} つ さ そ ひ こ み も ね る め {shift}',
      '{space}'
    ],
    shift: [
      '! " # $ % & \' ( ) ́ = ~ | {bksp}',
      '{tab} た て ぃ す か ん な に ら せ 「 」 む',
      '{lock} ち と し は き く ま の り れ け {enter}',
      '{shift} っ さ そ ひ こ み も 、 。 ・ {shift}',
      '{space}'
    ]
  },
  'japanese-kana': {
    normal: [
      '0 1 2 3 4 5 6 7 8 9',
      'あ い う え お は ひ ふ へ ほ',
      'か き く け こ ま み む め も',
      'さ し す せ そ や ゆ よ {bksp}',
      'た ち つ て と ら り る れ ろ',
      'な に ぬ ね の わ を ん ゐ ゑ',
      'ぁ ぃ ぅ ぇ ぉ ゙ ゚ ́ {shift}',
      'ゃ ゅ ょ っ ́ ・ ー 〜 {alt}',
      '{accept} {space} {cancel} {enter}'
    ],
    shift: [
      '0 1 2 3 4 5 6 7 8 9',
      'ア イ ウ エ オ ハ ヒ フ ヘ ホ',
      'カ キ ク ケ コ マ ミ ム メ モ',
      'サ シ ス セ ソ ヤ ユ ヨ  {bksp}',
      'タ チ ツ テ ト ラ リ ル レ ロ',
      'ナ ニ ヌ ネ ノ ワ ヲ ン ヰ ヱ',
      'ァ ィ ゥ ェ ォ ゙ ゚ ́ {shift}',
      'ャ ュ ョ ッ ́ ・ ー 〜 {alt}',
      '{accept} {space} {cancel} {enter}'
    ],
    alt: [
      '0 1 2 3 4 5 6 7 8 9 - ^ ￥ {bksp}',
      '{tab} q w e r t y u i o p @ [',
      'a s d f g h j k l ; : ] {enter}',
      ' {shift} z x c v b n m , . / \\  {shift}',
      '{accept} {space} {altGr} {cancel}'
    ],
    'alt-shift': [
      '0 1 2 3 4 5 6 7 8 9 - ^ ￥ {bksp}',
      '{tab} Q W E R T Y U I O P @ [',
      'A S D F G H J K L ; : ] {enter}',
      ' {shift} Z X C V B N M , . / \\ {shift}',
      '{accept} {space} {altGr} {cancel}'
    ]
  },
  'japanese-qwerty': {
    normal: [
      '1 2 3 4 5 6 7 8 9 0 - ^ \u00a5 {bksp}',
      '{tab} q w e r t y u i o p \u0040 [',
      'a s d f g h j k l ; : ] {enter}',
      '{shift} z x c v b n m , . / \\ {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '! " # $ % & \' ( ) \u0301 = ~ | {bksp}',
      '{tab} Q W E R T Y U I O P \u0060 {',
      'A S D F G H J K L + * } {enter}',
      '{shift} Z X C V B N M < > ? _ {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      'ぬ ふ あ う え お や ゆ よ わ ほ へ ｰ {bksp}',
      '{tab} た て い す か ん な に ら せ ﾞ ﾟ',
      'ち と し は き く ま の り れ け む {enter}',
      '{shift} つ さ そ ひ こ み も ね る め ろ {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    'alt-shift': [
      '́ ́ ぁ ぅ ぇ ぉ ゃ ゅ ょ を ́ ́ ́ {bksp}',
      '{tab} ́ ́ ぃ ́ ́ ́ ́ ́ ́ ́ ́ ｢',
      '́ ́ ́ ́ ́ ́ ́ ́ ́ ́ ヶ ｣ {enter}',
      '{shift} っ ́ ́ ́ ́ ́ ́ ､ ｡ ･ ¦ {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  },
  'Japanese Hiragana': {

    // Hiragana
    normal: [
      'ろ:Ro ぬ:Nu ふ:Hu あ:A う:U え:E お:O や:Ya ゆ:Yu よ:Yo わ:Wa ほ:Ho へ:He {bksp}',
      '{tab} た:Ta て:Te い:I す:Su か:Ka ん:N な:Na に:Ni ら:Ra せ:Se ゛ ゜ む:Mu',
      'ち:Ti と:To し:Si は:Ha き:Ki く:Ku ま:Ma の:No り:Ri れ:Re け:Ke {enter}',
      '{shift} つ:Tu さ:Sa そ:So ひ:Hi こ:Ko み:Mi も:Mo ね:Ne る:Ru め:Me {shift}',
      '{accept} {space} {meta3} {meta1:Kana} {cancel}'
    ],
    shift: [
      'ろ:Ro ぬ:Nu ふ:Hu ぁ:a ぅ:u ぇ:e ぉ:o ゃ:ya ゅ:yu ょ:yo を:Wo ー へ:He {bksp}',
      '{tab} た:Ta て:Te ぃ:i す:Su か:Ka ん:N な:Na に:Ni ら:Ra せ:Se 「 」 む:Mu',
      'ち:Ti と:To し:Si は:Ha き:Ki く:Ku ま:Ma の:No り:Ri れ:Re け:Ke {enter}',
      '{shift} っ:tu さ:Sa そ:So ひ:Hi こ:Ko み:Mi も:Mo 、 。 ・ {shift}',
      '{accept} {space} {meta3} {meta1:Kana} {cancel}'
    ]
  },
  ja: {
    normal: [
      '～ ぬ ふ あ う え お や ゆ よ わ ほ へ {b}',
      '{t} た て い す か ん な に ら せ 、 。 ￥ ゛',
      'ち と し は き く ま の れ け む {enter}',
      '{s} つ さ そ ひ こ み も ね る め ろ {s}',
      '{space} {alt} {accept}'
    ],
    shift: [
      '{e} ヌ フ ア ウ エ オ ヤ ユ ヨ ワ ホ ヘ {b}',
      '{t} タ テ イ ス カ ン ナ ニ ラ セ 、 。 {e} "',
      'チ ト シ ハ キ ク マ ノ レ ケ ム {enter}',
      '{s} ツ サ ソ ヒ コ ミ モ ネ ル メ ロ {s}',
      '{space} {alt} {accept}'
    ],
    alt: [
      '{e} {e} {e} ぁ ぅ ぇ ぉ ゃ ゅ ょ を ー ＾ {b}',
      '{t} {e} {e} ぃ {e} {e} {e} {e} {e} {e} {e} ＠ 「 {e} ￥',
      '{e} {e} {e} {e} {e} {e} {e} {e} ； ： 」 {enter}',
      '{s} {e} {e} {e} {e} {e} {e} {e} 、 。 ・ {e} {s}',
      '{space} {alt} {accept}'
    ],
    'alt-shift': [
      '{e} {e} {e} ァ ゥ ェ ォ ャ ュ ョ ヲ ＝ ～ {b}',
      '{t} {e} {e} ィ {e} {e} {e} {e} {e} {e} {e} ‘ ｛ ＊ ｜',
      '{e} {e} {e} {e} {e} {e} {e} {e} ＋ ヶ ｝ {enter}',
      '{s} {e} {e} {e} {e} {e} {e} {e} ＜ ＞ ？ ＿ {s}',
      '{space} {alt} {accept}'
    ]
  }
}
const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
