/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  simple: {
    normal: [
      '\u0661 \u0662 \u0663 \u0664 \u0665 \u0666 \u0667 \u0668 \u0669 \u0660 - = {bksp}',
      '{tab} \u0642 \u0648 \u06d5 \u0631 \u062a \u06cc \u0626 \u0639 \u06c6 \u067e',
      '{lock} \u0627 \u0633 \u062f \u0641 \u06af \u0647 \u0698 \u06a9 \u0644 {enter}',
      '{shift} \u0632 \u062e \u062c \u06a4 \u0628 \u0646 \u0645 {shift}',
      '@ {space}'
    ],
    shift: [
      '! @ # $ ٪ ^ & * ( ) _ + {bksp}',
      '{tab} \u066f \u0648\u0648 \u064a \u0695 \u0637 \u06ce \u0621 \u062d \u0624 \u062b',
      '{lock} \u0622 \u0634 \u0630 \u0625 \u063a \u200c \u0623 \u0643 \u06b5 {enter}',
      '{shift} \u0636 \u0635 \u0686 \u0638 \u0649 \u0629 \u0640 {shift}',
      '@ {space}'
    ]
  }
}

const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
