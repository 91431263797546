/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  simple: {
    normal: [
      '\u065a \u06f1 \u06f2 \u06f3 \u06f4 \u06f5 \u06f6 \u06f7 \u06f8 \u06f9 \u06f0 \u002d \u003d {bksp}',
      '{tab} \u0636 \u0635 \u0626 \u0642 \u0641 \u063a \u0639 \u0647 \u062e \u062d \u062c \u0686 \u0623',
      '{lock} \u0634 \u0633 \u064A \u0628 \u0644 \u0627 \u062a \u0646 \u0645 \u06a9 \u06af {enter}',
      '{shift} \u0624 \u06CA \u0632 \u0631 \u0630 \u062f \u067e \u0648 \u002e \u002f {shift}',
      '@ {space}'
    ],
    shift: [
      '\u02c7 \u0021 \u0040 \u0023 \ufdfc \u066a \u00d7 \u060c \u002a \u0029 \u0028 \u0640 \u002b {bksp}',
      '{tab} \u0643 \u0629 \u062B \u064e \u005e \u00B0 \u064f \u00f7 \u005d \u005b \u007d \u007b \u0670',
      '{lock} \u06cb \u064b \u06cc \u0650 \u0027 \u0622 \u0649 \u002c \u005c \u003a \u061b {enter}',
      '{shift} \u0638 \u0637 \u0698 \u0022 \u0654 \u00bb \u00ab \u003c \u003e \u061f {shift}',
      '@ {space}'
    ]
  }
}

const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
