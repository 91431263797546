/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  simple: {
    normal: [
      '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} ; ς ε ρ τ υ θ ι ο π [ ] \\',
      '{lock} α σ δ φ γ η ξ κ λ ΄ \' {enter}',
      '{shift} < ζ χ ψ ω β ν μ , . / {shift}',
      '@ {space}'
    ],
    shift: [
      '~ ! @ # $ % ^ & * ( ) _ + {bksp}',
      '{tab} : ΅ Ε Ρ Τ Υ Θ Ι Ο Π { } |',
      '{lock} Α Σ Δ Φ Γ Η Ξ Κ Λ ¨ " {enter}',
      '{shift} > Ζ Χ Ψ Ω Β Ν Μ < > ? {shift}',
      '@ {space}'
    ]
  },
  Greek: {
    normal: [
      '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} ; \u03C2 \u03B5 \u03C1 \u03C4 \u03C5 \u03B8 \u03B9 \u03BF \u03C0 [ ]',
      '\u03B1 \u03C3 \u03B4 \u03C6 \u03B3 \u03B7 \u03BE \u03BA \u03BB \u0384 \' \\ {enter}',
      '{shift} < \u03B6 \u03C7 \u03C8 \u03C9 \u03B2 \u03BD \u03BC , . / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '~ ! @ # $ % ^ & * ( ) _ + {bksp}',
      '{tab} : \u0385 \u0395 \u03A1 \u03A4 \u03A5 \u0398 \u0399 \u039F \u03A0 { }',
      '\u0391 \u03A3 \u0394 \u03A6 \u0393 \u0397 \u039E \u039A \u039B \u00A8 " | {enter}',
      '{shift} > \u0396 \u03A7 \u03A8 \u03A9 \u0392 \u039D \u039C < > ? {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{e} {e} \u00B2 \u00B3 \u00A3 \u00A7 \u00B6 {e} \u00A4 \u00A6 \u00B0 \u00B1 \u00BD {bksp}',
      '{tab} {e} {e} \u20AC \u00AE {e} \u00A5 {e} {e} {e} {e} \u00AB \u00BB',
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} \u0385 {e} \u00AC {enter}',
      '{shift} {e} {e} {e} \u00A9 {e} {e} {e} {e} {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  },
  'Greek (220)': {
    normal: [
      '\u00BD 1 2 3 4 5 6 7 8 9 0 \' ] {bksp}',
      '{tab} \u037E \u03C2 \u03B5 \u03C1 \u03C4 \u03C5 \u03B8 \u03B9 \u03BF \u03C0 + }',
      '\u03B1 \u03C3 \u03B4 \u03C6 \u03B3 \u03B7 \u03BE \u03BA \u03BB \u0384 \u00A8 # {enter}',
      '{shift} < \u03B6 \u03C7 \u03C8 \u03C9 \u03B2 \u03BD \u03BC , . - {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\u00B1 ! " \u00A3 $ % & / ( ) = ? [ {bksp}',
      '{tab} : ~ \u0395 \u03A1 \u03A4 \u03A5 \u0398 \u0399 \u039F \u03A0 * {',
      '\u0391 \u03A3 \u0394 \u03A6 \u0393 \u0397 \u039E \u039A \u039B \u00A8 \u0385 @ {enter}',
      '{shift} > \u0396 \u03A7 \u03A8 \u03A9 \u0392 \u039D \u039C ; : _ {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{e} {e} \u00B2 \u00B3 \u00A3 \u00A7 \u00B6 {e} \u00A4 \u00A6 \u00B0 \u00B1 \u00BD {bksp}',
      '{tab} {e} {e} \u20AC \u00AE {e} \u00A5 {e} {e} {e} {e} \u00AB \u00BB',
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} \u0385 \u0385 \u00AC {enter}',
      '{shift} {e} {e} {e} \u00A9 {e} {e} {e} {e} {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  },
  'Greek (319)': {
    normal: [
      '\u00BD 1 2 3 4 5 6 7 8 9 0 \' + {bksp}',
      '{tab} \u00B7 \u03C2 \u03B5 \u03C1 \u03C4 \u03C5 \u03B8 \u03B9 \u03BF \u03C0 [ ]',
      '\u03B1 \u03C3 \u03B4 \u03C6 \u03B3 \u03B7 \u03BE \u03BA \u03BB \u0384 \u2019 \u00B2 {enter}',
      '{shift} \u00A7 \u03B6 \u03C7 \u03C8 \u03C9 \u03B2 \u03BD \u03BC , . - {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\u00B1 ! " \u00A3 $ % \u00AC / ( ) = \u00B0 * {bksp}',
      '{tab} \u2015 \u00A6 \u0395 \u03A1 \u03A4 \u03A5 \u0398 \u0399 \u039F \u03A0 \u00AB \u00BB',
      '\u0391 \u03A3 \u0394 \u03A6 \u0393 \u0397 \u039E \u039A \u039B \u00A8 \u2018 \u00B3 {enter}',
      '{shift} \u00A9 \u0396 \u03A7 \u03A8 \u03A9 \u0392 \u039D \u039C ; : _ {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {bksp}',
      '{tab} {e} {e} \u20AC {e} {e} {e} {e} {e} {e} {e} {e} {e}',
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} \u0385 {e} {e} {enter}',
      '{shift} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  },
  'Greek Polytonic': {
    normal: [
      '~ 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} ; \u03C2 \u03B5 \u03C1 \u03C4 \u03C5 \u03B8 \u03B9 \u03BF \u03C0 [ ]',
      '\u03B1 \u03C3 \u03B4 \u03C6 \u03B3 \u03B7 \u03BE \u03BA \u03BB \u0384 \' \\ {enter}',
      '{shift} \u03B6 \u03C7 \u03C8 \u03C9 \u03B2 \u03BD \u03BC , . / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '` ! @ # $ % ^ & * ( ) _ + {bksp}',
      '{tab} : \u0385 \u0395 \u03A1 \u03A4 \u03A5 \u0398 \u0399 \u039F \u03A0 { }',
      '\u0391 \u03A3 \u0394 \u03A6 \u0393 \u0397 \u039E \u039A \u039B \u00A8 " | {enter}',
      '{shift} \u0396 \u03A7 \u03A8 \u03A9 \u0392 \u039D \u039C < > ? {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '\u1FC1 \u03DA \u03DE \u03E0 \u00A3 \u00A7 \u00B6 {e} \u00A4 \u00A6 \u00B0 \u00B1 \u00BD {bksp}',
      '{tab} \u1FFD {e} \u20AC \u00AE {e} \u00A5 {e} {e} {e} {e} \u00AB \u00BB',
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} \u0385 \u1FBF \u00AC {enter}',
      '{shift} {e} {e} \u00A9 {e} {e} {e} {e} {e} {e} \u1FBE {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    'alt-shift': [
      '{e} {e} \u00B2 \u00B3 {e} {e} {e} {e} {e} {e} {e} {e} \u1FDF {bksp}',
      '{tab} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} \u0387',
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} \u1FFE \u1FDD {enter}',
      '{shift} {e} {e} {e} {e} {e} {e} {e} {e} {e} \u1FDE {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  }
}

const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
