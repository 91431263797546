/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  'Pashto (Afghanistan)': {
    normal: [
      '{ZWJ} \u06f1 \u06f2 \u06f3 \u06f4 \u06f5 \u06f6 \u06f7 \u06f8 \u06f9 \u06f0 - = {bksp}',
      '{tab} \u0636 \u0635 \u062b \u0642 \u0641 \u063a \u0639 \u0647 \u062e \u062d \u062c \u0686 \\',
      '\u0634 \u0633 \u06cc \u0628 \u0644 \u0627 \u062a \u0646 \u0645 \u06a9 \u06ab {enter}',
      '{shift} \u0638 \u0637 \u0632 \u0631 \u0630 \u062f \u0693 \u0648 \u0696 / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\u0654 ! \u066c \u066b \u060b \u066a \u00d7 \u00bb \u00ab ) ( \u0640 + {bksp}',
      '{tab} \u0652 \u064c \u064d \u064b \u064f \u0650 \u064e \u0651 \u0681 \u0685 ] [ *',
      '\u069a \u06cd \u064a \u067e \u0623 \u0622 \u067c \u06bc \u0629 : \u061b {enter}',
      '{shift} \u0626 \u06d0 \u0698 \u0621 {ZWNJ} \u0689 \u0624 \u060c . / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '` ~ @ # $ % ^ & \u066d \u2022 \u00b0 _ \u00f7 {bksp}',
      '{tab} \u20ac \u0671 \u0649 {LRM} {RLM} \u0653 \u0659 \u0670 \' " } { |',
      '{e} {e} \u06d2 \u06ba \u06b7 \u0625 \u0679 > < \u0643 \u06af {enter}',
      '{shift} ? ; {e} {e} {e} \u0688 \u0691 , \u06c7 {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  }
}
const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
