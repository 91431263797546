/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  simple: {
    normal: [
      '\\ 1 2 3 4 5 6 7 8 9 0 \' \u00EC {bksp}',
      '{tab} q w e r t y u i o p \u00E8 +',
      '{lock} a s d f g h j k l \u00F2 \u00E0 \u00F9 {enter}',
      '{shift} < z x c v b n m , . - {shift}',
      '@ {space}'
    ],
    shift: [
      '| ! " \u00A3 $ % & / ( ) = ? ^ {bksp}',
      '{tab} Q W E R T Y U I O P \u00E9 *',
      '{lock} A S D F G H J K L \u00E7 \u00B0 \u00A7 {enter}',
      '{shift} > Z X C V B N M ; : _ {shift}',
      '@ {space}'
    ]
  },
  Italian: {
    normal: [
      '\\ 1 2 3 4 5 6 7 8 9 0 \' \u00EC {bksp}',
      '{tab} q w e r t y u i o p \u00E8 +',
      'a s d f g h j k l \u00F2 \u00E0 \u00F9 {enter}',
      '{shift} < z x c v b n m , . - {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '| ! " \u00A3 $ % & / ( ) = ? ^ {bksp}',
      '{tab} Q W E R T Y U I O P \u00E9 *',
      'A S D F G H J K L \u00E7 \u00B0 \u00A7 {enter}',
      '{shift} > Z X C V B N M ; : _ {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{e} {e} {e} {e} {e} \u20AC {e} {e} {e} {e} {e} {e} {e} {bksp}',
      '{tab} {e} {e} \u20AC {e} {e} {e} {e} {e} {e} {e} [ ]',
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} @ # {e} {enter}',
      '{shift} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    'alt-shift': [
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {bksp}',
      '{tab} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} { }',
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {enter}',
      '{shift} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  },
  'Italian (142)': {
    normal: [
      '\\ 1 2 3 4 5 6 7 8 9 0 \' \u00EC {bksp}',
      '{tab} q w e r t y u i o p \u00E8 +',
      'a s d f g h j k l \u00F2 \u00E0 \u00F9 {enter}',
      '{shift} < z x c v b n m , . - {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '| ! " \u00A3 $ % & / ( ) = ? ^ {bksp}',
      '{tab} Q W E R T Y U I O P \u00E9 *',
      'A S D F G H J K L \u00E7 \u00B0 \u00A7 {enter}',
      '{shift} > Z X C V B N M ; : _ {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{e} {e} {e} # {e} \u20AC {e} { [ ] } {e} {e} {bksp}',
      '{tab} @ {e} \u20AC {e} {e} {e} {e} {e} {e} {e} {e} ~',
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} ` {enter}',
      '{shift} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  }
}

const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
