/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  international: {
    normal: [
      '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} q w e r t y u i o p [ ] \\',
      'a s d f g h j k l ; \' {enter}',
      '{shift} z x c v b n m , . / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '~ ! @ # $ % ^ & * ( ) _ + {bksp}',
      '{tab} Q W E R T Y U I O P { } |',
      'A S D F G H J K L : " {enter}',
      '{shift} Z X C V B N M < > ? {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '~ ¡ ² ³ ¤ € ¼ ½ ¾ ‘ ’ ¥ × {bksp}',
      '{tab} ä å é ® þ ü ú í ó ö « » ¬',
      'á ß ð f g h j k ø ¶ ´ {enter}',
      '{shift} æ x © v b ñ µ ç > ¿ {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    'alt-shift': [
      '~ ¹ ² ³ £ € ¼ ½ ¾ ‘ ’ ¥ ÷ {bksp}',
      '{tab} Ä Å É ® Þ Ü Ú Í Ó Ö « » ¦',
      'Ä § Ð F G H J K Ø ° ¨ {enter}',
      '{shift} Æ X ¢ V B Ñ µ Ç . ¿ {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  }
}

const layout = layouts.international
export {
  layout, layouts
}
