/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  'danish-qwerty': {
    normal: [
      '\u00a7 1 2 3 4 5 6 7 8 9 0 + \u0301 {b}',
      '{tab} q w e r t y u i o p \u00e5 \u00a8',
      'a s d f g h j k l \u00e6 \u00f8 \' {enter}',
      '{shift} < z x c v b n m , . - {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\u00bd ! " # \u00a4 % & / ( ) = ? \u0300 {b}',
      '{tab} Q W E R T Y U I O P \u00c5 ^',
      'A S D F G H J K L \u00c6 \u00d8 * {enter}',
      '{shift} > Z X C V B N M ; : _ {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '\u00a7 1 @ \u00a3 $ 5 6 { [ ] } + | {b}',
      '{tab} q w € r t y u i o p \u00e5 ~',
      'a s d f g h j k l \u00e6 \u00f8 \' {enter}',
      '{shift} \\ z x c v b n \u00b5 , . - {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  },
  Danish: {
    normal: [
      '\u00BD 1 2 3 4 5 6 7 8 9 0 + \u00B4 {bksp}',
      '{tab} q w e r t y u i o p \u00E5 \u00A8',
      'a s d f g h j k l \u00E6 \u00F8 \'  {enter}',
      '{shift} < z x c v b n m , . - {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\u00A7 ! " # \u00A4 % & / ( ) = ? ` {bksp}',
      '{tab} Q W E R T Y U I O P \u00C5 ^',
      'A S D F G H J K L \u00C6 \u00D8 *  {enter}',
      '{shift} > Z X C V B N M ; : _ {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{e} {e} @ \u00A3 $ \u20AC {e} { [ ] } {e} | {bksp}',
      '{tab} {e} {e} \u20AC {e} {e} {e} {e} {e} {e} {e} {e} ~',
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {enter}',
      '{shift} \\ {e} {e} {e} {e} {e} {e} \u00B5 {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  }
}
const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
