/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  simple: {
    normal: [
      '„ 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} ქ წ ე რ ტ ყ უ ი ო პ [ ] \\',
      '{lock} ა ს დ ფ გ ჰ ჯ კ ლ ; \' {enter}',
      '{shift} ზ ხ ც ვ ბ ნ მ , . / {shift}',
      '@ {space}'
    ],
    shift: [
      '“ ! @ # $ % ^ & * ( ) _ + {bksp}',
      '{tab} ქ ჭ ე ღ თ ყ უ ი ო პ { } | ~',
      '{lock} ა შ დ ფ გ ჰ ჟ კ ₾ : " {enter}',
      '{shift} ძ ხ ჩ ვ ბ ნ მ < > ? {shift}',
      '@ {space}'
    ]
  },
  Georgian: {
    normal: [
      '\u201e ! ? \u2116 \u00a7 % : . ; , / \u2013 = {bksp}',
      '{tab} \u10e6 \u10ef \u10e3 \u10d9 \u10d4 \u10dc \u10d2 \u10e8 \u10ec \u10d6 \u10ee \u10ea (',
      '\u10e4 \u10eb \u10d5 \u10d7 \u10d0 \u10de \u10e0 \u10dd \u10da \u10d3 \u10df {enter}',
      '{shift} ( \u10ed \u10e9 \u10e7 \u10e1 \u10db \u10d8 \u10e2 \u10e5 \u10d1 / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\u201c 1 2 3 4 5 6 7 8 9 0 - + {bksp}',
      '{tab} \u10e6 \u10ef \u10e3 \u10d9 \u10d4 \u10dc \u10d2 \u10e8 \u10ec \u10d6 \u10ee \u10ea )',
      '\u10e4 \u10eb \u10d5 \u10d7 \u10d0 \u10de \u10e0 \u10dd \u10da \u10d3 \u10df {enter}',
      '{shift} ) \u10ed \u10e9 \u10e7 \u10e1 \u10db \u10d8 \u10e2 \u10e5 \u10d1 / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {bksp}',
      '{tab} {e} {e} {e} {e} \u10f1 {e} {e} {e} {e} {e} \u10f4 {e} {e}',
      '\u10f6 {e} \u10f3 {e} {e} {e} {e} {e} {e} {e} {e} {enter}',
      '{shift} {e} {e} {e} {e} {e} {e} \u10f2 {e} {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  },
  'Georgian (Ergonomic)': {
    normal: [
      '\u201e ! \u2116 , ; % : ? . ( ) - \u201c {bksp}',
      '{tab} \u10e9 \u10de \u10e3 \u10eb \u10ed \u10e2 \u10d7 \u10dc \u10d5 \u10e8 \u10d9 \u10e5 /',
      '\u10ee \u10d8 \u10d0 \u10d4 \u10dd \u10d3 \u10db \u10e1 \u10e0 \u10d1 \u10d2 {enter}',
      '{shift} \\ \u10ef \u10f0 \u10e7 \u10e6 \u10df \u10d6 \u10ea \u10da \u10e4 / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\' 1 2 3 4 5 6 7 8 9 0 + = {bksp}',
      '{tab} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} \u00a7',
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {enter}',
      '{shift} / {e} {e} {e} {e} {e} {e} {e} {e} {e} / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '` ! @ # $ \u20ac ^ & * ( ) \u2014 = {bksp}',
      '{tab} {e} {e} {e} {e} {e} {e} {e} \u10fc \u10f3 {e} [ ] \\',
      '\u10f4 \u10f2 \u10fa \u10f1 {e} {e} {e} {e} {e} {e} \u10f9 {enter}',
      '{shift} {e} {e} \u10f5 \u10f8 \u10f7 {e} {e} {e} {e} \u10f6 {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    'alt-shift': [
      '~ {e} {e} \u00a7 {e} {e} {e} {e} \u00b0 \u00ab \u00bb _ {e} {bksp}',
      '{tab} {e} {e} {e} {e} {e} {e} {e} I {e} {e} { } |',
      '{e} {e} D {e} {e} {e} {e} {e} L {e} " {enter}',
      '{shift} {e} {e} X C V {e} {e} M < > {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  },
  'Georgian (QWERTY)': {
    normal: [
      '\u201e 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} \u10e5 \u10ec \u10d4 \u10e0 \u10e2 \u10e7 \u10e3 \u10d8 \u10dd \u10de [ ] ~',
      '\u10d0 \u10e1 \u10d3 \u10e4 \u10d2 \u10f0 \u10ef \u10d9 \u10da ; \' {enter}',
      '{shift} \\ \u10d6 \u10ee \u10ea \u10d5 \u10d1 \u10dc \u10db , . / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\u201c ! @ # $ % ^ & * ( ) _ + {bksp}',
      '{tab} {e} \u10ed {e} \u10e6 \u10d7 {e} {e} {e} {e} {e} { } |',
      '{e} \u10e8 {e} {e} {e} {e} \u10df {e} {e} : " {enter}',
      '{shift} {e} \u10eb {e} \u10e9 {e} {e} N {e} < > / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{e} {e} \u201e \u201c {e} \u20ac {e} {e} \u00b0 {e} {e} \u2014 \u2013 {bksp}',
      '{tab} {e} {e} \u10f1 \u00ae {e} \u10f8 {e} \u10f2 {e} {e} {e} {e} {e}',
      '\u10fa {e} {e} \u10f6 \u10f9 \u10f5 \u10f7 {e} {e} {e} {e} {enter}',
      '{shift} {e} {e} \u10f4 \u00a9 \u10f3 {e} \u10fc {e} \u00ab \u00bb {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  }
}

const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
