/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  Setswana: {
    normal: [
      '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} q w e r t y u i o p [ ] \\',
      'a s d f g h j k l ; \' {enter}',
      '{shift} \\ z x c v b n m , . / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '~ ! @ # $ % ^ & * ( ) _ + {bksp}',
      '{tab} Q W E R T Y U I O P { } |',
      'A S D F G H J K L : " {enter}',
      '{shift} | Z X C V B N M < > / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{e} \u00a1 \u00b2 \u00b3 \u00a4 \u20ac \u00bc \u00bd \u00be \u2018 \u2019 \u00a5 \u00d7 {bksp}',
      '{tab} \u00e4 \u00e5 \u00e9 \u00ae \u00fe \u00fc \u00fa \u00ed \u00f3 \u00f6 \u00ab \u00bb \u00ac',
      '\u00e1 \u00df \u00f0 {e} {e} {e} {e} {e} \u00f8 \u00b6 \u00b4 {enter}',
      '{shift} {e} \u00e6 {e} \u00a9 {e} {e} \u00f1 \u00b5 \u00e7 \u0161 {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    'alt-shift': [
      '{e} \u00b9 {e} {e} \u00a3 {e} {e} {e} {e} {e} {e} {e} \u00f7 {bksp}',
      '{tab} \u00c4 \u00c5 \u00c9 {e} \u00de \u00dc \u00da \u00cd \u00d3 \u00d6 {e} {e} \u00a6',
      '\u00c1 \u00a7 \u00d0 {e} {e} {e} {e} {e} \u00d8 \u00b0 \u00a8 {enter}',
      '{shift} {e} \u00c6 {e} \u00a2 {e} {e} \u00d1 {e} \u00c7 \u0160 {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  },
  'Setswana (Sesotho sa Leboa)': {
    normal: [
      '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} q w e r t y u i o p [ ] \\',
      'a s d f g h j k l ; \' {enter}',
      '{shift} z x c v b n m , . / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '~ ! @ # $ % ^ & * ( ) _ + {bksp}',
      '{tab} Q W E R T Y U I O P { } |',
      'A S D F G H J K L : " {enter}',
      '{shift} Z X C V B N M < > / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{e} \u00a1 \u00b2 \u00b3 \u00a4 \u20ac \u00bc \u00bd \u00be \u2018 \u2019 \u00a5 \u00d7 {bksp}',
      '{tab} \u00e4 \u00e5 \u00e9 \u00ae \u00fe \u00fc \u00fa \u00ed \u00f3 \u00f6 \u00ab \u00bb \u00ac',
      '\u00e1 \u00df \u00f0 {e} {e} {e} {e} {e} \u00f8 \u00b6 \u00b4 {enter}',
      '{shift} \u00e6 {e} \u00a9 {e} {e} \u00f1 \u00b5 \u00e7 \u0161 {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    'alt-shift': [
      '{e} \u00b9 {e} {e} \u00a3 {e} {e} {e} {e} {e} {e} {e} \u00f7 {bksp}',
      '{tab} \u00c4 \u00c5 \u00c9 {e} \u00de \u00dc \u00da \u00cd \u00d3 \u00d6 {e} {e} \u00a6',
      '\u00c1 \u00a7 \u00d0 {e} {e} {e} {e} {e} \u00d8 \u00b0 \u00a8 {enter}',
      '{shift} \u00c6 {e} \u00a2 {e} {e} \u00d1 {e} \u00c7 \u0160 {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  }
}
const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
