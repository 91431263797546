/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  Tajik: {
    normal: [
      '\u0451 1 2 3 4 5 6 7 8 9 0 \u0493 \u04ef {bksp}',
      '{tab} \u0439 \u049b \u0443 \u043a \u0435 \u043d \u0433 \u0448 \u04b3 \u0437 \u0445 \u044a \\',
      '\u0444 \u04b7 \u0432 \u0430 \u043f \u0440 \u043e \u043b \u0434 \u0436 \u044d {enter}',
      '{shift} \\ \u044f \u0447 \u0441 \u043c \u0438 \u0442 \u04e3 \u0431 \u044e / {shift}',
      '{accept} {space} {cancel}'
    ],
    shift: [
      '\u0401 ! " \u2116 ; % : ? * ( ) \u0492 \u04ee {bksp}',
      '{tab} \u0419 \u049a \u0423 \u041a \u0415 \u041d \u0413 \u0428 \u04b2 \u0417 \u0425 \u042a /',
      '\u0424 \u04b6 \u0412 \u0410 \u041f \u0420 \u041e \u041b \u0414 \u0416 \u042d {enter}',
      '{shift} / \u042f \u0427 \u0421 \u041c \u0418 \u0422 \u04e2 \u0411 \u042e / {shift}',
      '{accept} {space} {cancel}'
    ]
  }
}
const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
