/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  km: {
    normal: [
      '« ១ ២ ៣ ៤ ៥ ៦ ៧ ៨ ៩ ០ ឥ ឲ {b}',
      '{t} ឆ ឹ េ រ ត យ ុ ិ ោ ផ ៀ ឪ ឮ',
      'ា ស ដ ថ ង ហ ្ ក ល ើ ់ {enter}',
      '{s} ឋ ខ ច វ ប ន ម ុំ ។ ៊ {s}',
      '{ZWSP} {space} {alt} {accept}'
    ],
    shift: [
      '» ! ៗ " ៛ % ៍ ័ ៏ ( ) ៌ = {b}',
      '{t} ឈ ឺ ែ ឬ ទ ួ ូ ី ៅ ភ ឿ ឧ ឭ',
      'ាំ ៃ ឌ ធ អ ះ ញ គ ឡ ោះ ៉ {enter}',
      '{s} ឍ ឃ ជ េះ ព ណ ំ ុះ ៕ ? {s}',
      '{e} {space} {alt} {accept}'
    ],
    alt: [
      '{ZWJ} {ZWNJ} @ ៑ $ € ៙ ៚ * { } x ៎ {b}',
      '{t} ៜ ៝ ឯ ឫ ឨ យើង {e} ឦ ឱ ឰ ឩ ឳ \\',
      '឵ {e} ៓ {e} ឤ ឣ ឴ ឝ ៘ ៖ ៈ {enter}',
      '{s} | ខ្ញុំ - + ឞ [ ] , . / {s}',
      '{NBSP} {space} {alt} {accept}'
    ],
    'alt-shift': [
      '{e} ៱ ៲ ៳ ៴ ៵ ៶ ៷ ៸ ៹ ៰ {e} {e} {b}',
      '{t} ᧠ ᧡ ᧢ ᧣ ᧤ ᧥ ᧦ ᧧ ᧨ ᧩ ᧪ ᧫ {e}',
      '᧬ ᧭ ᧮ ᧯ ᧰ ᧱ ᧲ ᧳ ᧴ ᧵ ᧶ {enter}',
      '{s} ᧷ ᧸ ᧹ ᧺ ᧻ ᧼ ᧽ ᧾ ᧿ {e} {s}',
      '{e} {space} {alt} {accept}'
    ]
  }
}

const layout = layouts.international
export {
  layout, layouts
}
