/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  Kazakh: {
    normal: [
      '( " ә і ң ғ , . ү ұ қ ө һ {bksp}',
      '{tab} й ц у к е н г ш щ з х ъ \\',
      'ф ы в а п р о л д ж э {enter}',
      '{shift} \\ я ч с м и т ь б ю № {shift}',
      '{accept} {space} {cancel}'
    ],
    shift: [
      ') ! Ә І Ң Ғ ; : Ү Ұ Қ Ө Һ {bksp}',
      '{tab} Й Ц У К Е Н Г Ш Щ З Х Ъ /',
      'Ф Ы В А П Р О Л Д Ж Э {enter}',
      '{shift} | Я Ч С М И Т Ь Б Ю ? {shift}',
      '{accept} {space} {cancel}'
    ]
  }
}
const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
