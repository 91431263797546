/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  Dutch: {
    normal: [
      '@ 1 2 3 4 5 6 7 8 9 0 / \u00B0 {bksp}',
      '{tab} q w e r t y u i o p \u00A8 * {enter}',
      'a s d f g h j k l + \u00B4 < ',
      '{shift} ] z x c v b n m , . - {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\u00A7 ! " # $ % & _ ( ) \' ? ~ {bksp}',
      '{tab} Q W E R T Y U I O P ^ | {enter}',
      'A S D F G H J K L \u00B1 ` > ',
      '{shift} [ Z X C V B N M ; : = {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '\u00AC \u00B9 \u00B2 \u00B3 \u00BC \u00BD \u00BE \u00A3 { } {e} \\ \u00B8 {bksp}',
      '{tab} {e} {e} \u20AC \u00B6 {e} {e} {e} {e} {e} {e} {e} {e} {enter}',
      '{e} \u00DF {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} ',
      '{shift} \u00A6 \u00AB \u00BB \u00A2 {e} {e} {e} \u00B5 {e} \u00B7 {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  }
}
const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
