/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  Greenlandic: {
    normal: [
      '\u00bd 1 2 3 4 5 6 7 8 9 0 + \u00b4 {bksp}',
      '{tab} q w e r t y u i o p \u00e5 \u00a8 \'',
      'a s d f g h j k l \u00e6 \u00f8 {enter}',
      '{shift} < z x c v b n m , . / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\u00a7 ! " # \u00a4 % & / ( ) = ? ` {bksp}',
      '{tab} Q W E R T Y U I O P \u00c5 ^ *',
      'A S D F G H J K L \u00c6 \u00d8 {enter}',
      '{shift} > Z X C V B N M ; : / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{e} {e} @ \u00a3 $ \u20ac {e} { [ ] } {e} | {bksp}',
      '{tab} {e} {e} \u20ac {e} {e} {e} {e} {e} {e} \u00fe {e} ~ {e}',
      '{e} \u00df \u00f0 {e} {e} {e} {e} \u0138 {e} {e} {e} {enter}',
      '{shift} \\ {e} {e} {e} {e} {e} {e} \u00b5 {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    'alt-shift': [
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {bksp}',
      '{tab} {e} {e} {e} {e} {e} {e} {e} {e} {e} \u00de {e} {e} {e}',
      '{e} {e} \u00d0 {e} {e} {e} {e} {e} {e} {e} {e} {enter}',
      '{shift} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  }
}
const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
