/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  simple: {
    normal: [
      '\u200C \u09e7 \u09e8 \u09e9 \u09ea \u09eb \u09ec \u09ed \u09ee \u09ef \u09e6 - = {bksp}',
      '{tab} \u09b8 \u09b9 \u09c7 \u09be \u09bf \u09c1 \u09cb \u0995 \u0997 \u0999 \u09af \u0982 \u09cd',
      '{lock} \u0985 \u0987 \u0989 \u099f \u09a1 \u09a8 \u09a4 \u09a6 \u09aa ; \' {enter}',
      '{shift} \u09ac \u09ae \u099a \u099c \u09b0 \u09b2 \u09b6 , . / {shift}',
      '@ {space}'
    ],
    shift: [
      '\u200d ! \u09cd\u09af \u09cd\u09b0 \u09f3 \u0025 \u0964 \u09b0\u09cd \u00d7 ( ) \u0981 + {bksp}',
      '{tab} \u0993 \u0994 \u09c8 \u09c3 \u09c0 \u09c2 \u09cc \u0996 \u0998 \u098b \u09df \u09ce \u0983',
      '{lock} \u0986 \u0988 \u098a \u09a0 \u09a2 \u09a3 \u09a5 \u09a7 \u09ab : " {enter}',
      '{shift} \u09ad \u099e \u099b \u099d \u09dc \u09dd \u09b7 \u098f \u0990 ? {shift}',
      '@ {space}'
    ]
  },
  Bengali: {
    normal: [
      '1 2 3 4 5 6 7 8 9 0 - \u09c3 {bksp}',
      '{tab} \u09cc \u09c8 \u09be \u09c0 \u09c2 \u09ac \u09b9 \u0997 \u09a6 \u099c \u09a1 \u09bc',
      '\u09cb \u09c7 \u09cd \u09bf \u09c1 \u09aa \u09b0 \u0995 \u09a4 \u099a \u099f {enter}',
      '{shift} \u0982 \u09ae \u09a8 \u09ac \u09b2 \u09b8 , . \u09af {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '{e} {e} \u09cd\u09b0 \u09b0\u09cd \u099c\u09cd\u09b0 \u09a4\u09cd\u09b7 \u0995\u09cd\u09b0 \u09b6\u09cd\u09b0 ( ) \u0983 \u098b {bksp}',
      '{tab} \u0994 \u0990 \u0986 \u0988 \u098a \u09ad \u0999 \u0998 \u09a7 \u099d \u09a2 \u099e',
      '\u0993 \u098f \u0985 \u0987 \u0989 \u09ab {e} \u0996 \u09a5 \u099b \u09a0 {enter}',
      '{shift} \u0981 \u09a3 {e} {e} {e} \u09b6 \u09b7 { \u09df {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '\u09e7 \u09e8 \u09e9 \u09ea \u09eb \u09ec \u09ed \u09ee \u09ef \u09e6 {e} \u09e2 {bksp}',
      '{tab} \u09d7 {e} {e} \u09e3 {e} {e} {e} {e} {e} {e} \u09dc {e}',
      '\u09f4 \u09f6 \u09f8 \u09e2 {e} {e} \u09f0 {e} {e} {e} {e} {enter}',
      '{shift} \u09fa {e} {e} {e} {e} {e} {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    'alt-shift': [
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} \u09e0 {bksp}',
      '{tab} {e} {e} {e} \u09e1 {e} {e} {e} {e} {e} {e} \u09dd {e}',
      '\u09f5 \u09f7 \u09f9 \u098c {e} {e} \u09f1 {e} {e} {e} {e} {enter}',
      '{shift} {e} {e} {e} {e} {e} {e} {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  }
}
const layout = layouts[Object.keys(layouts)[0]]
export {
  layout, layouts
}
