/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  simple: {
    normal: [
      '\u007c 1 2 3 4 5 6 7 8 9 0 \' \u00bf {bksp}',
      '{tab} q w e r t y u i o p \u0301 +',
      '{lock} a s d f g h j k l \u00f1 \u007b \u007d {enter}',
      '{shift} < z x c v b n m , . - {shift}',
      '@ {space}'
    ],
    shift: [
      '\u00b0 ! " # $ % & / ( ) = ? \u00a1 {bksp}',
      '{tab} Q W E R T Y U I O P \u0308 *',
      '{lock} A S D F G H J K L \u00d1 \u005b \u005d {enter}',
      '{shift} > Z X C V B N M ; : _ {shift}',
      '@ {space}'
    ]
  },
  'spanish-qwerty': {
    normal: [
      '\u007c 1 2 3 4 5 6 7 8 9 0 \' \u00bf {bksp}',
      '{tab} q w e r t y u i o p \u0301 +',
      'a s d f g h j k l \u00f1 \u007b \u007d {enter}',
      '{shift} < z x c v b n m , . - {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\u00b0 ! " # $ % & / ( ) = ? \u00a1 {bksp}',
      '{tab} Q W E R T Y U I O P \u0308 *',
      'A S D F G H J K L \u00d1 \u005b \u005d {enter}',
      '{shift} > Z X C V B N M ; : _ {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '\u00ac 1 2 3 4 5 6 7 8 9 0 \\ \u00bf {bksp}',
      '{tab} @ w e r t y u i o p \u0301 \u0303',
      'a s d f g h j k l \u00f1 \u0302 \u0300 {enter}',
      '{shift} < z x c v b n m , . - {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    'alt-shift': [
      '\u00b0 ! " # $ % & / ( ) = ? \u00a1 {bksp}',
      '{tab} Q W E R T Y U I O P \u0308 *',
      'A S D F G H J K L \u00d1 \u005b \u005d {enter}',
      '{shift} > Z X C V B N M ; : _ {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  },
  'spanish-qwerty-sp': {
    normal: [
      '\u00ba 1 2 3 4 5 6 7 8 9 0 \' \u00a1 {bksp}',
      '{tab} q w e r t y u i o p \u0300 +',
      'a s d f g h j k l \u00f1 \u0301 \u00e7 {enter}',
      '{shift} < z x c v b n m , . - {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\u00aa ! " \u00b7 $ % & / ( ) = ? \u00bf {bksp}',
      '{tab} Q W E R T Y U I O P \u005e *',
      'A S D F G H J K L \u00d1 \u0308 \u00c7 {enter}',
      '{shift} > Z X C V B N M ; : _ {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '\\ \u007c @ # 4 5 \u00ac 7 8 9 0 \u0027 \u00a1 {bksp}',
      '{tab} q w e r t y u i o p [ ]',
      'a s d f g h j k l \u00f1 { } {enter}',
      '{shift} \u007c z x c v b n m , . - {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    'alt-shift': [
      '\u00aa ! " \u00b7 $ % & / ( ) = ? \u00bf {bksp}',
      '{tab} Q W E R T Y U I O P [ ]',
      'A S D F G H J K L \u00d1 { } {enter}',
      '{shift} > Z X C V B N M ; : _ {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  },
  Spanish: {
    normal: [
      '\u00BA 1 2 3 4 5 6 7 8 9 0 \' \u00A1 {bksp}',
      '{tab} q w e r t y u i o p ` +',
      'a s d f g h j k l \u00F1 \u00B4 \u00E7 {enter}',
      '{shift} < z x c v b n m , . - {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\u00AA ! " \u00B7 $ % & / ( ) = ? \u00BF {bksp}',
      '{tab} Q W E R T Y U I O P ^ *',
      'A S D F G H J K L \u00D1 \u00A8 \u00C7 {enter}',
      '{shift} > Z X C V B N M ; : _ {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '\\ | @ # ~ \u20AC \u00AC {e} {e} {e} {e} {e} {e} {bksp}',
      '{tab} {e} {e} \u20AC {e} {e} {e} {e} {e} {e} {e} [ ]',
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} { } {enter}',
      '{shift} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  },
  'Spanish Variation': {
    normal: [
      '\' 1 2 3 4 5 6 7 8 9 0 - \u00A8 {bksp}',
      '{tab} q w e r t y u i o p \u00F7 `',
      'a s d f g h j k l \u00F1 \u00E7 \u00B4 {enter}',
      '{shift} < z x c v b n m , . = {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\u00B7 \u00AA " / ( ) \u00A1 ! \u00BF ? \u20A7 + \u00A8 {bksp}',
      '{tab} Q W E R T Y U I O P \u00D7 `',
      'A S D F G H J K L \u00D1 \u00C7 \u00B4 {enter}',
      '{shift} > Z X C V B N M ; : % {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '\\ | @ # \u00BC \u00BD \u00AC _ # \u00A7 \\ * ~ {bksp}',
      '{tab} {e} {e} \u20AC {e} {e} {e} {e} {e} {e} {e} [ ]',
      '$ & @ [ ] | \u00A3 \u00B1 {e} ~ { } {enter}',
      '{shift} {e} {e} {e} {e} {e} {e} {e} {e} {e} ^ {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  },
  'Latin American': {
    normal: [
      '| 1 2 3 4 5 6 7 8 9 0 \' \u00BF {bksp}',
      '{tab} q w e r t y u i o p \u00B4 +',
      'a s d f g h j k l \u00F1 { } {enter}',
      '{shift} < z x c v b n m , . - {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\u00B0 ! " # $ % & / ( ) = ? \u00A1 {bksp}',
      '{tab} Q W E R T Y U I O P \u00A8 *',
      'A S D F G H J K L \u00D1 [ ] {enter}',
      '{shift} > Z X C V B N M ; : _ {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '\u00AC {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} \\ {e} {bksp}',
      '{tab} @ {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} ~',
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} ^ ` {enter}',
      '{shift} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  }
}

const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
