/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  simple: {
    normal: [
      '{//} 1 2 3 4 5 6 7 8 9 0 - {//} {bksp}',
      '{tab} \u0A4C \u0A48 \u0A3E \u0A40 \u0A42 \u0A2C \u0A39 \u0A17 \u0A26 \u0A1C \u0A21 \u0A3C {//}',
      '{lock} \u0A4B \u0A47 \u0A4D \u0A3F \u0A41 \u0A2A \u0A30 \u0A15 \u0A24 \u0A1A \u0A1F {enter}',
      '{shift} {//} \u0A70 \u0A2E \u0A28 \u0A35 \u0A32 \u0A38 , . \u0A2F {shift}',
      '@ {space}'
    ],
    shift: [
      '\u0A4D\u0A39 \u0A4D\u0A35 \u0A4D\u0A2F \u0A4D\u0A30 \u0A71 {//} {//} {//} {//} ( ) {//} {//} {bksp}',
      '{tab} \u0A14 \u0A10 \u0A06 \u0A08 \u0A0A \u0A2D \u0A19 \u0A18 \u0A27 \u0A1D \u0A22 \u0A1E {//}',
      '{lock} \u0A13 \u0A0F \u0A05 \u0A07 \u0A09 \u0A2B \u0A5C \u0A16 \u0A25 \u0A1B \u0A20 {enter}',
      '{shift} {//} \u0A02 \u0A23  \u0A72 \u0A33 \u0A36 {//} \u0964 {//} {shift}',
      '@ {space}'
    ]
  },
  Punjabi: {
    normal: [
      '{e} 1 2 3 4 5 6 7 8 9 0 - {e} {bksp}',
      '{tab} \u0a4c \u0a48 \u0a3e \u0a40 \u0a42 \u0a2c \u0a39 \u0a17 \u0a26 \u0a1c \u0a21 \u0a3c',
      '\u0a4b \u0a47 \u0a4d \u0a3f \u0a41 \u0a2a \u0a30 \u0a15 \u0a24 \u0a1a \u0a1f {enter}',
      '{shift} \u0a70 \u0a2e \u0a28 \u0a35 \u0a32 \u0a38 , . \u0a2f {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\u0a4d\u0a39 \u0a4d\u0a35 \u0a4d\u0a2f \u0a4d\u0a30 \u0a71 {e} {e} {e} {e} ( ) {e} {e} {bksp}',
      '{tab} \u0a14 \u0a10 \u0a06 \u0a08 \u0a0a \u0a2d \u0a19 \u0a18 \u0a27 \u0a1d \u0a22 \u0a1e',
      '\u0a13 \u0a0f \u0a05 \u0a07 \u0a09 \u0a2b \u0a5c \u0a16 \u0a25 \u0a1b \u0a20 {enter}',
      '{shift} \u0a02 \u0a23 {e} \u0a72 \u0a33 \u0a36 {e} \u0964 / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{e} \u0a67 \u0a68 \u0a69 \u0a6a \u0a6b \u0a6c \u0a6d \u0a6e \u0a6f \u0a66 {e} {e} {bksp}',
      '{tab} {e} {e} {e} {e} {e} {e} {e} \u0a5a {e} \u0a5b \u0a5c {e}',
      '{e} {e} {e} {e} {e} \u0a5e {e} \u0a59 {e} {e} {e} {enter}',
      '{shift} {e} {e} {e} \u0a73 {e} {e} {e} \u0965 {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    'alt-shift': [
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {bksp}',
      '{tab} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e}',
      '{e} {e} {e} \u0a72 \u0a73 {e} {e} {e} {e} {e} {e} {enter}',
      '{shift} \u0a74 {e} {e} {e} {e} {e} {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  }
}

const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
