/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  simple: {
    normal: [
      'ё 1 2 3 4 5 6 7 8 9 0 ц э {bksp}',
      '{tab} й i у к е н г ш щ з х ѳ \\',
      '{lock} ф ы в ъ а п р о л д ж ѵ {enter}',
      '{shift} / я ѣ ч с м и т ь б ю . {shift}',
      '@ {space}'
    ],
    shift: [
      'Ё ! " № ; % : ? * ( ) Ц Э {bksp}',
      '{tab} Й I У К Е Н Г Ш Щ З Х Ѳ /',
      '{lock} Ф Ы В Ъ А П Р О Л Д Ж Ѵ {enter}',
      '{shift} | Я Ѣ Ч С М И Т Ь Б Ю , {shift}',
      '@ {space}'
    ]
  }
}

const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
