/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  Tatar: {
    normal: [
      '\u04BB 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} \u0439 \u04E9 \u0443 \u043A \u0435 \u043D \u0433 \u0448 \u04D9 \u0437 \u0445 \u04AF',
      '\u0444 \u044B \u0432 \u0430 \u043F \u0440 \u043E \u043B \u0434 \u04A3 \u044D \\ {enter}',
      '{shift} \u0491 \u044F \u0447 \u0441 \u043C \u0438 \u0442 \u0497 \u0431 \u044E . {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\u04BA ! " \u2116 ; % : ? * ( ) _ + {bksp}',
      '{tab} \u0419 \u04E8 \u0423 \u041A \u0415 \u041D \u0413 \u0428 \u04D8 \u0417 \u0425 \u04AE',
      '\u0424 \u042B \u0412 \u0410 \u041F \u0420 \u041E \u041B \u0414 \u04A2 \u042D / {enter}',
      '{shift} \u0490 \u042F \u0427 \u0421 \u041C \u0418 \u0422 \u0496 \u0411 \u042E , {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '\u0451 {e} @ # $ {e} {e} [ ] { } {e} {e} {bksp}',
      '{tab} {e} \u0446 {e} {e} {e} {e} {e} {e} \u0449 {e} {e} \u044A',
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} \u0436 \' {e} {enter}',
      '{shift} {e} {e} {e} {e} {e} {e} {e} \u044C < > {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    'alt-shift': [
      '\u0401 {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {bksp}',
      '{tab} {e} \u0426 {e} {e} {e} {e} {e} {e} \u0429 {e} {e} \u042A',
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} \u0416 {e} {e} {enter}',
      '{shift} {e} {e} {e} {e} {e} {e} {e} \u042C {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  }
}
const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
