/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  simple: {
    normal: [
      'ೊ 1 2 3 4 5 6 7 8 9 0 - ೃ {bksp}',
      '{tab} ೌ ೈ ಾ ೀ ೂ ಬ ಹ ಗ ದ ಜ ಡ',
      'ೋ ೇ ್ ಿ ು ಪ ರ ಕ ತ ಚ ಟ {enter}',
      '{shift} ೆ ಂ ಮ ನ ವ ಲ ಸ , . / {shift}',
      '@ {space}'
    ],
    shift: [
      'ಒ ್ರ ರ್ ಜ್ಞ ತ್ರ ಕ್ಷ ಶ್ರ ( ) ಃ ಋ {bksp}',
      '{tab} ಔ ಐ ಆ ಈ ಊ ಭ ಙ ಘ ಧ ಝ ಢ ಞ',
      'ಓ ಏ ಅ ಇ ಉ ಫ ಱ ಖ ಥ ಛ ಠ {enter}',
      '{shift} ಎ ಣ ಳ ಶ ಷ | / {shift}',
      '@ {space}'
    ]
  },
  Kannada: {
    normal: [
      'ೊ 1 2 3 4 5 6 7 8 9 0 - ೃ {bksp}',
      '{tab} ೌ ೈ ಾ ೀ ೂ ಬ ಹ ಗ ದ ಜ ಡ {e}',
      'ೋ ೇ ್ ಿ ು ಪ ರ ಕ ತ ಚ ಟ {enter}',
      '{shift} ೆ ಂ ಮ ನ ವ ಲ ಸ , . ಯ {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      'ಒ {e} {e} ್ರ ರ್ ಜ್ಞ ತ್ರ ಕ್ಷ ಶ್ರ ( ) ಃ ಋ {bksp}',
      '{tab} ಔ ಐ ಆ ಈ ಊ ಭ ಙ ಘ ಧ ಝ ಢ ಞ',
      'ಓ ಏ ಅ ಇ ಉ ಫ ಱ ಖ ಥ ಛ ಠ {enter}',
      '{shift} ಎ {e} ಣ {e} {e} ಳ ಶ ಷ | / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{e} ೧ ೨ ೩ ೪ ೫ ೬ ೭ ೮ ೯ ೦ {e} ೄ {bksp}',
      '{tab} {e} ೖ {e} {e} {e} {e} {e} {e} {e} {e} {e} {e}',
      '{e} ೕ {e} {e} {e} {e} {e} {e} {e} {e} {e} {enter}',
      '{shift} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    'alt-shift': [
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} ೠ {bksp}',
      '{tab} {e} {e} {e} ೡ {e} {e} {e} {e} {e} {e} {e} {e}',
      '{e} {e} {e} ಌ {e} ೞ {e} {e} {e} {e} {e} {enter}',
      '{shift} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  }
}

const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
