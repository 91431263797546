/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  'Canadian French': {
    normal: [
      '# 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} q w e r t y u i o p ^ \u00b8 <',
      'a s d f g h j k l ; ` {enter}',
      '{shift} \u00ab z x c v b n m , . / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '| ! " / $ % ? & * ( ) _ + {bksp}',
      '{tab} Q W E R T Y U I O P ^ \u00a8 >',
      'A S D F G H J K L : ` {enter}',
      '{shift} \u00bb Z X C V B N M \' . / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '\\ \u00b1 @ \u00a3 \u00a2 \u00a4 \u00ac \u00a6 \u00b2 \u00b3 \u00bc \u00bd \u00be {bksp}',
      '{tab} {e} {e} {e} {e} {e} {e} {e} {e} \u00a7 \u00b6 [ ] }',
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} ~ { {enter}',
      '{shift} \u00b0 {e} {e} {e} {e} {e} {e} \u00b5 \u00af \u00ad {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  },
  'Canadian Multilingual Standard': {
    normal: [
      '/ 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} q w e r t y u i o p ^ \u00E7 {enter}',
      'a s d f g h j k l ; \u00E8 \u00E0 ',
      '{shift} \u00F9 z x c v b n m , . \u00E9 {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\\ ! @ # $ % ? & * ( ) _ + {bksp}',
      '{tab} Q W E R T Y U I O P \u00A8 \u00C7 {enter}',
      'A S D F G H J K L : \u00C8 \u00C0 ',
      '{shift} \u00D9 Z X C V B N M \' " \u00C9 {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '| {e} {e} {e} {e} {e} {e} { } [ ] {e} \u00AC {bksp}',
      '{tab} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} ` ~ {enter}',
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} \u00B0 {e} {e} ',
      '{shift} {e} \u00AB \u00BB {e} {e} {e} {e} {e} < > {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  }
}
const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
