/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  simple: {
    normal: [
      '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} q w e r t y u i o p [ ] \\',
      '{lock} a s d f g h j k l ; \' {enter}',
      '{shift} z x c v b n m , . / {shift}',
      '@ {space}'
    ],
    shift: [
      '~ ! @ # $ % ^ & * ( ) _ + {bksp}',
      '{tab} Q W E R T Y U I O P { } |',
      '{lock} A S D F G H J K L : " {enter}',
      '{shift} Z X C V B N M < > ? {shift}',
      '@ {space}'
    ]
  },
  International: {
    normal: [
      '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} q w e r t y u i o p [ ]',
      'a s d f g h j k l ; \' \u005C {enter}',
      '{shift} z x c v b n m , . / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '~ ! @ # $ % ^ & * ( ) _ + {bksp}',
      '{tab} Q W E R T Y U I O P { }',
      'A S D F G H J K L : \u0022 | {enter}',
      '{shift} Z X C V B N M < > ? {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{e} \u00A1 \u00B2 \u00B3 \u00A4 \u20AC \u00BC \u00BD \u00BE \u2018 \u2019 \u00A5 \u00D7 {bksp}',
      '{tab} \u00E4 \u00E5 \u00E9 \u00AE \u00FE \u00FC \u00FA \u00ED \u00F3 \u00F6 \u00AB \u00BB',
      '\u00E1 \u00DF \u00F0 {e} {e} {e} {e} {e} \u00F8 \u00B6 \u00B4 \u00AC {enter}',
      '{shift} \u00E6 {e} \u00A9 {e} {e} \u00F1 \u00B5 \u00E7 {e} \u00BF {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    'alt-shift': [
      '{e} \u00B9 {e} {e} \u00A3 {e} {e} {e} {e} {e} {e} {e} \u00F7 {bksp}',
      '{tab} \u00C4 \u00C5 \u00C9 {e} \u00DE \u00DC \u00DA \u00CD \u00D3 \u00D6 {e} {e}',
      '\u00C1 \u00A7 \u00D0 {e} {e} {e} {e} {e} \u00D8 \u00B0 \u00A8 \u00A6 {enter}',
      '{shift} \u00C6 {e} \u00A2 {e} {e} \u00D1 {e} \u00C7 {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  },
  Dvorak: {
    normal: [
      '` 1 2 3 4 5 6 7 8 9 0 [ ] {bksp}',
      '{tab} \' , . p y f g c r l / = \u005C',
      'a o e u i d h t n s - {enter}',
      '{shift} ; q j k x b m w v z {shift}',
      '{accept} {space} {cancel}'
    ],
    shift: [
      '~ ! @ # $ % ^ & * ( ) { } {bksp}',
      '{tab} \u0022 < > P Y F G C R L ? + |',
      'A O E U I D H T N S _ {enter}',
      '{shift} : Q J K X B M W V Z {shift}',
      '{accept} {space} {cancel}'
    ]
  },
  'Dvorak for left hand': {
    normal: [
      '` [ ] / p f m l j 4 3 2 1 {bksp}',
      '{tab} ; q b y u r s o . 6 5 = \u005C',
      '- k c d t h e a z 8 7 {enter}',
      '{shift} \' x g v w n i , 0 9 {shift}',
      '{accept} {space} {cancel}'
    ],
    shift: [
      '~ { } ? P F M L J $ # @ ! {bksp}',
      '{tab} : Q B Y U R S O > ^ % + |',
      '_ K C D T H E A Z * & {enter}',
      '{shift} \u0022 X G V W N I < ) ( {shift}',
      '{accept} {space} {cancel}'
    ]
  },
  'Dvorak for right hand': {
    normal: [
      '` 1 2 3 4 j l m f p / [ ] {bksp}',
      '{tab} 5 6 q . o r s u y b ; = \u005C',
      '7 8 z a e h t d c k - {enter}',
      '{shift} 9 0 x , i n w v g \' {shift}',
      '{accept} {space} {cancel}'
    ],
    shift: [
      '~ ! @ # $ J L M F P ? { } {bksp}',
      '{tab} % ^ Q > O R S U Y B : + |',
      '& * Z A E H T D C K _ {enter}',
      '{shift} ( ) X < I N W V G \u0022 {shift}',
      '{accept} {space} {cancel}'
    ]
  }
}

const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
