/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  simple: {
    normal: [
      '` 1 2 3 4 5 6 7 8 9 0 \u00B0 + {bksp}',
      '{tab} a z e r t y u i o p ^ $',
      '{lock} q s d f g h j k l m \u00F9 * {enter}',
      '{shift} < w x c v b n , ; : ! {shift}',
      '@ {space}'
    ],
    shift: [
      '\u00B2 & \u00E9 " \' ( - \u00E8 _ \u00E7 \u00E0 ) = {bksp}',
      '{tab} A Z E R T Y U I O P \u00A8 \u00A3',
      '{lock} Q S D F G H J K L M % \u00B5 {enter}',
      '{shift} > W X C V B N ? . / \u00A7 {shift}',
      '@ {space}'
    ]
  },
  'french-azerty-1': {
    normal: [
      '\u00b2 & \u00e9 " \' ( - \u00e8 _ \u00e7 \u00e0 ) = {bksp}',
      '{tab} a z e r t y u i o p ^ $',
      'q s d f g h j k l m  \u00f9 * {enter}',
      '{shift} < w x c v b n , ; : ! {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '{sp:1} 1 2 3 4 5 6 7 8 9 0 \u00b0 + {bksp}',
      '{tab} A Z E R T Y U I O P \u00a8 \u00a3',
      'Q S D F G H J K L M % \u00b5 {enter}',
      '{shift} > W X C V B N ? . / \u00a7 {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '\u00b2 & ~ # { [ | ` \\ ^ @ ] } {bksp}',
      '{tab} a z \u20ac r t y u i o p ^ \u00a4',
      'q s d f g h j k l m  \u00f9 * {enter}',
      '{shift} < w x c v b n , ; : ! {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    'alt-shift': [
      '{sp:1} 1 ~ # { [ | ` \\ ^ @ ] } {bksp}',
      '{tab} A Z \u20ac R T Y U I O P \u00a8 \u00a4',
      'Q S D F G H J K L M % \u00b5 {enter}',
      '{shift} > W X C V B N ? . / \u00a7 {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  },
  'french-azerty-2': {
    normal: [
      '\u00b2 & \u00e9 " \' ( - \u00e8 _ \u00e7 \u00e0 ) = {b}',
      '{t} a z e r t y u i o p ^ $',
      'q s d f g h j k l m  \u00f9 * {e}',
      '{s} < w x c v b n , ; : ! {s}',
      '{a} {alt} {space} {alt} {c}'
    ],
    shift: [
      '{sp:1} 1 2 3 4 5 6 7 8 9 0 \u00b0 + {b}',
      '{t} A Z E R T Y U I O P \u00a8 \u00a3',
      'Q S D F G H J K L M % \u00b5 {e}',
      '{s} > W X C V B N ? . / \u00a7 {s}',
      '{a} {alt} {space} {alt} {c}'
    ],
    alt: [
      '\u00b2 & ~ # { [ | ` \\ ^ @ ] } {b}',
      '{t} a z \u20ac r t y u i o p ^ \u00a4',
      'q s d f g h j k l m  \u00f9 * {e}',
      '{s} < w x c v b n , ; : ! {s}',
      '{a} {alt} {space} {alt} {c}'
    ],
    'alt-shift': [
      '{sp:1} 1 ~ # { [ | ` \\ ^ @ ] } {b}',
      '{t} A Z \u20ac R T Y U I O P \u00a8 \u00a4',
      'Q S D F G H J K L M % \u00b5 {e}',
      '{s} > W X C V B N ? . / \u00a7 {s}',
      '{a} {alt} {space} {alt} {c}'
    ]
  },

  'french-bepo-V1.0rc2': {
    normal: [
      '$ " \u00AB \u00BB ( ) @ + - / * = % {b}',
      '{t} b \u00e9 p o \u00e8 \u02c6 v d l j z w',
      'a u i e , c t s r n m \u00E7 {enter}',
      '{s} \u00ea \u00E0 y x . k \' q g h f {s}',
      '{a} {alt} {space} {alt} {c}'
    ],
    shift: [
      '# 1 2 3 4 5 6 7 8 9 0 \u00b0 ` {b}',
      '{t} B \u00c9 P O \u00c8 ! V D L J Z W',
      'A U I E ; C T S R N M \u00c7 {enter}',
      '{s} \u00ca \u00c0 Y X : K ? Q G H F {s}',
      '{a} {alt} {space} {alt} {c}'
    ],
    alt: [
      '\u2013 \u2014 < > [ ] ^ \u00b1 \u2212 \u00f7 \u00d7 \u2260 \u2030 {b}',
      '{t} | \u00b4 & \u0153 ` \u00a1 \u02c7 \u00f0 / \u0133 \u0259 \u02d8',
      '\u00e6 \u00f9 \u00a8 \u20ac \u2019 \u00a9 \u00fe \u00df \u00ae ~ \u00af \u00b8 {enter}',
      '{s} / \\ { } \u2026 ~ \u00bf \u00b0 \u00b5 \u2020 \u02db {s}',
      '{a} {alt} {space} {alt} {c}'
    ],
    'alt-shift': [
      '\u00b6 \u201e \u201c \u201d \u2264 \u2265 {e} \u00ac \u00bc \u00bd \u00be \u2032 \u2033 {b}',
      '{t} \u00A6 \u02DD \u00A7 \u0152 ` \u00A1 {e} \u00D0 {e} \u0132 \u018F \u02d8',
      '\u00c6 \u00d9 \u02d9 \u00a4 \u031b \u017f \u00de \u1e9e \u2122 {e} \u00ba , {enter}',
      '{s} {e} {e} \u2018 \u2019 \u00B7 {e} \u0309 \u0323 {e} \u2021 \u00AA {s}',
      '{a} {alt} {space} {alt} {c}'
    ]
  },
  French: {
    normal: [
      '\u00B2 & \u00E9 " \' ( - \u00E8 _ \u00E7 \u00E0 ) = {bksp}',
      '{tab} a z e r t y u i o p ^ $',
      'q s d f g h j k l m \u00F9 * {enter}',
      '{shift} < w x c v b n , ; : ! {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '{e} 1 2 3 4 5 6 7 8 9 0 \u00B0 + {bksp}',
      '{tab} A Z E R T Y U I O P \u00A8 \u00A3',
      'Q S D F G H J K L M % \u00B5 {enter}',
      '{shift} > W X C V B N ? . / \u00A7 {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{e} {e} ~ # { [ | ` \\ ^ @ ] } {bksp}',
      '{tab} {e} {e} \u20AC {e} {e} {e} {e} {e} {e} {e} {e} \u00A4',
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {enter}',
      '{shift} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  },
  'Swiss French': {
    normal: [
      '\u00A7 1 2 3 4 5 6 7 8 9 0 \' ^ {b}',
      '{t} q w e r t z u i o p \u00E8 \u00A8 $',
      'a s d f g h j k l \u00E9 \u00E0 {enter}',
      '{s} < y x c v b n m , . - {s}',
      '{space} {alt} {accept}'
    ],
    shift: [
      '\u00B0 + " * \u00E7 % & / ( ) = ? ` {b}',
      '{t} Q W E R T Z U I O P \u00FC ! \u00A3',
      'A S D F G H J K L \u00F6 \u00E4 {enter}',
      '{s} > Y X C V B N M ; : _ {s}',
      '{space} {alt} {accept}'
    ],
    alt: [
      '{e} \u00A6 @ # \u00B0 \u00A7 \u00AC | \u00A2 {e} {e} \u00B4 ~ {b}',
      '{t} {e} {e} \u20AC {e} {e} {e} {e} {e} {e} {e} [ ] }',
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} { {enter}',
      '{s} \\ {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {s}',
      '{space} {alt} {accept}'
    ]
  }
}
const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
