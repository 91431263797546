/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  'Tibetan (PRC)': {
    normal: [
      'ཨ ༡ ༢ ༣ ༤ ༥ ༦ ༧ ༨ ༩ ༠ ཧ ཝ {bksp}',
      '{tab} ཅ ཆ ེ ར ཏ ཡ ུ ི ོ ཕ ཙ ཚ ཛ',
      'འ ས ད བ ང མ ་ ག ལ ཞ ། {enter}',
      '{shift} ཟ ཤ ཀ ཁ པ ན m ཐ ཇ / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '༁ ༪ ༫ ༬ ༭ ༮ ༯ ༰ ༱ ༲ ༳ ༼ ༽ {bksp}',
      '{tab} ༕ ༖ ༗ ྼ ཊ ྻ ༘ ༙ ༚ ༛ ༜ ༝ ༞',
      'ཱ ༟ ཌ ༾ ༿ ࿏ ༂ ༃ ༆ ༇ ༸ {enter}',
      '{shift} ༴ ཥ ཀྵ ྇ ྆ ཎ M ཋ ༺ / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {bksp}',
      '{tab} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e}',
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {enter}',
      '{shift} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    'alt-shift': [
      'ༀ ྲྀ ཷ ླྀ ཹ ཱི ༉ ༊ ༏ ༐ ༒ ༌ ༓ {bksp}',
      '{tab} ྉ ྈ ྾ ཪ ྚ ྿ ཱུ ཱི ࿀ ࿁ ࿂ ࿃ ཛྷ',
      '࿄ ࿅ ྜ བྷ ࿆ ࿇ ࿈ གྷ ࿉ ࿊ ࿋ {enter}',
      '{shift} ࿌ ྵ ྐྵ ༶ ྂ ྞ དྷ ྛ ྋ {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  }
}
const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
