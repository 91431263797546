/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  'albanian-qwertz': {
    normal: [
      '\\ 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} q w e r t z u i o p \u00e7 \'',
      'a s d f g h j k l \u00eb [ ] {enter}',
      '{shift} < y x c v b n m , . / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '| ! " # $ % ^ & * ( ) _ + {bksp}',
      '{tab} Q W E R T Z U I O P \u00c7 @',
      'A S D F G H J K L \u00cb { } {enter}',
      '{shift} > Y X C V B N M ; : ? {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '\\ ~ \u02c7 ^ \u02d8 \u00b0 \u02db ` \u02d9 \u00b4 \u02dd \u00a8 \u00b8 {bksp}',
      '{tab} q w \u20ac r t z u i o p \u00f7 \u00d7',
      '\u00e4 \u0111:Shortcut_(/d) \u0110:Shortcut_(/D) f g h j \u0142:Shortcut_(/l) \u0141:Shortcut_(/L) $ \u00df \u00a4 {enter}',
      '{shift} < y x c @ { } \u00a7 < > / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  },

  Albanian: {
    normal: [
      '\\ 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} q w e r t z u i o p \u00e7 @',
      'a s d f g h j k l \u00eb [ ] {enter}',
      '{shift} < y x c v b n m , . / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '| ! " # $ % ^ & * ( ) _ + {bksp}',
      '{tab} Q W E R T Z U I O P \u00c7 \'',
      'A S D F G H J K L \u00cb { } {enter}',
      '{shift} > Y X C V B N M ; : ? {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{e} ~ \u02c7 ^ \u02d8 \u00b0 \u02db ` \u02d9 \u00b4 \u02dd \u00a8 \u00b8 {bksp}',
      '{tab} \\ | {e} {e} {e} {e} {e} {e} {e} {e} \u00f7 \u00d7',
      '{e} \u0111 \u0110 [ ] {e} {e} \u0142 \u0141 $ \u00df \u00a4 {enter}',
      '{shift} {e} {e} {e} {e} @ { } \u00a7 < > {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  },
  'albanian-qwerty': {
    normal: [
      '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} q w e r t y u i o p \u00eb \'',
      'a s d f g h j k l ; \u00e7 # {enter}',
      '{shift} \\ z x c v b n m , . / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'],
    shift: [
      '\u00ac ! " \u00a7 $ % ^ & * ( ) _ + {bksp}',
      '{tab} Q W E R T Y U I O P \u00cb @',
      'A S D F G H J K L : \u00c7 ~ {enter}',
      '{shift} \u00a6 Z X C V B N M < > ? {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '| 1 2 3 4 5 6 { } [ ] - = {bksp}',
      '{tab} q w \u20ac r t y u i o p \u00f7 \u00d7',
      'a s d f g h j k l ; \u00e7 # {enter}',
      '{shift} \\ z x c v b n m , . / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    'alt-shift': [
      '| ! " \u00a7 $ % ^ & * ( ) _ + {bksp}',
      '{tab} Q W \u20AC R T Y U I O P \u00cb @',
      'A S D F G H J K L : Ç ~ {enter}',
      '{shift} \u00a6 Z X C V B N M < > ? {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  }
}
const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
