/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  simple: {
    normal: [
      '` \u0661 \u0662 \u0663 \u0664 \u0665 \u0666 \u0667 \u0668 \u0669 \u0660 - = {bksp}',
      '{tab} \u0642 \u0648 \u0639 \u0631 \u062A \u0680 \u0621 \u064A \u06C1 \u067E [ ]',
      '{lock} \u0627 \u0633 \u062F \u0641 \u06AF \u06BE \u062C \u06A9 \u0644 \u061B \u060C {enter}',
      '{shift} \u0632 \u0634 \u0686 \u0637 \u0628 \u0646 \u0645 \u0687 , . / {shift}',
      '@ {space}'
    ],
    shift: [
      '~ ! @ # $ \u066A ^ & * ( ) _ + {bksp}',
      '{tab} \uFE70 \u068C \u06AA \u0699 \u067D \uFE7A \uFEFB \uFE8B \u06A6 | { }',
      '{lock} \u067B \u0635 \u068A \u060D \u063A \u062D \u0636 \u062E \u06D4 \u0703 \u05f4 {enter}',
      '{shift} \u0630 \u067F \u062B \u0638 \u067A \u066b \u0640 < > \u061F {shift}',
      '@ {space}'
    ]
  }
}

const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
