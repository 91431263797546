/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  Irish: {
    normal: [
      '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} q w e r t y u i o p [ ] #',
      'a s d f g h j k l ; \' {enter}',
      '{shift} \\ z x c v b n m , . / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\u00ac ! " \u00a3 $ % ^ & * ( ) _ + {bksp}',
      '{tab} Q W E R T Y U I O P { } ~',
      'A S D F G H J K L : @ {enter}',
      '{shift} | Z X C V B N M < > / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '\u00a6 {e} {e} {e} \u20ac {e} {e} {e} {e} {e} {e} {e} {e} {bksp}',
      '{tab} {e} {e} \u00e9 {e} {e} {e} \u00fa \u00ed \u00f3 {e} {e} {e} {e}',
      '\u00e1 {e} {e} {e} {e} {e} {e} {e} {e} {e} \u00b4 {enter}',
      '{shift} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    'alt-shift': [
      '\u00a6 {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {bksp}',
      '{tab} {e} {e} \u00c9 {e} {e} {e} \u00da \u00cd \u00d3 {e} {e} {e} {e}',
      '\u00c1 {e} {e} {e} {e} {e} {e} {e} {e} {e} ` {enter}',
      '{shift} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  }
}
const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
