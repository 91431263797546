/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  simple: {
    normal: [
      '\u00A7 1 2 3 4 5 6 7 8 9 0 + \u00B4 {bksp}',
      '{tab} q w e r t y u i o p \u00E5 ¨',
      '{lock} a s d f g h j k l \u00F6 \u00E4 \' {enter}',
      '{shift} < z x c v b n m , . - {shift}',
      '@ {space}'
    ],
    shift: [
      '\u00B0 ! " # $ % & / ( ) = ? ` {bksp}',
      '{tab} Q W E R T Y U I O P \u00C5 ^',
      '{lock} A S D F G H J K L \u00D6 \u00C4 * {enter}',
      '{shift} > Z X C V B N M ; : _ {shift}',
      '@ {space}'
    ]
  },
  'swedish-qwerty': {
    normal: [
      '\u00a7 1 2 3 4 5 6 7 8 9 0 + \u0301 {bksp}',
      '{tab} q w e r t y u i o p \u00e5 \u0308',
      'a s d f g h j k l \u00f6 \u00e4 \' {enter}',
      '{shift} < z x c v b n m , . - {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\u00bd ! " # \u00a4 % & / ( ) = ? \u0300 {bksp}',
      '{tab} Q W E R T Y U I O P \u00c5 \u0302',
      'A S D F G H J K L \u00d6 \u00c4 * {enter}',
      '{shift} > Z X C V B N M ; : _ {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '\u00a7 1 @ \u00a3 $ 5 6 { [ ] } \\ \u0301 {bksp}',
      '{tab} q w € r t y u i o p \u00e5 \u0303',
      'a s d f g h j k l \u00f6 \u00e4 \' {enter}',
      '{shift} | z x c v b n \u00b5 , . - {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  },
  'swedish-dvorak': {
    normal: [
      '\u00a7 1 2 3 4 5 6 7 8 9 0 + \u0301 {bksp}',
      '{tab} \u00f6 \u00e5 \u00e4 p y f g c r l q \u00a8',
      'a o e u i d h t n s - < {enter}',
      '{shift} \' , . j k x b m w v z {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\u00bd ! " # \u00a4 % & / ( ) = ? ` {bksp}',
      '{tab} \u00d6 \u00c5 \u00c4 P Y F G C R L Q ^',
      'A O E U I D H T N S _ > {enter}',
      '{shift} * ; : J K X B M W V Z {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '\u00a7 1 @ \u00a3 $ 5 6 { [ ] } \\ \u00b4 {bksp}',
      '{tab} \u00f6 \u00e5 \u00e4 p y f g c r l q ~',
      'a o e u i d h t n s - | {enter}',
      '{shift} \' , . j k x b m w v z {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  },
  Swedish: {
    normal: [
      '\u00A7 1 2 3 4 5 6 7 8 9 0 + \u00B4 {bksp}',
      '{tab} q w e r t y u i o p \u00E5 \u00A8',
      'a s d f g h j k l \u00F6 \u00E4 \' {enter}',
      '{shift} < z x c v b n m , . - {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\u00BD ! " # \u00A4 % & / ( ) = ? ` {bksp}',
      '{tab} Q W E R T Y U I O P \u00C5 ^',
      'A S D F G H J K L \u00D6 \u00C4 * {enter}',
      '{shift} > Z X C V B N M ; : _ {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{e} {e} @ \u00A3 $ \u20AC {e} { [ ] } \\ {e} {bksp}',
      '{tab} {e} {e} \u20AC {e} {e} {e} {e} {e} {e} {e} {e} ~',
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {enter}',
      '{shift} | {e} {e} {e} {e} {e} {e} \u00B5 {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  },
  'Swedish with Sami': {
    normal: [
      '\u00a7 1 2 3 4 5 6 7 8 9 0 + \u00B4 {bksp}',
      '{tab} q w e r t y u i o p \u00E5 \u00A8',
      'a s d f g h j k l \u00F6 \u00E4 \' {enter}',
      '{shift} < z x c v b n m , . - {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\u00BD ! " # \u00A4 % & / ( ) = ? ` {bksp}',
      '{tab} Q W E R T Y U I O P \u00C5 ^',
      'A S D F G H J K L \u00D6 \u00C4 * {enter}',
      '{shift} > Z X C V B N M ; : _ {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{e} {e} @ \u00A3 $ \u20AC {e} { [ ] } \\ {e} {bksp}',
      '{tab} \u00E2 {e} \u20AC {e} \u0167 {e} {e} \u00ef \u00f5 {e} {e} ~',
      '\u00E1 \u0161 \u0111 \u01e5 \u01E7 \u021F {e} \u01e9 {e} \u00F8 \u00E6 {e} {enter}',
      '{shift} | \u017E {e} \u010d \u01EF \u0292 \u014B \u00B5 {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    'alt-shift': [
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {bksp}',
      '{tab} \u00C2 {e} {e} {e} \u0166 {e} {e} \u00CF \u00D5 {e} {e} {e}',
      '\u00C1 \u0160 \u0110 \u01E4 \u01E6 \u021e {e} \u01E8 {e} \u00D8 \u00C6 {e} {enter}',
      '{shift} {e} \u017D {e} \u010C \u01EE \u01B7 \u014A {e} {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  }
}
const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
