/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  'Chinese Bopomofo IME': {
    normal: [
      '€ ㄅ ㄉ ˇ ˋ ㄓ ˊ ˙ ㄚ ㄞ ㄢ ㄦ = {bksp}',
      '{tab} ㄆ ㄊ ㄍ ㄐ ㄔ ㄗ ㄧ ㄛ ㄟ ㄣ [ ] \\',
      'ㄇ ㄋ ㄎ ㄑ ㄕ ㄘ ㄨ ㄜ ㄠ ㄤ \' {enter}',
      'ㄈ ㄌ ㄏ ㄒ ㄖ ㄙ ㄩ ㄝ ㄡ ㄥ',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} q w e r t y u i o p [ ] \\',
      'a s d f g h j k l ; \' {enter}',
      '{shift} z x c v b n m , . / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    'alt-shift': [
      '~ ! @ # $ % ^ & * ( ) _ + {bksp}',
      '{tab} Q W E R T Y U I O P { } |',
      'A S D F G H J K L : " {enter}',
      '{shift} Z X C V B N M < > ? {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  },
  'Chinese ChaJei IME': {
    normal: [
      '€ 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} 手 田 水 口 廿 卜 山 戈 人 心 [ ] \\',
      '日 尸 木 火 土 竹 十 大 中 ; \' {enter}',
      'Ｚ 難 金 女 月 弓 一 , . /',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} q w e r t y u i o p [ ] \\',
      'a s d f g h j k l ; \' {enter}',
      '{shift} z x c v b n m , . / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    'alt-shift': [
      '~ ! @ # $ % ^ & * ( ) _ + {bksp}',
      '{tab} Q W E R T Y U I O P { } |',
      'A S D F G H J K L : " {enter}',
      '{shift} Z X C V B N M < > ? {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  },
  'zh-Latn Pinyin': {
    normal: [
      '` 1 2 3 4 5 6 7 8 9 0 - = {b}',
      '{t} q w e r t y u i o p [ ] \\',
      'a s d f g h j k l ; \' {enter}',
      '{s} z x c v b n m , . / {s}',
      '{space} {alt} {accept}'
    ],
    shift: [
      '~ ! @ # $ % ^ & * ( ) _ + {b}',
      '{t} Q W E R T Y U I O P { } |',
      'A S D F G H J K L : " {enter}',
      '{s} Z X C V B N M < > ? {s}',
      '{space} {alt} {accept}'
    ],
    alt: [
      '亓 ｢ ｣ 〝 〞 〟 〈 〉 〯 《 》 『 』 {b}',
      '{t} ā á ǎ à ē é ě è ī í ǐ ì 〠',
      'ō ó ǒ ò ü ū ú ǔ ù {e} {e} {enter}',
      '{s} ǖ ǘ ǚ ǜ {e} {e} {e} 、 。 {e} {s}',
      '{space} {alt} {accept}'
    ],
    'alt-shift': [
      '〜 {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {b}',
      '{t} Ā Á Ǎ À Ē É Ě È Ī Í Ǐ Ì {e}',
      'Ō Ó Ǒ Ò Ü Ū Ú Ǔ Ù {e} {e} {enter}',
      '{s} Ǖ Ǘ Ǚ Ǜ {e} {e} {e} {e} {e} {e} {s}',
      '{space} {alt} {accept}'
    ]
  }
}
const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
