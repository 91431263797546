/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  Oriya: {
    normal: [
      '1 2 3 4 5 6 7 8 9 0 \u002d \u0b43 {bksp}',
      '{tab} \u0b4c \u0b48 \u0b3e \u0b40 \u0b42 \u0b2c \u0b39 \u0b17 \u0b26 \u0b1c \u0b21 \u0b3c',
      '\u0b4b \u0b47 \u0b4d \u0b3f \u0b41 \u0b2a \u0b30 \u0b15 \u0b24 \u0b1a \u0b1f {enter}',
      '{shift} \u0b5f \u0b02 \u0b2e \u0b28 \u0b2c \u0b32 \u0b38 , \u0964 \u0b2f {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '{e} {e} \u0b4d\u0b30 \u0b30\u0b4d \u0b1c\u0b4d\u0b1e \u0b24\u0b4d\u0b30 \u0b15\u0b4d\u0b37 \u0b36\u0b4d\u0b30 ( ) \u0b03 \u0b0b {bksp}',
      '{tab} \u0b14 \u0b10 \u0b06 \u0b08 \u0b0a \u0b2d \u0b19 \u0b18 \u0b27 \u0b1d \u0b22 \u0b1e',
      '\u0b13 \u0b0f \u0b05 \u0b07 \u0b09 \u0b2b {e} \u0b16 \u0b25 \u0b1b \u0b20 {enter}',
      '{shift} \u0b71 \u0b01 \u0b23 {e} {e} \u0b33 \u0b36 \u0b37 {e} \u0b3c {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '\u0b67 \u0b68 \u0b69 \u0b6a \u0b6b \u0b6c \u0b6d \u0b6e \u0b6f \u0b66 {e} \u0b44 {bksp}',
      '{tab} {e} {e} {e} \u0b63 {e} {e} {e} {e} {e} {e} \u0b5c {e}',
      '{e} {e} {e} \u0b62 {e} {e} {e} {e} {e} {e} {e} {enter}',
      '{shift} {e} {e} \u0b70 {e} {e} \u0b35 {e} {e} \u0965 {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    'alt-shift': [
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} \u0b60 {bksp}',
      '{tab} {e} {e} {e} \u0b61 {e} {e} {e} {e} {e} {e} \u0b5d {e}',
      '{e} {e} {e} \u0b0c {e} {e} {e} {e} {e} {e} {e} {enter}',
      '{shift} {e} {e} {e} {e} {e} {e} {e} {e} \u0b3d \u0b5f {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  }
}
const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
