/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  Sinhala: {
    normal: [
      '\u0dca{ZWJ}\u0dbb 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} \u0dd4 \u0d85 \u0dd0 \u0dbb \u0d92 \u0dc4 \u0db8 \u0dc3 \u0daf \u0da0 \u0da4 ; {ZWJ}',
      '\u0dca \u0dd2 \u0dcf \u0dd9 \u0da7 \u0dba \u0dc0 \u0db1 \u0d9a \u0dad . {enter}',
      '{shift} {ZWJ} \' \u0d82 \u0da2 \u0da9 \u0d89 \u0db6 \u0db4 \u0dbd \u0d9c / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\u0dbb\u0dca{ZWJ} ! @ $ $ % ^ & * ( ) _ + {bksp}',
      '{tab} \u0dd6 \u0d8b \u0dd1 \u0d8d \u0d94 \u0dc1 \u0db9 \u0dc2 \u0db0 \u0da1 \u0da5 : {e}',
      '\u0ddf \u0dd3 \u0dd8 \u0dc6 \u0da8 \u0dca{ZWJ}\u0dba \u0dc5\u0dd4 \u0dab \u0d9b \u0dae , {enter}',
      '{shift} {e} " \u0d83 \u0da3 \u0daa \u0d8a \u0db7 \u0db5 \u0dc5 \u0d9d / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {bksp}',
      '{tab} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e}',
      '\u0df3 {e} {e} {e} {e} {e} {e} {e} {e} {e} \u0df4 {enter}',
      '{shift} {e} {e} \u0d9e \u0da6 \u0dac {e} {e} {e} \u0d8f \u0d9f {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  },
  'Sinhala - Wij 9': {
    normal: [
      '\u0dca{ZWJ}\u0dbb 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} \u0dd4 \u0d85 \u0dd0 \u0dbb \u0d91 \u0dc4 \u0db8 \u0dc3 \u0daf \u0da0 \u0da4 ; \u0dca{ZWJ}',
      '\u0dca \u0dd2 \u0dcf \u0dd9 \u0da7 \u0dba \u0dc0 \u0db1 \u0d9a \u0dad . {enter}',
      '{shift} \' \u0d82 \u0da2 \u0da9 \u0d89 \u0db6 \u0db4 \u0dbd \u0d9c / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\u0dbb\u0dca{ZWJ} ! @ # $ % ^ & * ( ) _ + {bksp}',
      '{tab} \u0dd6 \u0d8b \u0dd1 \u0d8d \u0d94 \u0dc1 \u0db9 \u0dc2 \u0db0 \u0da1 \u0da5 : {e}',
      '\u0ddf \u0dd3 \u0dd8 \u0dc6 \u0da8 \u0dca{ZWJ}\u0dba \u0dc5\u0dd4 \u0dab \u0d9b \u0dae , {enter}',
      '{shift} " \u0d83 \u0da3 \u0daa \u0d8a \u0db7 \u0db5 \u0dc5 \u0d9d / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '` \u00a1 \u00b2 \u00b3 \u00a4 \u20ac \u00bc \u00bd \u00be \u2018 \u2019 \u00a5 \u00d7 {bksp}',
      '{tab} \u0dde \u0ddc \u0ddb \u0dda {e} {e} {e} {e} \u0db3 {e} [ ] \\',
      '\u0df3 \u0ddd {e} {e} {e} {e} {ZWJ} {e} {e} {e} \u0df4 {enter}',
      '{shift} {e} \u0d9e \u0da6 \u0dac {e} {ZWNJ} {e} \u0d8f \u0d9f {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    'alt-shift': [
      '~ \u00b9 {e} {e} \u00a3 {e} {e} {e} {e} {e} {e} {e} \u00f7 {bksp}',
      '{tab} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} { } |',
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {enter}',
      '{shift} {e} {e} {e} {e} {e} {e} {e} < > {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  }
}
const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
