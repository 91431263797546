/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  'Serbian (Latin)': {
    normal: [
      '\u201a 1 2 3 4 5 6 7 8 9 0 \' + {bksp}',
      '{tab} q w e r t z u i o p \u0161 \u0111 \u017e',
      'a s d f g h j k l \u010d \u0107 {enter}',
      '{shift} < y x c v b n m , . / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '~ ! " # $ % & / ( ) = ? * {bksp}',
      '{tab} Q W E R T Z U I O P \u0160 \u0110 \u017d',
      'A S D F G H J K L \u010c \u0106 {enter}',
      '{shift} > Y X C V B N M ; : / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{e} ~ \u02c7 ^ \u02d8 \u00b0 \u02db ` \u02d9 \u00b4 \u02dd \u00a8 \u00b8 {bksp}',
      '{tab} \\ | \u20ac {e} {e} {e} {e} {e} {e} {e} \u00f7 \u00d7 \u00a4',
      '{e} {e} {e} [ ] {e} {e} \u0142 \u0141 {e} \u00df {enter}',
      '{shift} {e} {e} {e} {e} @ { } \u00a7 < > {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  },
  'Serbian (Cyrillic)': {
    normal: [
      '` 1 2 3 4 5 6 7 8 9 0 \' + {bksp}',
      '{tab} \u0459 \u045a \u0435 \u0440 \u0442 \u0437 \u0443 \u0438 \u043e \u043f \u0448 \u0452 \u0436',
      '\u0430 \u0441 \u0434 \u0444 \u0433 \u0445 \u0458 \u043a \u043b \u0447 \u045b {enter}',
      '{shift} < \u0455 \u045f \u0446 \u0432 \u0431 \u043d \u043c , . / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '~ ! " # $ % & / ( ) = ? * {bksp}',
      '{tab} \u0409 \u040a \u0415 \u0420 \u0422 \u0417 \u0423 \u0418 \u041e \u041f \u0428 \u0402 \u0416',
      '\u0410 \u0421 \u0414 \u0424 \u0413 \u0425 \u0408 \u041a \u041b \u0427 \u040b {enter}',
      '{shift} > \u0405 \u040f \u0426 \u0412 \u0411 \u041d \u041c ; : / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {bksp}',
      '{tab} {e} {e} \u20ac {e} {e} {e} {e} {e} {e} {e} {e} {e} {e}',
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {enter}',
      '{shift} {e} {e} {e} {e} {e} {e} {e} {e} < > {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  },
  'sr-Cyrl': {
    normal: [
      '` 1 2 3 4 5 6 7 8 9 0 \' + {bksp}',
      '{tab} \u0459 \u045a \u0435 \u0440 \u0442 \u0437 \u0443 \u0438 \u043e \u043f \u0448 \u0452 \u0436',
      ' \u0430 \u0441 \u0434 \u0444 \u0433 \u0445 \u0458 \u043a \u043b \u0447 \u045b {enter}',
      '{shift} < \u0455 \u045f \u0446 \u0432 \u0431 \u043d \u043c , . - {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '~ ! " # $ % & / ( ) = ? * {bksp}',
      '{tab} \u0409 \u040a \u0415 \u0420 \u0422 \u0417 \u0423 \u0418 \u041e \u041f \u0428 \u0402 \u0416',
      ' \u0410 \u0421 \u0414 \u0424 \u0413 \u0425 \u0408 \u041a \u041b \u0427 \u040b {enter}',
      '{shift} > \u0405 \u040f \u0426 \u0412 \u0411 \u041d \u041c ; : _ {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '` 1 2 3 4 5 6 7 8 9 0 \' + {bksp}',
      '{tab} \u0459 \u045a \u0435 \u0440 \u0442 \u0437 \u0443 \u0438 \u043e \u043f \u0448 \u0452 \u0436',
      ' \u0430 \u0441 \u0434 \u0444 \u0433 \u0445 \u0458 \u043a \u043b \u0447 \u045b {enter}',
      '{shift} < \u0455 \u045f \u0446 \u0432 \u0431 \u043d \u043c , . - {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  }
}

const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
