/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  simple: {
    normal: [
      '\u0B66 \u0B67 \u0B68 \u0B69 \u0B6A \u0B6B \u0B6C \u0B6D \u0B6E \u0B6F \u0B0B \u0B43 {bksp}',
      '{tab} \u0B4C \u0B48 \u0B3E \u0B40 \u0B42  \u0B2C \u0B39 \u0B17 \u0B26 \u0B1C \u0B21 \u0B3C',
      '{lock} \u0B4B \u0B47 \u0B4D \u0B3F \u0B41 \u0B2A \u0B30 \u0B15 \u0B24 \u0B1A \u0B1F {enter}',
      '{shift} \u0B5F \u0B02 \u0B2E \u0B28 \u0B71 \u0B32 \u0B38 \u002C \u002E \u0B5F {shift}',
      '@ {space}'
    ],
    shift: [
      '\u0B4D\u0B30 \u0B30\u0B4D \u0B1C\u0B4D\u0B1E \u0B24\u0B4D\u0B30 \u0B15\u0B4D\u0B37 \u0B36\u0B4D\u0B30 \u0028 \u0029  \u0B03 \u0B60 \u0B44 {bksp}',
      '{tab} \u0B14 \u0B10 \u0B06 \u0B08 \u0B0A \u0B2D \u0B19 \u0B18 \u0B27 \u0B1D \u0B22 \u0B5C \u0B1E',
      '{lock} \u0B13 \u0B0F \u0B05 \u0B62 \u0B07 \u0B09 \u0B2B \u0B16 \u0B25 \u0B1B \u0B20 {enter}',
      '{shift} \u0B71 \u0B01 \u0B70 \u0B23 \u0B35 \u0B33 \u0B36 \u0B37 \u0964 \u0B2F {shift}',
      '@ {space}'
    ]
  }
}

const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
