/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  Lithuanian: {
    normal: [
      '` \u0105 \u010d \u0119 \u0117 \u012f \u0161 \u0173 \u016b 9 0 - \u017e {bksp}',
      '{tab} q w e r t y u i o p [ ] \\',
      'a s d f g h j k l ; \' {enter}',
      '{shift} \\ z x c v b n m , . / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '~ \u0104 \u010c \u0118 \u0116 \u012e \u0160 \u0172 \u016a ( ) _ \u017d {bksp}',
      '{tab} Q W E R T Y U I O P { } |',
      'A S D F G H J K L : " {enter}',
      '{shift} | Z X C V B N M < > / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{e} 1 2 3 4 5 6 7 8 9 0 {e} = {bksp}',
      '{tab} {e} {e} \u20ac {e} {e} {e} {e} {e} {e} {e} {e} {e} {e}',
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {enter}',
      '{shift} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    'alt-shift': [
      '{e} ! @ # $ % ^ & * {e} {e} {e} + {bksp}',
      '{tab} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e}',
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {enter}',
      '{shift} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  },
  'Lithuanian IBM': {
    normal: [
      '` ! " / ; : , . ? ( ) _ + {bksp}',
      '{tab} \u0105 \u017e e r t y u i o p \u012f \u201c |',
      'a s d f g h j k l \u0173 \u0117 {enter}',
      '{shift} \\ z \u016b c v b n m \u010d \u0161 / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '~ 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} \u0104 \u017d E R T Y U I O P \u012e \u201d \\',
      'A S D F G H J K L \u0172 \u0116 {enter}',
      '{shift} | Z \u016a C V B N M \u010c \u0160 / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{e} {e} {e} {e} {e} {e} {e} { [ ] } {e} {e} {bksp}',
      '{tab} {e} {e} \u20ac {e} {e} {e} {e} {e} {e} {e} {e} {e} {e}',
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {enter}',
      '{shift} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  },
  'Lithuanian Standard': {
    normal: [
      '` ! - / ; : , . = ( ) ? x {bksp}',
      '{tab} \u0105 \u017e e r t y u i o p \u012f w q',
      'a s d \u0161 g h j k l \u0173 \u0117 {enter}',
      '{shift} < z \u016b c v b n m \u010d f / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '~ 1 2 3 4 5 6 7 8 9 0 + X {bksp}',
      '{tab} \u0104 \u017d E R T Y U I O P \u012e W Q',
      'A S D \u0160 G H J K L \u0172 \u0116 {enter}',
      '{shift} > Z \u016a C V B N M \u010c F / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '\u00b4 @ _ # $ \u00a7 ^ & * [ ] \' % {bksp}',
      '{tab} {e} {e} \u20ac {e} {e} {e} {e} {e} {e} {e} { } |',
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} " {enter}',
      '{shift} \u2013 {e} {e} {e} {e} {e} {e} {e} \u201e \u201c {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  }
}
const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
