/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  dingbats: {
    normal: [
      '❤ ➊ ➋ ➌ ➍ ➎ ➏ ➐ ➑ ➒ ➓ ➕ ✂ {b}',
      '{t} ✔ ✘ ✚ ✝ ✢ ✦ ✩ ✬ ✶ ✻ ❄ ✳ ✷',
      '➙ ➵ ➸ ➔ ➝ ➩ ➤ ➳ ➭ ➨ ➟ {enter}',
      '{s} ✌ ❏ ✿ ❇ ❗ ❓ ✏ ✒ ✉ ❛ ❜ {s}',
      '{space} {alt} {accept}'
    ],
    shift: [
      '❥ ➀ ➁ ➂ ➃ ➄ ➅ ➆ ➇ ➈ ➉ ➖ ✄ {b}',
      '{t} ✅ ✕ ✙ ✞ ✣ ✧ ✪ ✫ ✱ ✼ ❅ ✴ ✸',
      '➚ ➶ ➹ ➜ ➞ ➪ ➣ ➼ ➮ ➦ ➠ {enter}',
      '{s} ✋ ❐ ❀ ❈ ❕ ❔ ✐ ✑ ✆ ❝ ❞ {s}',
      '{space} {alt} {accept}'
    ],
    alt: [
      '❦ ❶ ❷ ❸ ❹ ❺ ❻ ❼ ❽ ❾ ❿ ❌ ✁ {b}',
      '{t} ✓ ✗ ✛ ✟ ✤ ✨ ✭ ✮ ✲ ✽ ❆ ✵ ✹',
      '➘ ➴ ➷ ➺ ➡ ➫ ➢ ➽ ➯ ➥ ➾ {enter}',
      '{s} ✊ ❑ ❁ ❊ ❢ ➰ ✎ ❍ ✈ ❡ ❟ {s}',
      '{space} {alt} {accept}'
    ],
    'alt-shift': [
      '❧ ❨ ❩ ❪ ❫ ❬ ❭ ❮ ❯ ❰ ❱ ➗ ✃ {b}',
      '{t} {e} ✖ ✜ ✠ ✥ ❖ ✰ ✯ ❉ ✾ ❃ ✡ ✺',
      '❘ ❙ ❚ ➻ ❲ ➬ ➛ ❳ ➱ ➧ ➲ {enter}',
      '{s} ✍ ❒ ❂ ❋ ❣ ➿ ❴ ❎ ✇ ❵ ❠ {s}',
      '{space} {alt} {accept}'
    ]
  },
  '??-symbols': {
    normal: [
      '★ ♈ ♉ ♊ ♋ ♌ ♍ ♎ ♏ ♐ ♑ ♒ ♓ {b}',
      '☿ ♀ ♁ ♂ ♃ ♄ ♅ ♆ ♇ ♩ ♪ ♫ ♬ ⛹ ♿',
      '{t} ☞ ☛ ☂ ☕ ☺ ☗ ♠ ♤ ⛂ ☤ ☐ ☨ ☪ ⛺',
      '☯ ☼ ⛄ ⚠ ⛤ ☊ ⚜ ⚳ ⚷ ⚹ ⚽ {enter}',
      '{s} ☀ ⚑ ⛋ ⛏ ⛓ ⛚ ⛟ ⛣ ⛫ ⛯ ⛴ {s}',
      '{space} {alt} {accept}'
    ],
    shift: [
      '☆ ♳ ♴ ♵ ♶ ♷ ♸ ♹ ♺ ♻ ♼ ♽ ♲ {b}',
      '⚀ ⚁ ⚂ ⚃ ⚄ ⚅ ☠ ☢ ☣ ♭ ♮ ♯ ☇ ☈ ☮',
      '{t} ☜ ☚ ☔ ♨ ☹ ☖ ♣ ♧ ⛀ ☥ ☑ ☦ ☫ ⛻',
      '♰ ⚙ ☃ ⚡ ⛥ ☋ ⚛ ⚴ ⚿ ⚺ ⚾ {enter}',
      '{s} ☁ ⚐ ⛌ ⛐ ⛕ ⛛ ⛠ ⛨ ⛬ ⛰ ⛵ {s}',
      '{space} {alt} {accept}'
    ],
    alt: [
      '☎ ♙ ♘ ♗ ♖ ♕ ♔ ♟ ♞ ♝ ♜ ♛ ♚ {b}',
      '⚊ ⚋ ⚌ ⚍ ⚎ ⚏ ⛿ ⚒ ⚓ ⚔ ⚕ ⚖ ⚗ ⚘ ☸',
      '{t} ☝ ☘ ⛱ ⛾ ☻ ⛊ ♥ ♡ ⛃ ⚚ ☒ ☧ ☬ ⛼',
      '♱ ☽ ⛇ ☡ ⛦ ☌ ⚝ ⚵ ⚸ ⚻ ⚟ {enter}',
      '{s} ⛅ ⚫ ⛍ ⛑ ⛖ ⛝ ⛡ ⛩ ⛭ ⛲ ⛶ {s}',
      '{space} {alt} {accept}'
    ],
    'alt-shift': [
      '☏ ☰ ☱ ☲ ☳ ☴ ☵ ☶ ☷ ⚆ ⚇ ⚈ ⚉ {b}',
      '⚢ ⚣ ⚤ ⚥ ⚦ ⚧ ⚨ ⚩ ⚲ ⚬ ⚭ ⚮ ⚯ ⚰ ☉',
      '{t} ☟ ☙ ⛙ ⛘ ⛜ ⛉ ♦ ♢ ⛁ ⚱ ☓ ☩ ☭ ⛽',
      '♾ ☾ ⛆ ⛔ ⛧ ☍ ☄ ⚶ ⛸ ⚼ ⚞ {enter}',
      '{s} ⛈ ⚪ ⛎ ⛒ ⛗ ⛞ ⛢ ⛪ ⛮ ⛳ ⛷ {s}',
      '{space} {alt} {accept}'
    ]
  }
}

const layout = layouts.international
export {
  layout, layouts
}
