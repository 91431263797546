/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  simple: {
    normal: [
      '\u07F7 \u07C1 \u07C2 \u07C3 \u07C4 \u07C5 \u07C6 \u07C7 \u07C8 \u07C9 \u07C0 \u07FA = {bksp}',
      '{tab} \u07D2 \u07E5 \u07CB \u07D9 \u07D5 \u07E6 \u07CE \u07CC \u07D0 \u07D4 [ ] \\',
      '{lock} \u07CA \u07DB \u07D8 \u07DD \u07DC \u07E4 \u07D6 \u07DE \u07DF \u07F8 \u07F4 {enter}',
      '{shift} \u07E2 \u07CF \u07D7 \u07CD \u07D3 \u07E3 \u07E1 \u07F2 \u07F3 \u2E2E {shift}',
      '@ {space}'
    ],
    shift: [
      '~ ! @ # $ % ^ & * ( ) _ + {bksp}',
      '{tab} \u07D1 \u07E0  \u07DA  \u07E7     { } |',
      '{lock} \u07FD \u07F2 \u07F3 \u07F8 \u07F9 \u07FA \u07FE \u07FF \u07F6 \u07F9 \u07F5 {enter}',
      '{shift} \u07EB \u07EC \u07ED \u07EE \u07EF \u07F0 \u07F1 < > \u07FD {shift}',
      '@ {space}'
    ]
  }
}

const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
