/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  Bashkir: {
    normal: [
      'ә ! ө ҡ ғ ҫ : ҙ һ ? № - ү {bksp}',
      '{tab} й ц у к е н г ш щ з х ъ ң',
      'ф ы в а п р о л д ж э {enter}',
      '{shift} я ч с м и т ь б ю / {shift}',
      '{accept} {space} {cancel}'
    ],
    shift: [
      'Ә " Ө Ҡ Ғ Ҫ ; Ҙ Һ ( ) % Ү {bksp}',
      '{tab} Й Ц У К Е Н Г Ш Щ З Х Ъ Ң',
      'Ф Ы В А П Р О Л Д Ж Э {enter}',
      '{shift} Я Ч С М И Т Ь Б Ю / {shift}',
      '{accept} {space} {cancel}'
    ]
  },
  'Bosnian (Cyrillic)': {
    normal: [
      '` 1 2 3 4 5 6 7 8 9 0 \' + {bksp}',
      '{tab} љ њ е р т з у и о п ш ђ ж',
      'а с д ф г х ј к л ч ћ {enter}',
      '{shift} < ѕ џ ц в б н м , . / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '~ ! " # $ % & / ( ) = ? * {bksp}',
      '{tab} Љ Њ Е Р Т З У И О П Ш Ђ Ж',
      'А С Д Ф Г Х Ј К Л Ч Ћ {enter}',
      '{shift} > Ѕ Џ Ц В Б Н М ; : / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{e} {e} {e} ^ {e} ° {e} {e} {e} {e} {e} {e} {e} {bksp}',
      '{tab} \\ | € {e} {e} {e} {e} {e} {e} {e} {e} {e} {e}',
      '{e} {e} {e} [ ] {e} {e} {e} {e} {e} {e} {enter}',
      '{shift} {e} {e} {e} {e} @ { } § < > {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  }
}
const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
