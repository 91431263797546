/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  Icelandic: {
    normal: [
      '\u00B0 1 2 3 4 5 6 7 8 9 0 \u00F6 - {bksp}',
      '{tab} q w e r t y u i o p \u00F0 \'',
      'a s d f g h j k l \u00E6 \u00B4 + {enter}',
      '{shift} < z x c v b n m , . \u00FE {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\u00A8 ! " # $ % & / ( ) = \u00D6 _ {bksp}',
      '{tab} Q W E R T Y U I O P \u00D0 ?',
      'A S D F G H J K L \u00C6 \' * {enter}',
      '{shift} > Z X C V B N M ; : \u00DE {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '\u00B0 {e} {e} {e} {e} \u20AC {e} { [ ] } \\ {e} {bksp}',
      '{tab} @ {e} \u20AC {e} {e} {e} {e} {e} {e} {e} {e} ~',
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} ^ ` {enter}',
      '{shift} | {e} {e} {e} {e} {e} {e} \u00B5 {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  }
}
const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
