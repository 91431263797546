/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  Korean: {
    normal: [
      '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} \u1107 \u110c \u1103 \u1100 \u1109 \u116d \u1167 \u1163 \u1162 \u1166 [ ] \u20a9',
      '\u1106 \u1102 \u110b \u1105 \u1112 \u1169 \u1165 \u1161 \u1175 ; \' {enter}',
      '{shift} \u110f \u1110 \u110e \u1111 \u1172 \u116e \u1173 , . / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '~ ! @ # $ % ^ & * ( ) _ + {bksp}',
      '{tab} \u1108 \u110d \u1104 \u1101 \u110a \u116d \u1167 \u1163 \u1164 \u1168 { } |',
      '\u1106 \u1102 \u110b \u1105 \u1112 \u1169 \u1165 \u1161 \u1175 : " {enter}',
      '{shift} \u110f \u1110 \u110e \u1111 \u1172 \u116e \u1173 < > ? {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} q w e r t y u i o p [ ] \u20a9',
      'a s d f g h j k l ; \' {enter}',
      '{shift} z x c v b n m , . / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    'alt-shift': [
      '~ ! @ # $ % ^ & * ( ) _ + {bksp}',
      '{tab} Q W E R T Y U I O P { } |',
      'A S D F G H J K L : " {enter}',
      '{shift} Z X C V B N M < > ? {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  }
}
const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
