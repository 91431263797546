/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  Wolof: {
    normal: [
      '\u00e3 & \u00e9 \u0022 \' ( - \u00f1 _ \u014b \u00e0 ) = {bksp}',
      '{tab} a z e r t y u i o p ^ \u00f3 *',
      'q s d f g h j k l m \u00f9 {enter}',
      '{shift} < w x c v b n , ; : / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\u00c3 1 2 3 4 5 6 7 8 9 0 \u00c9 + {bksp}',
      '{tab} A Z E R T Y U I O P \u00a8 \u00d3 \u00d1',
      'Q S D F G H J K L M \u00c0 {enter}',
      '{shift} > W X C V B N ? . / / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{e} {e} ~ # { [ | ` \u005c ^ @ ] } {bksp}',
      '{tab} {e} {e} \u20ac {e} {e} {e} {e} {e} {e} {e} {e} \u00a4 {e}',
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {enter}',
      '{shift} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  }
}
const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
