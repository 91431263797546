/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  bs: {
    normal: [
      '\u00B8 1 2 3 4 5 6 7 8 9 0 \' + {b}',
      '{t} q w e r t z u i o p \u0161 \u0111 \u017E',
      'a s d f g h j k l \u010D \u0107 {enter}',
      '{s} < y x c v b n m , . - {s}',
      '{space} {alt} {accept}'
    ],
    shift: [
      '\u00A8 ! " # $ % & / ( ) = ? * {b}',
      '{t} Q W E R T Z U I O P \u0160 \u0110 \u017D',
      'A S D F G H J K L \u010C \u0106 {enter}',
      '{s} > Y X C V B N M ; : _ {s}',
      '{space} {alt} {accept}'
    ],
    alt: [
      '{e} ~ \u02C7 ^ \u02D8 \u00B0 \u02DB ` \u02D9 \u00B4 \u02DD \u00A8 \u00B8 {b}',
      '{t} \\ | \u20AC {e} {e} {e} {e} {e} {e} {e} \u00F7 \u00D7 \u00A4',
      '{e} {e} {e} [ ] {e} {e} \u0142 \u0141 {e} \u00DF {enter}',
      '{s} {e} {e} {e} {e} @ { } \u00A7 < > \u00A9 {s}',
      '{space} {alt} {accept}'
    ],
    'alt-shift': [
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {b}',
      '{t} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e}',
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {enter}',
      '{s} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {s}',
      '{space} {alt} {accept}'
    ]
  }
}

const layout = layouts.international
export {
  layout, layouts
}
