/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  Gujarati: {
    normal: [
      '{e} 1 2 3 4 5 6 7 8 9 0 - \u0ac3 {bksp}',
      '{tab} \u0acc \u0ac8 \u0abe \u0ac0 \u0ac2 \u0aac \u0ab9 \u0a97 \u0aa6 \u0a9c \u0aa1 \u0abc \u0ac9',
      '\u0acb \u0ac7 \u0acd \u0abf \u0ac1 \u0aaa \u0ab0 \u0a95 \u0aa4 \u0a9a \u0a9f {enter}',
      '{shift} \u0a82 \u0aae \u0aa8 \u0ab5 \u0ab2 \u0ab8 , . \u0aaf {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '{e} \u0a8d \u0ac5 \u0acd\u0ab0 \u0ab0\u0acd \u0a9c\u0acd\u0a9e \u0aa4\u0acd\u0ab0 \u0a95\u0acd\u0ab7 \u0ab6\u0acd\u0ab0 ( ) \u0a83 \u0a8b {bksp}',
      '{tab} \u0a94 \u0a90 \u0a86 \u0a88 \u0a8a \u0aad \u0a99 \u0a98 \u0aa7 \u0a9d \u0aa2 \u0a9e \u0a91',
      '\u0a93 \u0a8f \u0a85 \u0a87 \u0a89 \u0aab {e} \u0a96 \u0aa5 \u0a9b \u0aa0 {enter}',
      '{shift} \u0a81 \u0aa3 {e} {e} \u0ab3 \u0ab6 \u0ab7 \u0964 / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{e} \u0ae7 \u0ae8 \u0ae9 \u0aea \u0aeb \u0aec \u0aed \u0aee \u0aef \u0ae6 {e} \u0ac4 {bksp}',
      '{tab} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e}',
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {enter}',
      '{shift} {e} {e} {e} {e} {e} {e} {e} \u0965 {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    'alt-shift': [
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} \u0ae0 {bksp}',
      '{tab} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e}',
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {enter}',
      '{shift} \u0ad0 {e} {e} {e} {e} {e} {e} \u0abd {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  }
}
const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
