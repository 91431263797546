/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  simple: {
    normal: [
      '_ ๅ / - ภ ถ ุ ึ ค ฅ จ ข ช {bksp}',
      '{tab} ๆ ไ ำ พ ะ ั ี ร น ย บ ล ฃ',
      '{lock} ฟ ห ก ด เ ้ ่ า ส ว ง {enter}',
      '{shift} ผ ป แ อ ิ ื ท ม ใ ฝ {shift}',
      '@ {space}'
    ],
    shift: [
      '% + ๑ ๒ ๓ ๔ ู ฿ ๕ ๖ ๗ ๘ ๙ {bksp}',
      '{tab} ๐ " ฎ ฑ ธ ํ ๊ ณ ฯ ญ ฐ , ฅ',
      '{lock} ฤ ฆ ฏ โ ฌ ็ ๋ ษ ศ ซ . {enter}',
      '{shift} ( ) ฉ ฮ ฺ ์ ? ฒ ฬ ฦ {shift}',
      '@ {space}'
    ]
  },
  'thai-qwerty': {
    normal: [
      '_ ๅ / - ภ ถ ุ ึ ค ฅ จ ข ช {bksp}',
      '{tab} ๆ ไ ำ พ ะ ั ี ร น ย บ ล ฃ ',
      'ฟ ห ก ด เ ้ ่ า ส ว ง {enter}',
      '{shift} ผ ป แ อ ิ ื ท ม ใ ฝ {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '% + ๑ ๒ ๓ ๔ ู ฿ ๕ ๖ ๗ ๘ ๙ {bksp}',
      '{tab} ๐ " ฎ ฑ ธ ํ ๊ ณ ฯ ญ ฐ , ฅ',
      'ฤ ฆ ฏ โ ฌ ็ ๋ ษ ศ ซ . {enter}',
      '{shift} ( ) ฉ ฮ ฺ ์ ? ฒ ฬ ฦ {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} q w e r t y u i o p [ ] \\',
      'a s d f g h j k l ; \' {enter}',
      '{shift} z x c v b n m , . / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    'alt-shift': [
      '~ | @ # $ % ¬ & * ( ) _ + {bksp}',
      '{tab} Q W E R T Y U I O P ! ¦ ¢',
      'A S D F G H J K L : "  {enter}',
      '{shift} Z X C V B N M { } ? {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  },
  'Thai Kedmanee': {
    normal: [
      '_ ๅ / - ภ ถ ุ ึ ค ต จ ข ช {bksp}',
      '{tab} ๆ ไ ำ พ ะ ั ี ร น ย บ ล ฃ',
      'ฟ ห ก ด เ ้ ่ า ส ว ง {enter}',
      '{shift} ผ ป แ อ ิ ื ท ม ใ ฝ {shift}',
      '{accept} {space} {cancel}'
    ],
    shift: [
      '% + ๑ ๒ ๓ ๔ ู ฿ ๕ ๖ ๗ ๘ ๙ {bksp}',
      '{tab} ๐ " ฎ ฑ ธ ํ ๊ ณ ฯ ญ ฐ , ฅ',
      'ฤ ฆ ฏ โ ฌ ็ ๋ ษ ศ ซ . {enter}',
      '{shift} ( ) ฉ ฮ ฺ ์ ? ฒ ฬ ฦ {shift}',
      '{accept} {space} {cancel}'
    ]
  },
  'Thai Kedmanee (non-ShiftLock)': {
    normal: [
      '_ ๅ / - ภ ถ ุ ึ ค ต จ ข ช {bksp}',
      '{tab} ๆ ไ ำ พ ะ ั ี ร น ย บ ล ฃ',
      'ฟ ห ก ด เ ้ ่ า ส ว ง {enter}',
      '{shift} ผ ป แ อ ิ ื ท ม ใ ฝ {shift}',
      '{accept} {space} {cancel}'
    ],
    shift: [
      '% + ๑ ๒ ๓ ๔ ู ฿ ๕ ๖ ๗ ๘ ๙ {bksp}',
      '{tab} ๐ " ฎ ฑ ธ ํ ๊ ณ ฯ ญ ฐ , ฅ',
      'ฤ ฆ ฏ โ ฌ ็ ๋ ษ ศ ซ . {enter}',
      '{shift} ( ) ฉ ฮ ฺ ์ ? ฒ ฬ ฦ {shift}',
      '{accept} {space} {cancel}'
    ]
  },
  'Thai Pattachote': {
    normal: [
      '_ = ๒ ๓ ๔ ๕ ู ๗ ๘ ๙ ๐ ๑ ๖ {bksp}',
      '{tab} ็ ต ย อ ร ่ ด ม ว แ ใ ฌ ',
      '้ ท ง ก ั ี า น เ ไ ข {enter}',
      '{shift} บ ป ล ห ิ ค ส ะ จ พ {shift}',
      '{accept} {space} {cancel}'
    ],
    shift: [
      '฿ + " / , ? ุ _ . ( ) - % {bksp}',
      '{tab} ๊ ฤ ๆ ญ ษ ึ ฝ ซ ถ ฒ ฯ ฦ ํ',
      '๋ ธ ำ ณ ์ ื ผ ช โ ฆ ฑ {enter}',
      '{shift} ฎ ฏ ฐ ภ ั ศ ฮ ฟ ฉ ฬ {shift}',
      '{accept} {space} {cancel}'
    ]
  },
  'Thai Pattachote (non-ShiftLock)': {
    normal: [
      '_ = ๒ ๓ ๔ ๕ ู ๗ ๘ ๙ ๐ ๑ ๖ {bksp}',
      '{tab} ็ ต ย อ ร ่ ด ม ว แ ใ ฌ ',
      '้ ท ง ก ั ี า น เ ไ ข {enter}',
      '{shift} บ ป ล ห ิ ค ส ะ จ พ {shift}',
      '{accept} {space} {cancel}'
    ],
    shift: [
      '฿ + " / , ? ุ _ . ( ) - % {bksp}',
      '{tab} ๊ ฤ ๆ ญ ษ ึ ฝ ซ ถ ฒ ฯ ฦ ํ',
      '๋ ธ ำ ณ ์ ื ผ ช โ ฆ ฑ {enter}',
      '{shift} ฎ ฏ ฐ ภ ั ศ ฮ ฟ ฉ ฬ {shift}',
      '{accept} {space} {cancel}'
    ]
  }
}
const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
