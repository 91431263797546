/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  'hungarianansi-qwertz-1': {
    normal: [
      '0 1 2 3 4 5 6 7 8 9 \u00f6 \u00fc \u00f3 \u0171 {bksp}',
      '{tab} q w e r t z u i o p \u0151 \u00fa',
      'a s d f g h j k l \u00e9 \u00e1 {enter}',
      '{shift} \u00ed y x c v b n m , . - {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\u00a7 \u0027 " + ! % / = ( ) \u00d6 \u00dc \u00d3 \u0170 {bksp}',
      '{tab} Q W E R T Z U I O P \u0150 \u00da',
      'A S D F G H J K L \u00c9 \u00c1 {enter}',
      '{shift} \u00cd Y X C V B N M ; : _ {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{sp:1} \u007e \u02c7 \u005e \u02d8 \u00b0 \u02db \u0060 \u02d9 \u00b4 \u02dd \u00a8 \u00b8 \u00a4 {bksp}', // most non-used
      '{tab} \\ | \u00c4 {sp:1} {sp:1} {sp:1} \u20ac \u00cd {sp:1} {sp:1} \u00f7 \u00d7',
      '\u00e4 \u0111 \u0110 [ ] {sp:1} \u00ed \u0142 \u0141 $ \u00df {enter}',
      '{shift} < > # & @ { } < ; > * {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  },
  'hungarianansi-qwertz-2': {
    normal: [
      '0 1 2 3 4 5 6 7 8 9 \u00f6 \u00fc \u00f3 {bksp}',
      '{tab} q w e r t z u i o p \u0151 \u00fa',
      'a s d f g h j k l \u00e9 \u00e1 \u0171 {enter}',
      '{shift} \u00ed y x c v b n m , . - {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\u00a7 \u0027 " + ! % / = ( ) \u00d6 \u00dc \u00d3 {bksp}',
      '{tab} Q W E R T Z U I O P \u0150 \u00da',
      'A S D F G H J K L \u00c9 \u00c1 \u0170 {enter}',
      '{shift} \u00cd Y X C V B N M ; : _ {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{sp:1} \u007e \u02c7 \u005e \u02d8 \u00b0 \u02db \u0060 \u02d9 \u00b4 \u02dd \u00a8 \u00b8 {bksp}', // most non-used
      '{tab} \\ | \u00c4 {sp:1} {sp:1} {sp:1} \u20ac \u00cd {sp:1} {sp:1} \u00f7 \u00d7',
      '\u00e4 \u0111 \u0110 [ ] {sp:1} \u00ed \u0142 \u0141 $ \u00df \u00a4 {enter}',
      '{shift} < > # & @ { } < ; > * {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  }
}
const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
