/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  simple: {
    normal: [
      '^ 1 2 3 4 5 6 7 8 9 0 \u00DF \u00B4 {bksp}',
      '{tab} q w e r t z u i o p \u00FC +',
      '{lock} a s d f g h j k l \u00F6 \u00E4 # {enter}',
      '{shift} < y x c v b n m , . - {shift}',
      '@ {space}'
    ],
    shift: [
      '\u00B0 ! " \u00A7 $ % & / ( ) = ? ` {bksp}',
      '{tab} Q W E R T Z U I O P \u00DC *',
      '{lock} A S D F G H J K L \u00D6 \u00C4 \' {enter}',
      '{shift} > Y X C V B N M ; : _ {shift}',
      '@ {space}'
    ]
  },
  'german-qwertz-1': {
    normal: [
      '\u0302 1 2 3 4 5 6 7 8 9 0 \u00df \u0301 {bksp}',
      '{tab} q w e r t z u i o p \u00fc +',
      'a s d f g h j k l \u00f6 \u00e4 # {enter}',
      '{shift} < y x c v b n m , . - {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\u00b0 ! " \u00a7 $ % & / ( ) = ? \u0300 {bksp}',
      '{tab} Q W E R T Z U I O P \u00dc *',
      'A S D F G H J K L \u00d6 \u00c4 \' {enter}',
      '{shift} > Y X C V B N M ; : _ {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '\u0302 1 \u00b2 \u00b3 4 5 6 { [ ] } \\ \u0301 {bksp}',
      '{tab} @ w \u20ac r t z u i o p \u00fc \u0303',
      'a s d f g h j k l \u00f6 \u00e4 # {enter}',
      '{shift} \u007c y x c v b n \u00b5 , . - {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  },
  'german-qwertz-2': {
    normal: [
      '\u00a7 1 2 3 4 5 6 7 8 9 0 \' \u0302 {bksp}',
      '{tab} q w e r t z u i o p \u00fc \u0308',
      'a s d f g h j k l \u00f6 \u00e4 $ {enter}',
      '{shift} < y x c v b n m , . - {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\u00b0 + " * \u00e7 % & / ( ) = ? \u0300 {bksp}',
      '{tab} Q W E R T Z U I O P \u00e8 !',
      'A S D F G H J K L \u00e9 \u00e0 \u00a3 {enter}',
      '{shift} > Y X C V B N M ; : _ {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '\u00a7 \u007c @ # 4 5 \u00ac \u00a6 \u00a2 9 0 \u0301 \u0303 {bksp}',
      '{tab} q w \u20ac r t z u i o p [ ]',
      'a s d f g h j k l \u00f6 { } {enter}',
      '{shift} \\ y x c v b n m , . - {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  },
  German: {
    normal: [
      '^ 1 2 3 4 5 6 7 8 9 0 \u00DF \u00B4 {bksp}',
      '{tab} q w e r t z u i o p \u00FC +',
      'a s d f g h j k l \u00F6 \u00E4 # {enter}',
      '{shift} < y x c v b n m , . - {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\u00B0 ! " \u00A7 $ % & / ( ) = ? ` {bksp}',
      '{tab} Q W E R T Z U I O P \u00DC *',
      'A S D F G H J K L \u00D6 \u00C4 \' {enter}',
      '{shift} > Y X C V B N M ; : _ {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{e} {e} \u00B2 \u00B3 {e} {e} {e} { [ ] } \\ {e} {bksp}',
      '{tab} @ {e} \u20AC {e} {e} {e} {e} {e} {e} {e} {e} ~',
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {enter}',
      '{shift} | {e} {e} {e} {e} {e} {e} \u00B5 {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  },
  'German (IBM)': {
    normal: [
      '^ 1 2 3 4 5 6 7 8 9 0 \u00DF \u00B4 {bksp}',
      '{tab} q w e r t z u i o p \u00FC +',
      'a s d f g h j k l \u00F6 \u00E4 # {enter}',
      '{shift} < y x c v b n m , . - {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\u00B0 ! " \u00A7 $ % & / ( ) = ? ` {bksp}',
      '{tab} Q W E R T Z U I O P \u00DC *',
      'A S D F G H J K L \u00D6 \u00C4 \' {enter}',
      '{shift} > Y X C V B N M ; : _ {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{e} {e} \u00B2 \u00B3 {e} {e} {e} { [ ] } \\ {e} {bksp}',
      '{tab} @ {e} \u20AC {e} {e} {e} {e} {e} {e} {e} {e} ~',
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {enter}',
      '{shift} | {e} {e} {e} {e} {e} {e} \u00B5 {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  },
  'Swiss German': {
    normal: [
      '\u00A7 1 2 3 4 5 6 7 8 9 0 \' ^ {bksp}',
      '{tab} q w e r t z u i o p \u00FC \u00A8',
      'a s d f g h j k l \u00F6 \u00E4 $ {enter}',
      '{shift} < y x c v b n m , . - {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\u00B0 + " * \u00E7 % & / ( ) = ? ` {bksp}',
      '{tab} Q W E R T Z U I O P \u00E8 !',
      'A S D F G H J K L \u00E9 \u00E0 \u00A3 {enter}',
      '{shift} > Y X C V B N M ; : _ {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{e} \u00A6 @ # \u00B0 \u00A7 \u00AC | \u00A2 {e} {e} \u00B4 ~ {bksp}',
      '{tab} {e} {e} \u20AC {e} {e} {e} {e} {e} {e} {e} [ ]',
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} { } {enter}',
      '{shift} \\ {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  }
}
const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
