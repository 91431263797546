/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  Lao: {
    normal: [
      '" \u0ea2 \u0e9f \u0ec2 \u0e96 \u0eb8 \u0eb9 \u0e84 \u0e95 \u0e88 \u0e82 \u0e8a \u0ecd {bksp}',
      '{tab} \u0ebb \u0ec4 \u0eb3 \u0e9e \u0eb0 \u0eb4 \u0eb5 \u0eae \u0e99 \u0e8d \u0e9a \u0ea5 /',
      '\u0eb1 \u0eab \u0e81 \u0e94 \u0ec0 \u0ec9 \u0ec8 \u0eb2 \u0eaa \u0ea7 \u0e87 {enter}',
      '{shift} \u0e9c \u0e9b \u0ec1 \u0ead \u0eb6 \u0eb7 \u0e97 \u0ea1 \u0ec3 / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\' 1 2 3 4 \u0ecc \u0ebc 5 6 7 8 9 \u0ecd\u0ec8 {bksp}',
      '{tab} \u0ebb\u0ec9 0 * _ + \u0eb4\u0ec9 \u0eb5\u0ec9 \u0ea3 \u0edc \u0ebd - \u0eab\u0ebc \\',
      '\u0eb1\u0ec9 ; . , : \u0eca \u0ecb ! ? % = {enter}',
      '{shift} \u20ad ( \u0eaf x \u0eb6\u0ec9 \u0eb7\u0ec9 \u0ec6 \u0edd $ / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{e} \u0ed1 \u0ed2 \u0ed3 \u0ed4 \u0ed5 \u0ed6 \u0ed7 \u0ed8 \u0ed9 \u0ed0 {e} {e} {bksp}',
      '{tab} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e}',
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {enter}',
      '{shift} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  }
}
const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
