/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  Luxembourgish: {
    normal: [
      '\u00a7 1 2 3 4 5 6 7 8 9 0 \' ^ {bksp}',
      '{tab} q w e r t z u i o p \u00e8 \u00a8 $',
      'a s d f g h j k l \u00e9 \u00e0 {enter}',
      '{shift} < y x c v b n m , . / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\u00b0 + " * \u00e7 % & / ( ) = ? ` {bksp}',
      '{tab} Q W E R T Z U I O P \u00fc ! \u00a3',
      'A S D F G H J K L \u00f6 \u00e4 {enter}',
      '{shift} > Y X C V B N M ; : / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{e} \u00a6 @ # \u00b0 \u00a7 \u00ac | \u00a2 {e} {e} \u00b4 ~ {bksp}',
      '{tab} {e} {e} \u20ac {e} {e} {e} {e} {e} {e} {e} [ ] }',
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} { {enter}',
      '{shift} \\ {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  }
}
const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
