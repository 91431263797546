/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  'Belgian (Comma)': {
    normal: [
      '\u00b2 & \u00e9 " \' ( \u00a7 \u00e8 ! \u00e7 \u00e0 ) - {bksp}',
      '{tab} a z e r t y u i o p ^ $ \u00b5',
      'q s d f g h j k l m \u00f9 {enter}',
      '{shift} < w x c v b n , ; : / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\u00b3 1 2 3 4 5 6 7 8 9 0 \u00b0 _ {bksp}',
      '{tab} A Z E R T Y U I O P \u00a8 * \u00a3',
      'Q S D F G H J K L M % {enter}',
      '{shift} > W X C V B N ? . / / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{e} | @ # { [ ^ {e} {e} { } {e} {e} {bksp}',
      '{tab} {e} {e} \u20ac {e} {e} {e} {e} {e} {e} {e} [ ] {e}',
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} \u00b4 {enter}',
      '{shift} \\ {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  },
  'Belgian French': {
    normal: [
      '\u00b2 & \u00e9 " \' ( \u00a7 \u00e8 ! \u00e7 \u00e0 ) - {bksp}',
      '{tab} a z e r t y u i o p ^ $ \u00b5',
      'q s d f g h j k l m \u00f9 {enter}',
      '{shift} < w x c v b n , ; : / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '\u00b3 1 2 3 4 5 6 7 8 9 0 \u00b0 _ {bksp}',
      '{tab} A Z E R T Y U I O P \u00a8 * \u00a3',
      'Q S D F G H J K L M % {enter}',
      '{shift} > W X C V B N ? . / / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{e} | @ # { [ ^ {e} {e} { } {e} {e} {bksp}',
      '{tab} {e} {e} \u20ac {e} {e} {e} {e} {e} {e} {e} [ ] {e}',
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} \u00b4 {enter}',
      '{shift} \\ {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  }
}
const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
