/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  Yiddish: {
    rtl: true,
    normal: [
      '; 1 2 3 4 5 6 7 8 9 0 - = {b}',
      '{t} / \' \u05E7 \u05E8 \u05D0 \u05D8 \u05D5 \u05DF \u05DD \u05E4 [ ] \\',
      '\u05E9 \u05D3 \u05D2 \u05DB \u05E2 \u05D9 \u05D7 \u05DC \u05DA \u05E3 , {enter}',
      '{s} \u05D6 \u05E1 \u05D1 \u05D4 \u05E0 \u05DE \u05E6 \u05EA \u05E5 . {s}',
      '{space} {alt} {accept}'
    ],
    shift: [
      '~ ! @ # $ % ^ * & ( ) _ + {b}',
      '{t} \u201F \u201E ` \uFB2F \uFB2E \u05F0 \uFB35 \uFB4B \uFB4E \uFB44 { } |',
      '\uFB2A \uFB2B {e} \uFB3B \u05F1 \uFB1D \uFF1F \u05F2 {e} : " {enter}',
      '{s} \u2260 \uFB4C \uFB31 \u05BE \u2013 \u2014 \uFB4A < > ? {s}',
      '{space} {alt} {accept}'
    ],
    alt: [
      '\u05B0 \u05B1 \u05B2 \u05B3 \u05B4 \u05B5 \u05B6 \u05B7 \u05B8 \u05C2 \u05C1 \u05B9 \u05BC {b}',
      '{t} \u201F \u201E ` \uFB2F \uFB2E \u05F0 \uFB35 \uFB4B \uFB4E \uFB44 \u05BD \u05BF \u05BB',
      '\uFB2A \uFB2B {e} \uFB3B \u05F1 \uFB1D \uFF1F \u05F2 {e} \u05C3 \u05C0 {enter}',
      '{s} \u2260 \uFB4C \uFB31 \u05BE \u2013 \u2014 \uFB4A \u05F3 \u05F4 \u20AA {s}',
      '{space} {alt} {accept}'
    ]
  },
  'Yiddish (Yidish Lebt)': {
    rtl: true,
    normal: [
      '; 1 2 3 4 5 6 7 8 9 0 - = {b}',
      '{t} / \' \u05E7 \u05E8 \u05D0 \u05D8 \u05D5 \u05DF \u05DD \u05E4 ] [ \\',
      '\u05E9 \u05D3 \u05D2 \u05DB \u05E2 \u05D9 \u05D7 \u05DC \u05DA \u05E3 , {enter}',
      '{s} \u05D6 \u05E1 \u05D1 \u05D4 \u05E0 \u05DE \u05E6 \u05EA \u05E5 . {s}',
      '{space} {alt} {accept}'
    ],
    shift: [
      '~ ! @ # $ % ^ & * ) ( _ + {b}',
      '{t} {e} {e} {e} {e} {e} {e} \u05D5\u05B9 {e} {e} {e} } { |',
      '\u05E9\u05C1 {e} \u201E {e} {e} {e} {e} \u05DC\u05B9 {e} : " {enter}',
      '{s} {e} {e} \u05DC\u05B9 {e} {e} {e} {e} > < ? {s}',
      '{space} {alt} {accept}'
    ],
    alt: [
      '{e} \u05B2 \u05B3 \u05B1 \u05B4 \u05B5 \u05B7 \u05B8 \u05BB \u05B6 \u05B0 \u05BF \u05B9 {b}',
      '{t} \u05F4 \u05F3 \u20AC {e} \u05D0\u05B7 \u05D0\u05B8 \u05D5\u05BC \u05D5\u05D5 \u05BC \u05E4\u05BC \u201E \u201A \u05BE',
      '\u05E9\u05C2 \u20AA {e} \u05DB\u05BC {e} \u05D9\u05B4 \u05F2\u05B7 \u05D5\u05D9 {e} \u05E4\u05BF ; {enter}',
      '{s} \u2013 \u2014 \u05D1\u05BF \u201D \u059C \u2019 \u05E9\u05C1 \u05EA\u05BC {e} \u2026 {s}',
      '{space} {alt} {accept}'
    ],
    'alt-shift': [
      '{e} \u05B2 \u05B3 \u05B1 \u05B4 \u05B5 \u05B7 \u05B8 \u05BB \u05B6 \u05B0 \u05BF \u05B9 {b}',
      '{t} \u05F4 \u05F3 {e} {e} \uFB2E \uFB2F \uFB35 \u05F0 {e} \uFB44 \u201D \u2019 \u05BE',
      '\uFB2B {e} {e} \uFB3B \uFB20 \uFB1D \uFB1F \u05F1 \u05F2 \uFB4E \u05B2 {enter}',
      '{s} \u2013 \u2014 \uFB4C \u201C \u059E \u2018 \uFB2A \uFB4A {e} {e} {s}',
      '{space} {alt} {accept}'
    ]
  }
}

const layout = layouts.international
export {
  layout, layouts
}
