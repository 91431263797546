/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  'portuguese-qwerty': {
    normal: [
      '\u005C 1 2 3 4 5 6 7 8 9 0 \u0027 \u00ab {bksp}',
      '{tab} q w e r t y u i o p \u002b \u00b4',
      'a s d f g h j k l \u00e7 \u00ba \u007e {enter}',
      '{shift} \u003c z x c v b n m \u002c \u002e \u002d {shift}',
      '{cancel} {space} {alt} {combo} {accept}'
    ],
    shift: [
      '\u007c \u0021 \u0022 \u0023 \u0024 \u0025 \u0026 \u002f \u0028 \u0029 \u003d \u003F \u00bb {bksp}',
      '{tab} Q W E R T Y U I O P \u002a \u0060',
      'A S D F G H J K L \u00c7 \u00aa \u005e {enter}',
      '{shift} \u003e Z X C V B N M \u003b \u003a \u005f {shift}',
      '{cancel} {space} {alt} {combo} {accept}'
    ],
    // AltGr
    alt: [
      '\u005C 1 \u0040 \u00a3 \u00a7 \u20ac 6 \u007b \u005b \u005d \u007d \u0027 \u00ab {bksp}',
      '{tab} q w \u20ac r t y u i o p \u0308 \u00b4',
      'a s d f g h j k l \u00e7 \u00ba \u007e {enter}',
      '{shift} \u003c z x c v b n m \u002c \u002e \u002d {shift}',
      '{cancel} {space} {alt} {combo} {accept}'
    ],
    'alt-shift': [
      '\u007c \u0021 \u0022 \u0023 \u0024 \u0025 \u0026 \u002f \u0028 \u0029 \u003d \u003F \u00bb {bksp}',
      '{tab} Q W \u20ac R T Y U I O P \u0308 \u0060',
      'A S D F G H J K L \u00c7 \u00aa \u005e {enter}',
      '{shift} \u003e Z X C V B N M \u003b \u003a \u005f {shift}',
      '{cancel} {space} {alt} {combo} {accept}'
    ]
  },
  Portuguese: {
    normal: [
      '\\ 1 2 3 4 5 6 7 8 9 0 \' \u00AB {bksp}',
      '{tab} q w e r t y u i o p + \u00B4',
      'a s d f g h j k l \u00E7 \u00BA ~ {enter}',
      '{shift} < z x c v b n m , . - {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '| ! " # $ % & / ( ) = ? \u00BB {bksp}',
      '{tab} Q W E R T Y U I O P * `',
      'A S D F G H J K L \u00C7 \u00AA ^ {enter}',
      '{shift} > Z X C V B N M ; : _ {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{e} {e} @ \u00A3 \u00A7 \u20AC {e} { [ ] } {e} {e} {bksp}',
      '{tab} {e} {e} \u20AC {e} {e} {e} {e} {e} {e} {e} \u00A8 ]',
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {enter}',
      '{shift} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  },
  'Portuguese (Brazilian ABNT)': {
    normal: [
      '\' 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} q w e r t y u i o p \u00B4 [',
      'a s d f g h j k l \u00E7 ~ ] {enter}',
      '{shift} \\ z x c v b n m , . ; / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '" ! @ # $ % \u00A8 & * ( ) _ + {bksp}',
      '{tab} Q W E R T Y U I O P ` {',
      'A S D F G H J K L \u00C7 ^ } {enter}',
      '{shift} | Z X C V B N M < > : ? {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{e} \u00B9 \u00B2 \u00B3 \u00A3 \u00A2 \u00AC {e} {e} {e} {e} {e} \u00A7 {bksp}',
      '{tab} / ? \u00B0 {e} {e} {e} {e} {e} {e} {e} {e} \u00AA',
      '{e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} \u00BA {enter}',
      '{shift} {e} {e} {e} \u20A2 {e} {e} {e} {e} {e} {e} {e} \u00B0 {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  }
}
const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
