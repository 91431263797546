/* eslint-disable sort-keys */
import { KeyboardLayoutsObject } from '@/definitions/langoid'

const layouts: KeyboardLayoutsObject = {
  Estonian: {
    normal: [
      '\u02c7 1 2 3 4 5 6 7 8 9 0 + \u00b4 {bksp}',
      '{tab} q w e r t y u i o p \u00fc \u00f5 \'',
      'a s d f g h j k l \u00f6 \u00e4 {enter}',
      '{shift} < z x c v b n m , . / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    shift: [
      '~ ! " # \u00a4 % & / ( ) = ? ` {bksp}',
      '{tab} Q W E R T Y U I O P \u00dc \u00d5 *',
      'A S D F G H J K L \u00d6 \u00c4 {enter}',
      '{shift} > Z X C V B N M ; : / {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    alt: [
      '{e} {e} @ \u00a3 $ \u20ac {e} { [ ] } \\ {e} {bksp}',
      '{tab} {e} {e} \u20ac {e} {e} {e} {e} {e} {e} {e} {e} \u00a7 \u00bd',
      '{e} \u0161 {e} {e} {e} {e} {e} {e} {e} {e} ^ {enter}',
      '{shift} | \u017e {e} {e} {e} {e} {e} {e} {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ],
    'alt-shift': [
      '{e} {e} @ \u00a3 $ {e} {e} { [ ] } \\ {e} {bksp}',
      '{tab} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} {e} \u00a7 \u00bd',
      '{e} \u0160 {e} {e} {e} {e} {e} {e} {e} {e} ^ {enter}',
      '{shift} | \u017d {e} {e} {e} {e} {e} {e} {e} {e} {e} {shift}',
      '{accept} {alt} {space} {altGr} {cancel}'
    ]
  }
}
const layout = layouts[Object.keys(layouts)[0]]
export { layout, layouts }
